export function getBaseUrl() {
  // eslint-disable-line no-undef
  if (process.env.NODE_ENV !== 'production') {
    return 'localhost';
  }
  return 'thevibedance.com';
}

export function getBasePort() {
  // eslint-disable-line no-undef
  if (process.env.NODE_ENV !== 'production') {
    return ':5000';
  }
  return ':443';
}

export function getApiPort() {
  // eslint-disable-line no-undef
  if (process.env.NODE_ENV !== 'production') {
    return ':5050';
  }
  return ':444';
}

export function getProtocol() {
  // eslint-disable-line no-undef
  if (process.env.NODE_ENV !== 'production') {
    return 'http://';
  }
  return 'https://';
}
