export const getBaseUrl = () => {
  // if (process.env.NODE_ENV !== 'production') {
  return 'localhost';
  // }
  // return 'thevibedance.com';
};

export const getBasePort = () => {
  // if (process.env.NODE_ENV !== 'production') {
  return ':5000';
  // }
  // return ':443';
};

export const getApiPort = () => {
  // if (process.env.NODE_ENV !== 'production') {
  return ':5050';
  // }
  // return ':444';
};

export const getProtocol = () => {
  // if (process.env.NODE_ENV !== 'production') {
  return 'http://';
  // }
  // return 'https://';
};
