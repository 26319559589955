import { FormTypes } from '../../../../enums/form-types.enum';

export const SEMESTER_FORM_TYPE = 'SEMESTER_FORM_TYPE';
export const EDIT_SEMESTER_FORM = 'EDIT_SEMESTER_FORM';

export const semesterFormTypeAction = formType => {
  return {
    type: SEMESTER_FORM_TYPE,
    formType
  };
};

export const editSemesterFormAction = form => {
  return {
    type: EDIT_SEMESTER_FORM,
    form
  };
};

const initialState = {
  formType: FormTypes.NONE,
  form: {
    year: '',
    semesterName: { id: 0, name: '' },
    startDate: '',
    endDate: '',
    selected: ''
  }
};

export function semestersFormReducer(state = initialState, action) {
  switch (action.type) {
    case SEMESTER_FORM_TYPE:
      return {
        ...state,
        formType: action.formType
      };

    case EDIT_SEMESTER_FORM:
      return {
        ...state,
        form: action.form
      };

    default:
      return state;
  }
}
