import { createSelector } from 'reselect';
import { rootSelector } from '../../../root-selector';

export const sectionsSelector = createSelector(
  rootSelector,
  state => state.danceClassesSlice.sectionsSlice.sectionsApiSlice.sections
);

export const sectionFormSelector = createSelector(
  rootSelector,
  state => state.danceClassesSlice.sectionsSlice.sectionFormSlice.form
);

export const sectionFormTypeSelector = createSelector(
  rootSelector,
  state => state.danceClassesSlice.sectionsSlice.sectionFormSlice.formType
);
