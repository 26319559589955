import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../../util';
import { signOutErrorAction } from './sign-out.reducer';

export function* signOutWorkerSaga(action) {
  try {
    yield call(axios, {
      method: 'POST',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/authentication/signout`,
      withCredentials: true
    });

    window.location = '/';
  } catch (error) {
    yield put(signOutErrorAction(error));
  }
}
