import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextFieldWithValidation } from '../../../material-ui-wrappers/with-validations';
import { MenuItem } from '@material-ui/core';

const DancerFormInputsComponent = props => {
  const { classes, model, handleChange, hasSubmitted, validations } = props;

  return (
    <Fragment>
      <TextFieldWithValidation
        id='firstName'
        label='First Name'
        name='firstName'
        value={model.firstName}
        onChange={handleChange}
        className={`${classes.textField}`}
        InputProps={{
          className: classes.input
        }}
        InputLabelProps={{
          className: classes.textField
        }}
        margin='normal'
        rules={validations['firstName']}
        formSubmitted={hasSubmitted}
      />

      <TextFieldWithValidation
        id='lastName'
        label='Last Name'
        name='lastName'
        value={model.lastName}
        onChange={handleChange}
        className={`${classes.textField}`}
        InputProps={{
          className: classes.input
        }}
        InputLabelProps={{
          className: classes.textField
        }}
        margin='normal'
        rules={validations['lastName']}
        formSubmitted={hasSubmitted}
      />

      <TextFieldWithValidation
        id='gender'
        label='Gender'
        name='gender'
        select
        value={model.gender}
        onChange={handleChange}
        className={`${classes.textField}`}
        InputProps={{
          className: classes.input
        }}
        InputLabelProps={{
          className: classes.textField
        }}
        margin='normal'
        rules={validations['gender']}
        formSubmitted={hasSubmitted}
      >
        <MenuItem value='1'>Female</MenuItem>
        <MenuItem value='0'>Male</MenuItem>
      </TextFieldWithValidation>

      <TextFieldWithValidation
        id='birthdate'
        label='Birthdate'
        name='birthdate'
        type='date'
        value={model.birthdate}
        onChange={handleChange}
        className={`${classes.textField}`}
        InputProps={{
          className: classes.input
        }}
        InputLabelProps={{
          className: classes.textField
        }}
        margin='normal'
        rules={validations['birthdate']}
        formSubmitted={hasSubmitted}
      />
    </Fragment>
  );
};

const styles = theme => ({
  textField: {
    margin: theme.spacing.unit,
    display: 'flex'
  }
});

export const DancerFormInputs = withStyles(styles)(DancerFormInputsComponent);
