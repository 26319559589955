import { createSelector } from 'reselect';
import { rootSelector } from '../../../root-selector';

export const userNameSelector = createSelector(
  rootSelector,
  state =>
    state.authenticationSlice.userSlice &&
    state.authenticationSlice.userSlice.user.userName
);

export const userSelector = createSelector(
  rootSelector,
  state =>
    state.authenticationSlice.userSlice &&
    state.authenticationSlice.userSlice.user
);
