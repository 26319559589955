import { combineReducers } from 'redux';
import { classesBySectionReducer } from './fetch-classes-by-section.reducer';
import { danceClassFormReducer } from './dance-classes-form.reducer';

const initialState = {};

export function classesReducer(state = initialState, action) {
  let classesReducer = combineReducers({
    classesApiSlice: classesBySectionReducer,
    classesFormSlice: danceClassFormReducer
  });
  return classesReducer(state, action);
}
