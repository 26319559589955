import { combineReducers } from 'redux';
import { danceClassTimeFormReducer } from './dance-class-times-form.reducer';
import { timesByClassReducer } from './fetch-times-by-class.reducer';

const initialState = {};

export function classTimesReducer(state = initialState, action) {
  let classTimesReducer = combineReducers({
    classTimeApiSlice: timesByClassReducer,
    classTimeFormSlice: danceClassTimeFormReducer
  });
  return classTimesReducer(state, action);
}
