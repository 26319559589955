import React from 'react';
import { Redirect, Route } from 'react-router';
import { LoadingPage } from '../area/loading/loading-page.component';

export function ProtectedRouter({
  component: Component,
  isAuthenticated,
  authCalled,
  isAuthenticatedLoading,
  ...rest
}) {
  return (
    <React.Fragment>
      {isAuthenticatedLoading && <LoadingPage />}
      {authCalled && !isAuthenticatedLoading && !isAuthenticated && <Redirect to="/signin" />}
      {!isAuthenticatedLoading && isAuthenticated && <Route {...rest} render={props => <Component {...props} />} />}
    </React.Fragment>
  );
}
