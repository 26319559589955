import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../../util';
import { signInErrorAction } from './sign-in.reducer';

export function* signInWorkerSaga(action) {
  try {
    yield call(axios, {
      method: 'POST',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/authentication/signin`,
      data: action.payload,
      withCredentials: true
    });

    window.location.href = '/';
  } catch (error) {
    yield put(signInErrorAction(error.response));
  }
}
