import React from 'react';
import { Page } from '../../page/page';
import { RegistrationFormCard } from '../registration/registration-form/registration-form-card';
import { RegistrationFeeCard } from '../registration/registration-fee/registration-fee-card';
import { DancersCardContainer } from '../dancers/dancers-card-container';
import { OverviewCardContainer } from '../status/overview-card-container';

const AccountDashboardPageComponent = props => {
  const {
    registered,
    registrationPaid,
    tuition_payment,
    year,
    defaultYear
  } = props;
  return (
    <Page
      sections={[
        registered ? 'div' : RegistrationFormCard,
        registrationPaid.id !== 0 || !year ? 'div' : RegistrationFeeCard,
        OverviewCardContainer,
        DancersCardContainer
      ]}
      year={year}
      defaultYear={defaultYear}
      tuition_payment={tuition_payment}
    />
  );
};

export const AccountDashboardPage = AccountDashboardPageComponent;
