export const FETCH_DANCERS = 'FETCH_DANCERS';
export const FETCH_DANCERS_SUCCESS = 'FETCH_DANCERS_SUCCESS';
export const FETCH_DANCERS_ERROR = 'FETCH_DANCERS_ERROR';

export const fetchDancersAction = (payload) => {
    return (
        {
            type: FETCH_DANCERS,
            payload,
        });
};

export const fetchDancersSuccessAction = (dancers) => (
    {
        type: FETCH_DANCERS_SUCCESS,
        dancers,
    }
);

export const fetchDancersErrorAction = (error) => (
    {
        type: FETCH_DANCERS_ERROR,
        error,
    }
);

const initialState = {
    fetching: false,
    dancers: [],
    error: null,
};

export function fetchDancersReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_DANCERS:
            return {
                ...state,
                fetching: true,
                error: null,
            };

        case FETCH_DANCERS_SUCCESS:
            return {
                ...state,
                fetching: false,
                dancers: action.dancers,
                error: null,
            };

        case FETCH_DANCERS_ERROR:
            return {
                ...state,
                fetching: false,
                error: action.error,
            };

        default:
            return state;
    }
}