import { combineReducers } from 'redux';
import { classesForSignupReducer } from './area/dance-classes/dance-classes/fetch-classes-for-signup.reducer';
import { registrationReducer } from './area/forms/registration.reducer';
import { authenticationReducer } from './area/authentication/authentication.reducer';
import { dancersReducer } from './area/dancers/dancers.reducer';
import { danceClassesReducer } from './area/dance-classes/dance-classes.reducer';

const initialState = {};

export function rootReducer(state = initialState, action) {
  let rootReducer = combineReducers({
    danceClassesSlice: danceClassesReducer,
    signupSlice: classesForSignupReducer,
    formsSlice: registrationReducer,
    authenticationSlice: authenticationReducer,
    dancersSlice: dancersReducer
  });
  return rootReducer(state, action);
}
