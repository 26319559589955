import { call, put, select } from 'redux-saga/effects';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../../util';
import {
  fetchClassesSuccess,
  fetchClassesBySectionSuccess,
  fetchClassesBySectionError
} from './fetch-classes-by-section.reducer';
import { fetchTimesByClassAction } from '../dance-class-times/fetch-times-by-class.reducer';
import { currentSemesterSelector } from '../semesters/semester.selector';

export function* fetchClassesBySectionWorkerSaga(action) {
  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Classes/sectionId/${action.payload.sectionId}`
    });

    yield put(fetchClassesBySectionSuccess(response.data));

    var currentSemester = yield select(currentSemesterSelector);
    for (let i = 0; i < response.data.classes.length; ++i) {
      yield put(
        fetchTimesByClassAction({
          classId: response.data.classes[i].id,
          semesterId: action.payload.semesterId || currentSemester
        })
      );
    }
  } catch (error) {
    yield put(fetchClassesBySectionError(error));
  }
}

export function* fetchDanceClassesWorkerSaga(action) {
  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Classes`
    });

    yield put(fetchClassesSuccess(response.data));

    for (let i = 0; i < response.data.classes.length; ++i) {
      yield put(
        fetchTimesByClassAction({ classId: response.data.classes[i].id, semesterId: action.payload.semesterId })
      );
    }
  } catch (error) {
    yield put(fetchClassesBySectionError(error));
  }
}

export function* createDanceClassesWorkerSaga(action) {
  try {
    yield call(axios, {
      method: 'POST',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Classes`,
      data: action.danceClass,
      withCredentials: true
    });
  } catch (error) {
    yield put(fetchClassesBySectionError(error));
  }
}

export function* editDanceClassesWorkerSaga(action) {
  try {
    yield call(axios, {
      method: 'PUT',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Classes`,
      data: action.danceClass,
      withCredentials: true
    });
  } catch (error) {
    yield put(fetchClassesBySectionError(error));
  }
}
