export const FETCH_SEMESTERS = 'FETCH_SEMESTERS';
export const FETCH_SEMESTER_NAMES = 'FETCH_SEMESTER_NAMES';
export const CREATE_SEMESTER = 'CREATE_SEMESTER';
export const EDIT_SEMESTERS = 'EDIT_SEMESTERS';
export const FETCH_SEMESTERS_SUCCESS = 'FETCH_SEMESTERS_SUCCESS';
export const FETCH_SEMESTER_NAMES_SUCCESS = 'FETCH_SEMESTER_NAMES_SUCCESS';
export const FETCH_SEMESTERS_ERROR = 'FETCH_SEMESTERS_ERROR';
export const FETCH_CURRENT_SEMESTER = 'FETCH_CURRENT_SEMESTER';
export const FETCH_CURRENT_SEMESTER_SUCCESS = 'FETCH_CURRENT_SEMESTER_SUCCESS';
export const FETCH_CURRENT_SEMESTER_ERROR = 'FETCH_CURRENT_SEMESTER_ERROR';

export const fetchSemestersAction = (payload) => {
  return {
    type: FETCH_SEMESTERS,
    payload
  };
};

export const fetchSemesterNamesAction = () => {
  return {
    type: FETCH_SEMESTER_NAMES
  };
};

export const createSemestersAction = semester => {
  return {
    type: CREATE_SEMESTER,
    semester
  };
};

export const editSemestersAction = semester => {
  return {
    type: EDIT_SEMESTERS,
    semester
  };
};

export const fetchSemesterSuccessAction = semesters => ({
  type: FETCH_SEMESTERS_SUCCESS,
  semesters
});

export const fetchSemesterNamesSuccessAction = semestersNames => ({
  type: FETCH_SEMESTER_NAMES_SUCCESS,
  semestersNames
});

export const fetchSemesterErrorAction = error => ({
  type: FETCH_SEMESTERS_ERROR,
  error
});

export const fetchCurrentSemesterAction = () => {
  return {
    type: FETCH_CURRENT_SEMESTER
  };
};

export const fetchCurrentSemesterSuccessAction = payload => {
  return {
    type: FETCH_CURRENT_SEMESTER_SUCCESS,
    payload
  };
};

export const fetchCurrentSemesterErrorAction = error => {
  return {
    type: FETCH_CURRENT_SEMESTER_ERROR,
    error
  };
};

const initialState = {
  fetching: false,
  semesters: undefined,
  currentSemester: undefined,
  semesterNames: [],
  error: false
};

export function fetchSemestersReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SEMESTERS:
      return {
        ...state,
        fetching: true,
        error: false
      };

    case FETCH_SEMESTERS_SUCCESS:
      return {
        ...state,
        fetching: false,
        semesters: action.semesters,
        currentSemester: action.currentSemester,
        error: false
      };

    case FETCH_SEMESTER_NAMES_SUCCESS:
      return {
        ...state,
        fetching: false,
        semesterNames: action.semestersNames,
        error: false
      };

    case FETCH_SEMESTERS_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.error
      };

    case FETCH_CURRENT_SEMESTER:
      return {
        ...state,
        fetching: false,
        error: false
      };

    case FETCH_CURRENT_SEMESTER_SUCCESS:
      return {
        ...state,
        fetching: false,
        currentSemester: action.payload,
        error: false
      };

    case FETCH_CURRENT_SEMESTER_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.error
      };

    default:
      return state;
  }
}
