import React from 'react';
import { Button, withStyles } from '@material-ui/core';

const ActionButtonComponent = props => {
  const {
    classes,
    variant = 'contained',
    color = 'primary',
    onClick,
    disabled = false,
    component: Component = 'div',
    ...other
  } = props;
  return (
    <div className={classes.container}>
      <div className={classes.spacer} />
      <Button
        disabled={disabled}
        onClick={onClick}
        className={classes.button}
        variant={variant}
        color={color}
      >
        <Component {...other} />
      </Button>
    </div>
  );
};

const styles = theme => ({
  container: {
    display: 'flex'
  },
  spacer: {
    flex: 1
  },
  button: {
    margin: '0 10px'
  }
});

export const ActionButton = withStyles(styles)(ActionButtonComponent);
