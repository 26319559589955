import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import grid from 'jss-grid';
import { BiographyCard } from '../biography-card/biography-card.jsx';
import callieImage from '../../assets/img/Callie.jpg';
import mattieImage from '../../assets/img/Mattie.jpg';
import jessicaStaheli from '../../assets/img/jessicaStaheli.jpg';
// import natalieJensen from '../../assets/img/natalieJensen.jpg';
import caryssaNewboldImage from '../../assets/img/CaryssaNewbold.jpg';
import lisaEvansImage from '../../assets/img/lisaEvans.jpg';
// import brooklynDarbyImage from '../../assets/img/BrooklynDarby.jpg';
// import aubreeSorensonImage from '../../assets/img/AubreeSorenson.jpg';
// import ahranaHydeImage from '../../assets/img/AhranaHyde.jpg';
// import kyleeImage from '../../assets/img/Kylee.jpg';
// import charleyImage from '../../assets/img/charley.jpg';
// import alyssaBlackImage from '../../assets/img/AlyssaBlack.jpg';

const TeachersComponent = props => {
  const { classes } = props;

  const teachers = [
    {
      id: 1,
      image: callieImage,
      name: 'Callie Pickle',
      title: 'Owner',
      description:
        'Callie is the founding owner and teacher at VIBE. She is a certified yoga instructor, and has been trained in classical ballet, jazz, modern, and beginning levels of tumbling and acrobatics. She has performed with Odyssey Dance Theatre and held many lead roles in Academy of Ballet Arts “The Nutcracker.” She also attends classes with the Municipal Ballet Co-Operative and SALT Contemporary Dance. Callie has been dancing for 20 years and loves the artistry, creative communication, and life lessons she has learned through dance. Callie also has a Bachelor’s degree in Business Management and Entrepreneurship from BYU-Idaho. She hopes to help her students gain confidence to grow and share their talents, learn and become aware of dance technique and style, and guide them in learning important life lessons and gaining Christlike attributes.',
      isActive: true
    },
    {
      id: 2,
      image: mattieImage,
      name: 'Mattie Curtis',
      description:
        "Mattie has been dancing and singing for most of her life and loves to perform. She has been trained in tap dance for 10 years along with a background in ballet and jazz. Mattie has been a part of many productions in community theatre and was a performer in Riverton High School’s Music Dance Theatre team. She is studying at the University of Utah to earn her bachelor's degree in nursing and a minor in theatre",
      isActive: true
    },
    {
      id: 3,
      image: lisaEvansImage,
      name: 'Lisa Evans',
      description:
        'Lisa Evans started dancing 22 years ago. She was trained at Utah Regional Ballet, Hi-light Dance, The Pointe Academy, and ODT2. She is trained in Vaganova Classical Ballet, Jazz, Contemporary, Lyrical, Hip hop, and Tap. As a competitive dancers, she competed at many semi-regional, regional, and national competitions. At the age of 14 she started assisting and teaching ballet, jazz, and contemporary. Lisa started performing with Odyssey Dance Theater (ODT) at 15 and performed with them for 4 years. During that time she performed in shows such as, It’s A Wonderful Life, The REDUX NUT, and Thriller. In 2014 Lisa moved to Russia, St. Petersburg for a service mission. While she was in Russia, she taught Vaganova Classical Ballet to under privileged children. Lisa has been working privately with dancers for the last 9 years and has taken groups, small groups, and soloist to compete at NYCDA, NRG Nationals, Velocity Nationals, World Ballet Competition, and YAGP Regionals. Most recently Lisa had the opportunity to teach at a summer program at The Juilliard School along with New York City Ballet’s junior summer intensive at SAB. Lisa travels around the US teaching in master classes and choreographing solos, duos, trios and group pieces. She is so excited to be back in Utah for this dance season and is thrilled to be working with dancers at VIBE!',
      isActive: true
    },
    {
      id: 4,
      image: jessicaStaheli,
      name: 'Jessica Staheli',
      description:
        'Jessica began dancing at age 7, beginning her training solely in classical ballet. But her passion for dance only grew as she was introduced to Jazz, Modern, Hip Hop and ballroom dance. She trained at Center Stage Performing Arts in her youth, having the opportunity to work with world-renown teachers and choreographers such as Bonnie Story, Derryl Yeager & Rick Robinson. Jessica continued her dance training into high school and performing on competition and school teams, with multiple opportunities to travel, take master classes, and perform for various events in Utah and throughout the United States. She then received a scholarship to dance at Dixie State University. There she earned her Bachelor’s Degree in English and started her own teaching career in Southern Utah. She has been teaching dance in the studio setting for over 12 years, primarily with Premier Dance Center & Vista School for the performing arts, as well as a small studio in Colorado before moving here to northern Utah. Jessica truly loves working with young people; seeing their growth and progress, and encouraging their drive and passion. She is looking forward to sharing her love of dance with the students at Vibe Dance Studio.',
      isActive: true
    },
    // {
    //   id: 5,
    //   image: natalieJensen,
    //   name: 'Natalie Jensen',
    //   title: 'Dance Teacher',
    //   description:
    //     'Natalie was raised in Utah where she has been dancing since she was 3. She has experience in ballet, pointe, jazz, lyrical, hip hop, clogging, and tap dance. Natalie received most of her formal dance training at C Us Dance and Brigham Young University. Natalie formerly taught has an instructor at C Us Dance. Natalie has a Bachelor’s degree in Economics from BYU where she also teaches an Economics course, but is excited to start teaching dance again!',
    //   isActive: false
    // },
    {
      id: 6,
      image: caryssaNewboldImage,
      name: 'Caryssa Newbold',
      description:
        'Caryssa has danced since she was 3 years old. She trained at C&C ballet academy in ballet, lyrical, contemporary, jazz, and hip hop. As a member of a competitive dance team, she took part in various competitions as both a team member and soloist. She enjoys the competitive aspect of dance, but most importantly loves that dance allows her and others to express themselves through movement. Most recently Caryssa has taught as an instructor at The Dance Illusion in Logan, Utah while attending school at Utah State University. She loves being able to help others learn and experience the joys that dancing brings. She has also worked at LiveWire Dance and Aspire Dance Pro. She is extremely excited to join a new dance family here at VIBE!',
      isActive: true
    }
    // {
    //   id: 7,
    //   image: brooklynDarbyImage,
    //   name: "Brooklyn Darby",
    //   title: "Dance Teacher",
    //   description: "Brooklyn Darby is a University of Utah student, who was born and raised in Utah. She is a professional dancer and a fitness enthusiast. Within the dance field, she has experience in ballet, pointe, jazz, modern, lyrical, hiphop, clogging, tumbling, and contemporary dance. The majority of Brooklyn's 15 years of formal dance training have been under American Expression of Dance, Miss Margene's Creative Classroom, and C Us Dance - Martin Dance INC. These studios have also been supplemented by gymnastics, ballroom dance, Zumba, and countless hours of work outside the studio. Brooklyn is currently training to run a Spartan race at the end of July and enjoys any activity that can take place outside. She is excited to share her love of dance and movement with her students. And aims to promote an uplifting learning environment where students can gain knowledge and appreciation for their bodies, while developing social bonds.",
    //   isActive: false
    // },
    // {
    //   id: 8,
    //   image: aubreeSorensonImage,
    //   name: "Aubree Sorenson",
    //   title: "Dance Teacher",
    //   description: "My name is Aubree Sorenson. I am 20 years ago and I am from St George, Utah. I have been dancing since I was 3 years old. I am currently studying dance education at Utah Valley University and will graduate in 2023. I have trained in all genres at Dance Impressions, Onstage Dance Studio, 2 One Productionz, The Vault, Center Stage, Millenium and more! I have taken classes from many professional dancers and preformed with Odyssey Dance Theater. I also was apart of Dixie Highs Drill team. I have taught and subbed hip-hop, Jazz & contemporary for 3 years at 2 One Productionz, Onstage Dance Studio and recently Jazz N Place Dance Studio.",
    //   isActive: false
    // },
    // {
    //   id: 9,
    //   image: ahranaHydeImage,
    //   name: "Ahrana Hyde",
    //   title: "Hip Hop Teacher",
    //   description: "Ahrana has been dancing for 15 years and has a growing passion for dance.  She has a love for ballet and strives to become a professional ballet teacher. Ahrana has trained in other styles of dance like Jazz, Hip Hop, Contemporary, Lyrical, Tap, and Clogging. She looks forward to every teaching opportunity, to be able to share her love of dancing to others. Ahrana's goal is to share a positive, fun, and loving environment for her dancers and help them develop essential skills as a dancer.",
    //   isActive: false
    // },
    // {
    //   id: 10,
    //   image: kyleeImage,
    //   name: "Kylee",
    //   title: "Toddler Dance Teacher",
    //   description: "Kylee has been performing dance for 14 years. She has experience in ballet, jazz, cheer, tap, clogging, and tumbling. Kylee loves helping students improve their technique and performance ettiquette and hopes to share her love of dance and music with her students. She is currently working as a pharmacy technician with plans to attend BYU in the fall.",
    //   isActive: false
    // },
    // {
    //   id: 11,
    //   image: alyssaBlackImage,
    //   name: 'Alyssa Black',
    //   title: 'Dance Teacher',
    //   description: "Alyssa has danced competitively, and casually for most of her life. She especially loves ballet, pointe, and lyrical dance. She is currently a Family Life Studies major at Utah State University and is considering social work as a career. She teaches music at an elementary school and enjoys working with and teaching children. She loves helping her students understand the subject matter and important life lessons. She is excited to be back in the dance scene!",
    //   isActive: false
    // },
    // {
    //   id: 12,
    //   image: charleyImage,
    //   name: 'Charley Whitney',
    //   title: 'Hip-Hop Teacher',
    //   description: "Charley has danced for 21 years, and specializes in jazz, funk, and hip hop. She has danced competitively for 13 years, been a part of Davis High Drill Team, and was a member of Dixie State University dance team. Charley is also a certified yoga instructor that focuses on yoga for athletes, chair yoga for seniors, and specialized yoga therapy. Currently, Charley is finishing the last year of her bachelor’s degree in public relations, with a minor in health promotion. Charley is passionate about helping individuals achieve better health by teaching them proper fitness and nutrition habits.",
    //   isActive: false
    // },
  ];
  return (
    <Paper
      className={`${classes.root} ${classes.paper} ${classes.center}`}
      elevation={1}
    >
      <Typography variant='h5' component='h2' className={classes.heading}>
        Our Teachers
      </Typography>

      <br />

      <div className={classes.bioGrid}>
        {teachers.map(t => (
          <Fragment>
            {t.isActive && (
              <BiographyCard
                key={t.id}
                image={t.image}
                name={t.name}
                title={t.title}
                description={t.description}
              />
            )}
          </Fragment>
        ))}
      </div>
    </Paper>
  );
};

const styles = {
  root: {
    paddingTop: 20,
    paddingBottom: 20
  },
  paper: {
    margin: 'auto',
    marginTop: '4em',
    marginBottom: '4em',
    maxWidth: '50em',
    layout: 'center'
  },
  heading: {
    fontSize: '3rem'
  },
  bioGrid: {
    ...grid(
      `
      { 5px, 5px } | repeat(auto-fill, minmax(300px, 1fr)) |
      --
      `
    )
  },
  center: {
    textAlign: 'center'
  }
};

export const Teachers = withStyles(styles)(TeachersComponent);
