import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../../util';
import {
  fetchClassesForSignupSuccessAction,
  fetchClassesForSignupErrorAction
} from './fetch-classes-for-signup.reducer';

export function* fetchClassesForSignupWorkerSaga(action) {
  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/ClassList/semesterId/${
        action.payload.semesterId
      }/classSignUp`
    });

    yield put(fetchClassesForSignupSuccessAction(response.data));
  } catch (error) {
    yield put(fetchClassesForSignupErrorAction(error));
  }
}
