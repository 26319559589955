import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextFieldWithValidation } from '../../material-ui-wrappers/with-validations';
import { passwordsMatch } from '../../util/validations';

const CreateAccountInputsComponent = ({
  classes,
  model,
  handleChange,
  hasSubmitted,
  validations
}) => (
  <Fragment>
    <TextFieldWithValidation
      id='firstName'
      label='Guardian First Name'
      name='firstName'
      value={model.firstName}
      onChange={handleChange}
      className={`${classes.textField}`}
      InputProps={{
        className: classes.input
      }}
      InputLabelProps={{
        className: classes.textField
      }}
      margin='normal'
      rules={validations['firstName']}
      formSubmitted={hasSubmitted}
    />

    <TextFieldWithValidation
      id='lastName'
      label='Guardian Last Name'
      name='lastName'
      value={model.lastName}
      onChange={handleChange}
      className={`${classes.textField}`}
      InputProps={{
        className: classes.input
      }}
      InputLabelProps={{
        className: classes.textField
      }}
      margin='normal'
      rules={validations['lastName']}
      formSubmitted={hasSubmitted}
    />

    <TextFieldWithValidation
      id='phoneNumber'
      label='Phone Number'
      name='phoneNumber'
      value={model.phoneNumber}
      onChange={handleChange}
      className={`${classes.textField}`}
      InputProps={{
        className: classes.input
      }}
      InputLabelProps={{
        className: classes.textField
      }}
      margin='normal'
      rules={validations['phoneNumber']}
      formSubmitted={hasSubmitted}
    />

    <TextFieldWithValidation
      id='alternatePhoneNumber'
      label='Alternate Phone Number'
      name='alternatePhoneNumber'
      value={model.alternatePhoneNumber}
      onChange={handleChange}
      className={`${classes.textField}`}
      InputProps={{
        className: classes.input
      }}
      InputLabelProps={{
        className: classes.textField
      }}
      margin='normal'
      rules={validations['alternatePhoneNumber']}
      formSubmitted={hasSubmitted}
    />

    <TextFieldWithValidation
      id='address'
      label='Address'
      name='address'
      value={model.address}
      onChange={handleChange}
      className={`${classes.textField}`}
      InputProps={{
        className: classes.input
      }}
      InputLabelProps={{
        className: classes.textField
      }}
      margin='normal'
      rules={validations['address']}
      formSubmitted={hasSubmitted}
    />

    <TextFieldWithValidation
      id='userName'
      label='Guardian Email'
      name='userName'
      value={model.userName}
      onChange={handleChange}
      className={`${classes.textField}`}
      InputProps={{
        className: classes.input
      }}
      InputLabelProps={{
        className: classes.textField
      }}
      margin='normal'
      rules={validations['userName']}
      formSubmitted={hasSubmitted}
    />

    <TextFieldWithValidation
      id='referredBy'
      label='Referred By (Email)'
      name='referredBy'
      value={model.referredBy}
      onChange={handleChange}
      className={`${classes.textField}`}
      InputProps={{
        className: classes.input
      }}
      InputLabelProps={{
        className: classes.textField
      }}
      margin='normal'
      rules={validations['referredBy']}
      formSubmitted={hasSubmitted}
    />

    <TextFieldWithValidation
      id='password'
      type='password'
      label='Password'
      name='password'
      value={model.password}
      onChange={handleChange}
      className={`${classes.textField}`}
      InputProps={{
        className: classes.input
      }}
      InputLabelProps={{
        className: classes.textField
      }}
      margin='normal'
      rules={validations['password']}
      formSubmitted={hasSubmitted}
    />

    <TextFieldWithValidation
      id='passwordCheck'
      type='password'
      label='Verify Password'
      name='checkPassword'
      value={model.checkPassword}
      onChange={handleChange}
      className={`${classes.textField}`}
      InputProps={{
        className: classes.input
      }}
      InputLabelProps={{
        className: classes.textField
      }}
      margin='normal'
      rules={[passwordsMatch(model.password)]}
      formSubmitted={hasSubmitted}
    />
    {/* 
    <div
      className={`${classes.recaptcha} g-recaptcha`}
      data-sitekey='6Lena8IUAAAAAMvo0r7Eh2ywpCldvPJnLIOBd3dC'
    ></div> */}
  </Fragment>
);

const styles = theme => ({
  textField: {
    margin: theme.spacing.unit,
    display: 'flex'
  }
});

export const CreateAccountInputs = withStyles(styles)(
  CreateAccountInputsComponent
);
