import { createStore, applyMiddleware, compose } from 'redux';
import createSagMiddleware from 'redux-saga';

import { rootReducer } from './root-reducer';
import { watcherSaga } from './watcher-saga';

const sagaMiddleware = createSagMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(watcherSaga);
