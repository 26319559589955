import { ChangePasswordPage } from './change-password-page.component';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withLifecycle from '@hocs/with-lifecycle';
import { withRouter } from 'react-router-dom';
import {
  changePasswordAction,
  checkPasswordAction
} from '../../../../store/area/authentication/change-password/change-password.reducer';
import {
  signInStatusErrorCodeSelector,
  signInStatusCodeSelector
} from '../../../../store/area/authentication/sign-in/sign-in.selector';

const mapStateToProps = state => {
  return {
    signInErrorStatusCode: signInStatusErrorCodeSelector(state),
    signInStatusCode: signInStatusCodeSelector(state)
  };
};

const mapDispatchToProps = {
  checkPasswordAction,
  changePasswordAction
};

export const ChangePasswordPageContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLifecycle({
    onDidMount() {}
  })
)(withRouter(ChangePasswordPage));
