import { createSelector } from 'reselect';
import { rootSelector } from '../../../root-selector';

export const classTimesSelector = createSelector(
  rootSelector,
  state => state.danceClassesSlice.classTimesSlice.classTimeApiSlice.times
);

export const classTimesByClassSelector = createSelector(
  rootSelector,
  state => state.danceClassesSlice.classTimesSlice.classTimeApiSlice.timesByClass
);

export const danceClassTimeFormSelector = createSelector(
  rootSelector,
  state => state.danceClassesSlice.classTimesSlice.classTimeFormSlice.form
);

export const danceClassTimeFormTypeSelector = createSelector(
  rootSelector,
  state => state.danceClassesSlice.classTimesSlice.classTimeFormSlice.formType
);
