import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextFieldWithValidation } from '../../material-ui-wrappers/with-validations';

const SignInInputsComponent = ({
  classes,
  model,
  handleChange,
  hasSubmitted,
  validations
}) => (
  <Fragment>
    <TextFieldWithValidation
      id='userName'
      label='Email'
      name='userName'
      value={model.userName}
      onChange={handleChange}
      className={`${classes.textField}`}
      InputProps={{
        className: classes.input
      }}
      InputLabelProps={{
        className: classes.textField
      }}
      margin='normal'
      rules={validations['userName']}
      formSubmitted={hasSubmitted}
    />

    <TextFieldWithValidation
      id='password'
      type='password'
      label='Password'
      name='password'
      value={model.password}
      onChange={handleChange}
      className={`${classes.textField}`}
      InputProps={{
        className: classes.input
      }}
      InputLabelProps={{
        className: classes.textField
      }}
      margin='normal'
      rules={validations['password']}
      formSubmitted={hasSubmitted}
    />
  </Fragment>
);

const styles = theme => ({
  textField: {
    margin: theme.spacing.unit,
    display: 'flex'
  }
});

export const SignInInputs = withStyles(styles)(SignInInputsComponent);
