import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';

export const RegistrationFeeSummary = props => {
  const { registrationFee, convinienceFee } = props;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Item</TableCell>
          <TableCell align='right'>Price</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Registration Fee</TableCell>
          <TableCell align='right'>${registrationFee}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Convinience Fee</TableCell>
          <TableCell align='right'>${convinienceFee}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align='right'>Total:</TableCell>
          <TableCell align='right'>
            ${+registrationFee + +convinienceFee}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
