import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Header } from '../../../header/header';
import { Footer } from '../../../footer/footer.jsx';
import { Button, TextField } from '@material-ui/core';

class ChangePasswordPageComponent extends Component {
  state = {
    email: '',
    password: '',
    checkPassword: ''
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    const { classes, signInStatusCode } = this.props;
    return (
      <div>
        <Header />
        <div className={classes.spacer} />
        <div className={`${classes.center} ${classes.background}`}>
          <div>
            <Paper className={`${classes.root} ${classes.paper}`} elevation={1}>
              <Typography variant='title' component='p'>
                Change Password
              </Typography>

              <form>
                <TextField
                  id='email'
                  label='Email'
                  value={this.state.email}
                  onChange={this.handleChange('email')}
                  className={`${classes.textField}`}
                  InputProps={{
                    className: classes.input
                  }}
                  InputLabelProps={{
                    className: classes.textField
                  }}
                  margin='normal'
                />

                <TextField
                  id='password'
                  type='password'
                  label='Password'
                  value={this.state.password}
                  onChange={this.handleChange('password')}
                  className={`${classes.textField}`}
                  InputProps={{
                    className: classes.input
                  }}
                  InputLabelProps={{
                    className: classes.textField
                  }}
                  margin='normal'
                />

                <TextField
                  id='password-check'
                  type='password'
                  label='Verify Password'
                  value={this.state.checkPassword}
                  onChange={this.handleChange('checkPassword')}
                  className={`${classes.textField}`}
                  InputProps={{
                    className: classes.input
                  }}
                  InputLabelProps={{
                    className: classes.textField
                  }}
                  margin='normal'
                />

                <br />

                <Button
                  variant='contained'
                  color='primary'
                  onClick={() =>
                    this.props.checkPasswordAction({
                      username: this.state.email,
                      password: this.state.password,
                      checkPassword: this.state.checkPassword
                    })
                  }
                  className={classes.button}
                >
                  Change Password
                </Button>

                {signInStatusCode === 401 && (
                  <Typography
                    variant='body1'
                    component='p'
                    className={classes.error}
                  >
                    Invalid username or password
                  </Typography>
                )}
              </form>
            </Paper>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const styles = {
  root: {
    paddingTop: 20,
    paddingBottom: 20
  },
  spacer: {
    padding: '20px'
  },
  paper: {
    margin: 'auto',
    marginTop: '4em',
    marginBottom: '4em',
    maxWidth: '300px',
    layout: 'center'
  },
  heading: {
    fontSize: '4rem'
  },
  content: {
    fontSize: '1rem',
    textAlign: 'left',
    padding: '10px',
    whiteSpace: 'pre-wrap'
  },
  center: {
    textAlign: 'center'
  },
  button: {
    display: 'inline-flex',
    padding: '0 50px',
    margin: '20px 0'
  },
  background: {
    minHeight: '66vh'
  },
  error: {
    color: 'red'
  }
};

export const ChangePasswordPage = withStyles(styles)(
  ChangePasswordPageComponent
);
