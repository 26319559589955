import { combineReducers } from 'redux';
import { fetchSemestersReducer } from './fetch-semesters.reducer';
import { semestersFormReducer } from './semester-form.reducer';

const initialState = {};

export function semestersReducer(state = initialState, action) {
  let authenticationReducer = combineReducers({
    fetchSemestersSlice: fetchSemestersReducer,
    semestersFormSlice: semestersFormReducer
  });
  return authenticationReducer(state, action);
}
