import { call, put } from 'redux-saga/effects';
import { isAuthenticatedErrorAction, isAuthenticatedSuccessAction } from './is-authenticated.reducer';
import { getApiPort, getBaseUrl, getProtocol } from '../../../../area/util/http';
import axios from 'axios';

export function* isAuthenticatedWorkerSaga() {
  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/authentication/roles`,
      withCredentials: true
    });

    yield put(isAuthenticatedSuccessAction(response.data.roles.length > 0));
  } catch (error) {
    yield put(isAuthenticatedErrorAction(error));
  }
}
