import React, { useEffect, useState } from 'react';
import { Page } from '../../page/page';
import { Heading } from '../../material-ui-wrappers/heading';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { ActionButton } from '../../material-ui-wrappers/action-button';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  Paper,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress
} from '@material-ui/core';
import axios from 'axios';
import { useAsync } from '../../../hooks/useAsync';
import { getBaseUrl, getApiPort, getProtocol } from '../../../util';
import { routes } from '../../../routes/index';
import { Redirect } from 'react-router-dom';
import { ConfirmationModal } from '../../modals/confirmation-modal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const AdminAccountsPageComponent = props => {
  const { classes, accounts, stripe, history } = props;

  const fetchRegistrationFees = () => {
    return axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Admin/Payments/registration/fees`,
      withCredentials: true
    });
  };
  const {
    pending: isLoading,
    // rejected: hasError,
    resolved: response = [
      { id: 0, registrationType: 0, amount: 0 },
      { id: 0, registrationType: 1, amount: 0 }
    ]
  } = useAsync({
    fn: () => Promise.resolve(fetchRegistrationFees()).then(r => r.data)
  });

  useEffect(() => {
    setRegistrationFee(
      response.filter(r => r.registrationType === +registrationType)[0].amount
    );
  }, [response]);

  const [error, setError] = useState(undefined);
  const [redirect, setRedirect] = useState(undefined);
  const [registrationType, setRegistrationType] = useState('0');
  const [modalOpen, setModalOpen] = useState(false);
  const [registrationFee, setRegistrationFee] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  let convinienceFee = (+registrationFee * 0.03 + 0.3).toFixed(2);

  // const submitHandler = async ({ formState, isValid }) => {
  //   setError(undefined);
  //   let tokenResponse;
  //   tokenResponse = await stripe.createToken({
  //     type: 'card',
  //     name: `${user.firstName} ${user.lastName}`
  //   });
  //   if (tokenResponse.error) {
  //     setSubmitting(false);
  //     setError(
  //       `There was a problem processing your payment. ${tokenResponse.error.message}`
  //     );
  //     return;
  //   }
  //   formState.token = tokenResponse;
  //   try {
  //     var paymentResponse = await axios({
  //       method: 'POST',
  //       url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Payments/registration/current/${
  //         formState.registrationType
  //       }`,
  //       data: tokenResponse.token,
  //       withCredentials: true
  //     });
  //   } catch (ex) {
  //     setSubmitting(false);
  //     setError(
  //       `There was a problem processing your payment. ${ex.response.data.Message}`
  //     );
  //     return;
  //   }
  //   if (paymentResponse.status < 200 || paymentResponse.status > 299) {
  //     setSubmitting(false);
  //     setError(
  //       `There was a problem processing your payment. ${paymentResponse.Message}`
  //     );
  //     return;
  //   }
  //   setModalOpen(true);
  // };

  const handleRadioToggle = e => {
    e.persist();
    setRegistrationType(e.target.value);
    setRegistrationFee(
      response.filter(r => r.registrationType === +e.target.value)[0] &&
        response.filter(r => r.registrationType === +e.target.value)[0].amount
    );
  };

  const rows = [
    { id: 'name', numeric: true, disablePadding: true, label: 'Name' }
  ];

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <Page>
      <Heading>Accounts</Heading>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>First</TableCell>
            <TableCell>Last</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts &&
            accounts.map(account => (
              <TableRow
                onClick={e =>
                  setRedirect(
                    routes.adminEditAccountRoute.replace(
                      ':accountId',
                      account.id
                    )
                  )
                }
                key={account.id}
              >
                <TableCell>{account.firstName}</TableCell>
                <TableCell>{account.lastName}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <ConfirmationModal
        name='Payment submitted successfully!'
        handleClose={() => setRedirect(routes.accountDashboardPageRoute)}
        open={modalOpen}
      />
    </Page>
  );
};

const styles = {
  base: {
    // Add your base input styles here. For example:
    fontSize: '16px',
    color: '#32325d',
    borderBottom: '1px solid'
  },
  card: {
    width: '80%',
    margin: '20px auto',
    padding: '20px'
  },
  error: {
    color: 'red'
  }
};

export const AdminAccountsPage = withStyles(styles)(
  withRouter(injectStripe(AdminAccountsPageComponent))
);
