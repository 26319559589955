import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Header } from '../header/header';
import { Footer } from '../footer/footer.jsx';
import Paper from '@material-ui/core/Paper';

const PageComponent = props => {
  const {
    classes,
    sections: Sections,
    component: Component = 'div',
    ...other
  } = props;
  return (
    <Fragment>
      <Header />
      {Sections &&
        Sections.map((Section, index) => <Section key={index} {...other} />)}
      {!Sections && (
        <Paper
          className={`${classes.root} ${classes.paper} ${classes.page}`}
          elevation={1}
        >
          <Component {...other} />
        </Paper>
      )}
      <Footer />
    </Fragment>
  );
};

const styles = theme => ({
  page: {
    minHeight: '100vh'
  },
  root: {
    paddingTop: 20,
    paddingBottom: 20
  },
  paper: {
    margin: 'auto',
    marginTop: '4em',
    marginBottom: '4em',
    maxWidth: '50em',
    layout: 'center'
  }
});

export const Page = withStyles(styles)(PageComponent);
