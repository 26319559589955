import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { Header } from '../header/header';
import { Footer } from '../footer/footer.jsx';
import { StudioInfo } from './studio-info.component';

const RecitalsPage = ({ classes }) => (
  <React.Fragment>
    <Header />
    <div className={classes.spacer} />
    <div className={`"Center" ${classes.background}`}>
      <Paper className={`${classes.root} ${classes.paper}`} elevation={1}>
        <StudioInfo />
      </Paper>
    </div>
    <Footer />
  </React.Fragment>
);

const styles = {
  root: {
    marginTop: '20px',
    padding: 20
  },
  spacer: {
    padding: '40px'
  },
  paper: {
    margin: 'auto',
    marginTop: '20px',
    marginBottom: '20px',
    maxWidth: '50em',
    layout: 'center'
  },
  center: {
    textAlign: 'center'
  },
  background: {
    minHeight: '70vh'
  }
};

export default withStyles(styles)(RecitalsPage);
