import React, { useEffect, useState } from 'react';
import { Page } from '../../../page/page';
import { Heading } from '../../../material-ui-wrappers/heading';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { ActionButton } from '../../../material-ui-wrappers/action-button';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  Paper,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress
} from '@material-ui/core';
import axios from 'axios';
import { useAsync } from '../../../../hooks/useAsync';
import { getBaseUrl, getApiPort, getProtocol } from '../../../../util';
import { routes } from '../../../../routes/index';
import { Redirect } from 'react-router-dom';
import { ConfirmationModal } from '../../../modals/confirmation-modal';
import { RegistrationFeeSummary } from './registration-fee-summary';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';

const RegistrationFeePageComponent = props => {
  const { classes, user, stripe, history } = props;

  const fetchRegistrationFees = () => {
    return axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Payments/registration/fees`,
      withCredentials: true
    });
  };
  const {
    pending: isLoading,
    // rejected: hasError,
    resolved: response = [
      { id: 0, registrationType: 0, amount: 0 },
      { id: 0, registrationType: 1, amount: 0 }
    ]
  } = useAsync({
    fn: () => Promise.resolve(fetchRegistrationFees()).then(r => r.data)
  });

  useEffect(() => {
    setRegistrationFee(
      response.filter(r => r.registrationType === +registrationType)[0].amount
    );
  }, [response]);

  const [error, setError] = useState(undefined);
  const [redirect, setRedirect] = useState(undefined);
  const [registrationType, setRegistrationType] = useState('0');
  const [modalOpen, setModalOpen] = useState(false);
  const [registrationFee, setRegistrationFee] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  let convinienceFee = (+registrationFee * 0.03 + 0.3).toFixed(2);

  const submitHandler = async ({ formState, isValid }) => {
    setError(undefined);
    let tokenResponse;
    tokenResponse = await stripe.createToken({
      type: 'card',
      name: `${user.firstName} ${user.lastName}`
    });
    if (tokenResponse.error) {
      setSubmitting(false);
      setError(
        `There was a problem processing your payment. ${tokenResponse.error.message}`
      );
      return;
    }
    formState.token = tokenResponse;
    try {
      var paymentResponse = await axios({
        method: 'POST',
        url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Payments/registration/current/${
          formState.registrationType
        }`,
        data: tokenResponse.token,
        withCredentials: true
      });
    } catch (ex) {
      setSubmitting(false);
      setError(
        `There was a problem processing your payment. ${ex.response.data.Message}`
      );
      return;
    }
    if (paymentResponse.status < 200 || paymentResponse.status > 299) {
      setSubmitting(false);
      setError(
        `There was a problem processing your payment. ${paymentResponse.Message}`
      );
      return;
    }
    setModalOpen(true);
  };

  const handleRadioToggle = e => {
    e.persist();
    setRegistrationType(e.target.value);
    setRegistrationFee(
      response.filter(r => r.registrationType === +e.target.value)[0] &&
        response.filter(r => r.registrationType === +e.target.value)[0].amount
    );
  };

  if (redirect) {
    return <Redirect to={routes.accountDashboardPageRoute} />;
  }

  return (
    <Page>
      <IconButton onClick={history.goBack}>
        <ArrowBackIcon />
      </IconButton>
      <Heading>Registration Fee</Heading>
      <Paper className={classes.card}>
        <RadioGroup
          aria-label='registrationType'
          name='registrationType'
          value={registrationType}
          onClick={handleRadioToggle}
        >
          <FormControlLabel
            value='0'
            control={<Radio color='primary' />}
            label='Single Dancer'
          />
          <FormControlLabel
            value='1'
            control={<Radio color='primary' />}
            label='Family'
          />
        </RadioGroup>
        {!isLoading && response[0].id !== 0 && (
          <RegistrationFeeSummary
            registrationFee={registrationFee}
            convinienceFee={convinienceFee}
          />
        )}
        <br />
        <Typography variant='h6'>Card Details</Typography>
        <CardElement className={classes.base} />
        {error && <Typography className={classes.error}>{error}</Typography>}
        <br />
        <ActionButton
          disabled={submitting}
          onClick={() => {
            setSubmitting(true);
            submitHandler({
              formState: { registrationType },
              isValid: true
            });
          }}
        >
          {submitting ? <CircularProgress color='secondary' /> : 'Pay'}
        </ActionButton>
      </Paper>
      <ConfirmationModal
        name='Payment submitted successfully!'
        handleClose={() => setRedirect(routes.accountDashboardPageRoute)}
        open={modalOpen}
      />
    </Page>
  );
};

const styles = {
  base: {
    // Add your base input styles here. For example:
    fontSize: '16px',
    color: '#32325d',
    borderBottom: '1px solid'
  },
  card: {
    width: '80%',
    margin: '20px auto',
    padding: '20px'
  },
  error: {
    color: 'red'
  }
};

export const RegistrationFeePage = withStyles(styles)(
  withRouter(injectStripe(RegistrationFeePageComponent))
);
