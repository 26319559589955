import React, { useState, Fragment } from 'react';
import { Paper, withStyles } from '@material-ui/core';
import { Heading } from '../../material-ui-wrappers/heading';
import { Content } from '../../material-ui-wrappers/content';
import { ActionButton } from '../../material-ui-wrappers/action-button';
import { routes } from '../../../routes';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { TextField, Typography } from '@material-ui/core';
import { formatCurrency } from '../../util/currency';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../util';

const OverviewCardComponent = props => {
  const {
    admin,
    accountId,
    classes,
    tuition,
    tuition_payment,
    tuitionMonth,
    setTuitionMonth
  } = props;
  const [redirect, setRedirect] = useState(undefined);

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  const tuitionThisMonth = moment();
  const tuitionNextMonth = moment().add(1, 'month');

  const makeAdminPayment = async () => {
    try {
      var paymentResponse = await axios({
        method: 'POST',
        url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Payments/admin/account/${accountId}/tuition/fees`,
        data: {
          paymentYear: tuitionMonth.year,
          paymentMonth: tuitionMonth.month
        },
        withCredentials: true
      });
    } catch (ex) {
      // setSubmitting(false);
      // setError(
      //   `There was a problem processing your payment. ${ex.response.data.Message}`
      // );
      return;
    }
    if (paymentResponse.status < 200 || paymentResponse.status > 299) {
      // setSubmitting(false);
      // setError(
      //   `There was a problem processing your payment. ${paymentResponse.Message}`
      // );
      return;
    }
    // setModalOpen(true);
  };

  return (
    <Paper className={`${classes.paper} ${classes.root}`}>
      <Heading>Overview</Heading>
      <TextField
        onChange={e => setTuitionMonth(JSON.parse(e.target.value))}
        className={classes.content}
        select
        value={JSON.stringify(tuitionMonth)}
      >
        <Typography
          value={JSON.stringify({
            year: tuitionThisMonth.format('YYYY'),
            month: tuitionThisMonth.format('MM')
          })}
        >
          {tuitionThisMonth.format('MMMM YYYY')}
        </Typography>
        <Typography
          value={JSON.stringify({
            year: tuitionNextMonth.format('YYYY'),
            month: tuitionNextMonth.format('MM')
          })}
        >
          {tuitionNextMonth.format('MMMM YYYY')}
        </Typography>
      </TextField>
      <Content>
        {moment(tuitionMonth.month, 'MM').format('MMMM')} Tuition:{' '}
        {tuition.Total <= 0 ? 'Paid' : `$${formatCurrency(tuition.Total)}`}
      </Content>
      {tuition.Total - tuition['Convenience Fee'] <= 0 ? (
        <Fragment />
      ) : (
        <ActionButton
          onClick={() => {
            admin
              ? makeAdminPayment()
              : setRedirect(
                  routes.tuitionPaymentPageRoute
                    .replace(':year', tuitionMonth.year)
                    .replace(':month', tuitionMonth.month)
                );
          }}
        >
          Make Payment
        </ActionButton>
      )}
    </Paper>
  );
};

const styles = theme => ({
  root: {
    paddingTop: 20,
    paddingBottom: 20
  },
  content: {
    padding: '10px'
  },
  paper: {
    margin: 'auto',
    marginTop: '4em',
    marginBottom: '4em',
    maxWidth: '50em',
    layout: 'center'
  }
});

export const OverviewCard = withStyles(styles)(OverviewCardComponent);
