import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Heading } from '../../material-ui-wrappers/heading';
import { Content } from '../../material-ui-wrappers/content';
import { ActionButton } from '../../material-ui-wrappers/action-button';
import { ContentCard } from '../../material-ui-wrappers/content-card';
import { Redirect } from 'react-router-dom';
import { routes } from '../../../routes';
import { DancerCard } from './dancer-card';
import grid from 'jss-grid';
import { DancerCardContainer } from './dancer-card-container';

const DancersCardComponent = props => {
  const { classes, dancers, registered, semesterPaid, year } = props;
  const [redirect, setRedirect] = useState(undefined);

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <ContentCard>
      <Heading>Dancers</Heading>
      {!registered && year && (
        <Content className={classes.error}>
          You must fill out the registration form before signing up for classes.
        </Content>
      )}
      {!semesterPaid && year && (
        <Content className={classes.error}>
          You must pay the registration fee before signing up for classes.
        </Content>
      )}
      <div className={classes.dancerGrid}>
        {dancers === undefined && (
          <Content>You have not created any dancers yet.</Content>
        )}
        {dancers &&
          dancers.map(dancer => (
            <DancerCardContainer key={dancer.id} dancer={dancer} year={year} />
          ))}
      </div>
      <ActionButton onClick={() => setRedirect(routes.addDancerPage)}>
        Add Dancer
      </ActionButton>
    </ContentCard>
  );
};

const styles = theme => ({
  dancerGrid: {
    padding: '10px',
    ...grid(
      `
            {.5em, .5em} | repeat(auto-fill, minmax(230px, .5fr)) |
            --
            `
    )
  },
  error: {
    color: 'red'
  }
});

export const DancersCard = withStyles(styles)(DancersCardComponent);
