import { to } from '../area/util/await-to';
import { useState, useEffect } from 'react';

const initialState = {
  rejected: null,
  pending: true,
  resolved: undefined,
};

export const useAsync = ({ fn, resolve = true }, comparison = []) => {
  const [state, setState] = useState({ ...initialState, pending: resolve });
  let cancel = false;
  const handler = async (...args) => {
    setState({ ...initialState });
    const [rejected, resolved] = await to(fn(...args));
    if (cancel) return;
    setState({
      pending: false,
      rejected,
      resolved: resolved === null ? undefined : resolved,
    });
  };
  const reset = () => {
    setState({ ...initialState, pending: resolve });
  };
  useEffect(() => {
    if (resolve) handler();
    return () => { cancel = true; };
  }, comparison);
  return {
    handler,
    reset,
    ...state,
  };
};
