import React from 'react';
import { RegistrationFeePage } from './registration-fee-page';
import { Elements } from 'react-stripe-elements';
import { fetchUserAction } from '../../../../store/area/authentication/users/fetch-user.reducer';
import { userSelector } from '../../../../store/area/authentication/users/fetch-user.selector';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withLifecycle from '@hocs/with-lifecycle';

const RegistrationFeePageContainerComponent = props => {
  const { user } = props;
  return (
    <Elements>
      <RegistrationFeePage user={user} />
    </Elements>
  );
};

const mapStateToProps = state => {
  return {
    user: userSelector(state)
  };
};

const mapDispatchToProps = {
  fetchUserAction
};

export const RegistrationFeePageContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLifecycle({
    onDidMount({ fetchUserAction }) {
      fetchUserAction();
    }
  })
)(RegistrationFeePageContainerComponent);
