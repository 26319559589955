import React, { Fragment, useState } from 'react';
import { AccountDashboardPage } from './account-dashboard-page';
import { getBaseUrl, getApiPort, getProtocol } from '../../../util';
import axios from 'axios';
import { useAsync } from '../../../hooks/useAsync';
import { LoadingPage } from '../../loading/loading-page.component';
import moment from 'moment';

export const AccountDashboardPageContainer = props => {
  const [tuitionMonth, setTuitionMonth] = useState({
    year: moment().format('YYYY'),
    month: moment().format('M')
  });
  const fetchSemesterRegistration = () => {
    return axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Registration/semester-registrations/current`,
      withCredentials: true
    });
  };
  const fetchRegistrationPayment = () => {
    return axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Payments/registration`,
      withCredentials: true
    });
  };
  const fetchTuitionPayment = () => {
    return axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Payments/tuition/year/${
        tuitionMonth.year
      }/month/${tuitionMonth.month}`,
      withCredentials: true
    });
  };
  const fetchDefaultSemester = () => {
    return axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Semester/default`,
      withCredentials: true
    });
  };
  const {
    pending: isLoading,
    // rejected: hasError,
    resolved: [
      registered,
      registrationPaid,
      tuition_payment,
      defaultSemester
    ] = []
  } = useAsync({
    fn: () =>
      Promise.all([
        fetchSemesterRegistration(),
        fetchRegistrationPayment(),
        fetchTuitionPayment(),
        fetchDefaultSemester()
      ]).then(responces => responces.map(r => r.data))
  });
  return (
    <Fragment>
      {isLoading && <LoadingPage />}
      {!isLoading && registered && (
        <AccountDashboardPage
          registered={registered.registrationDigitalSignature != null}
          registrationPaid={registrationPaid}
          tuition_payment={tuition_payment}
          year={
            (registered &&
              registered.semesterAgreement &&
              registered.semesterAgreement.year) ||
            undefined
          }
          defaultYear={defaultSemester && defaultSemester.year}
        />
      )}
    </Fragment>
  );
};
