import { FormTypes } from '../../../../enums/form-types.enum';

export const SECTION_FORM_TYPE = 'SECTION_FORM_TYPE';
export const EDIT_SECTION_FORM = 'EDIT_SECTION_FORM';

export const sectionFormTypeAction = formType => {
  return {
    type: SECTION_FORM_TYPE,
    formType
  };
};

export const editSectionFormAction = form => {
  return {
    type: EDIT_SECTION_FORM,
    form
  };
};

const initialState = {
  formType: FormTypes.NONE,
  form: {
    sectionName: '',
    classesDuration: '',
    sectionAges: '',
    selected: ''
  }
};

export function sectionFormReducer(state = initialState, action) {
  switch (action.type) {
    case SECTION_FORM_TYPE:
      return {
        ...state,
        formType: action.formType
      };

    case EDIT_SECTION_FORM:
      return {
        ...state,
        form: action.form
      };

    default:
      return state;
  }
}
