import React, { useState } from 'react';
import { Page } from '../../../page/page';
import Validations from '../../../util/validations';
import { withStyles } from '@material-ui/core/styles';
import useFormState, { Form } from '../../../../hooks/useFormState';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../../util';
import { Redirect } from 'react-router';
import { routes } from '../../../../routes';
import moment from 'moment';
import { ConfirmationModal } from '../../../modals/confirmation-modal';
import { Heading } from '../../../material-ui-wrappers/heading';
import { DancerFormInputs } from './dancer-form-inputs';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';

const DancerFormComponent = props => {
  const { history } = props;
  const [redirect, setRedirect] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);

  let validation = {
    firstName: [Validations.required],
    lastName: [Validations.required],
    gender: [Validations.required],
    birthdate: [Validations.required, Validations.isDate]
  };
  let fieldValidations = () => validation;

  const handleSubmit = async ({ state, isValid }) => {
    if (isValid) {
      await axios({
        method: 'POST',
        url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/dancers`,
        data: state,
        withCredentials: true
      });

      setModalOpen(true);
    }
    return Promise.reject();
  };

  let defaultState = {
    firstName: '',
    lastName: '',
    gender: 1,
    birthdate: moment().format('YYYY-MM-DD')
  };

  const {
    formState,
    onFormChange,
    onSubmit,
    resetState,
    isUpdating,
    // response,
    // hasError,
    hasSubmitted
    // setFormState
  } = useFormState(defaultState, handleSubmit, fieldValidations);

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <Page>
      <IconButton onClick={history.goBack}>
        <ArrowBackIcon />
      </IconButton>
      <Heading>Add Dancer</Heading>

      <Form
        onSubmit={onSubmit}
        isUpdating={isUpdating}
        handleReset={resetState}
        hasSubmitted={hasSubmitted}
      >
        <DancerFormInputs
          model={formState}
          handleChange={onFormChange}
          hasSubmitted={hasSubmitted}
          validations={fieldValidations(formState)}
          isEditing={false}
        />
      </Form>
      <ConfirmationModal
        name='Dancer Created'
        handleClose={() => setRedirect(routes.accountDashboardPageRoute)}
        open={modalOpen}
      />
    </Page>
  );
};

const styles = theme => ({});

export const DancerForm = withStyles(styles)(DancerFormComponent);
