import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
// import { Icon } from 'blueprints2';
// import { defineMessages, injectIntl } from 'react-intl';
import { HelperText } from './helper-text';
import './helper-text.css';

// const messages = defineMessages({
//   validated: {
//     id: 'CommonStrings.Validated',
//     defaultMessage: 'Validated'
//   },
//   error: {
//     id: 'CommonStrings.Error',
//     defaultMessage: 'Error'
//   }
// });

const DefaultInputFinder = styled.span`
  .HelperWrapper.error select {
    color: #d62e2e !important;
    background-color: #fbe1e0a0 !important;
    border-width: 2px 2px 0 2px !important;
    border-color: #d62e2e !important;
    box-shadow: 0 0.2rem #d62e2e !important;
  }
  .HelperWrapper.error input {
    color: #d62e2e !important;
    background-color: #fbe1e0a0 !important;
    border-width: 2px !important;
    border-color: #d62e2e !important;
  }
`;

export const withHelperText = (
  WrappedComponent,
  StyleWrapper = DefaultInputFinder
) => {
  const helperWrapper = props => {
    // Two separate deconstructions here to let ...rest contain the name/label/value
    const { hasError, helperText, isValid, intl, ...rest } = props;
    // const hasIcon = hasError || isValid;
    const wrapperClasses = classNames('HelperWrapper', {
      error: hasError,
      valid: isValid
    });
    // const iconTitle = hasError ? messages.error : messages.validated;
    // const iconType = hasError ? 'exclamation-solid' : 'checkmark-solid';
    // const iconClasses = classNames('icon', {
    //   error: hasError,
    //   valid: isValid
    // });

    return (
      <StyleWrapper>
        <div className={wrapperClasses} aria-live='polite' role='status'>
          <WrappedComponent {...rest} />
          <div className='HelperArea'>
            {/* {hasIcon && <Icon title={intl.formatMessage(iconTitle)} className={iconClasses} type={iconType} />} */}
            {helperText && (
              <HelperText hasError={hasError}>{helperText}</HelperText>
            )}
          </div>
        </div>
      </StyleWrapper>
    );
  };

  helperWrapper.defaultProps = {
    helperText: '',
    hasError: false,
    isValid: false
  };

  helperWrapper.propTypes = {
    helperText: PropTypes.string,
    hasError: PropTypes.bool,
    isValid: PropTypes.bool,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired
    }).isRequired
  };
  //   return injectIntl(helperWrapper);
  return helperWrapper;
};
