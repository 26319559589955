import { compose } from 'recompose';
import withLifecycle from '@hocs/with-lifecycle';
import { connect } from 'react-redux';
import { StudentsPage } from './students-page';
import { fetchSemestersAction } from '../../store/area/dance-classes/semesters/fetch-semesters.reducer';
import {
  semesterSelector,
  currentSemesterSelector
} from '../../store/area/dance-classes/semesters/semester.selector.js';
import { rolesSelector } from '../../store/area/authentication/roles/fetch-role.selector';
import { fetchRoleAction } from '../../store/area/authentication/roles/fetch-role.reducer';
import { fetchDancersAction } from '../../store/area/dancers/fetch-dancers.reducer';
import { dancersSelector } from '../../store/area/dancers/fetch-dancers.selector';

const mapStateToProps = state => {
  return {
    semesters: semesterSelector(state),
    currentSemester: currentSemesterSelector(state),
    roles: rolesSelector(state)
  };
};

const mapDispatchToProps = {
  fetchSemestersAction,
  fetchRoleAction,
  fetchDancersAction
};

export const StudentsPageContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLifecycle({
    onDidMount({ fetchRoleAction, fetchDancersAction, fetchSemestersAction }) {
      fetchRoleAction();
      fetchSemestersAction({ query: '?historical=true' });
    }
  })
)(StudentsPage);
