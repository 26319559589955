import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router';
import { createBrowserHistory } from 'history';
import MuiPickersUtilProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import DateFnsUtils from 'material-ui-pickers/utils/date-fns-utils';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { StripeProvider } from 'react-stripe-elements';
import { indexRoutes, protectedRoutes } from './routes/index.jsx';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { ProtectedRoute } from './routes/protected-route-container';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: '#757ce8',
      main: '#000000',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#dbdbdb',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

var hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilProvider utils={DateFnsUtils}>
        {console.log(process.env.environment)}
        {/* <StripeProvider apiKey={process.env.STRIPE_API}> */}
        <Router history={hist}>
          <Switch>
            {indexRoutes.map((prop, key) => {
              return (
                <Route
                  exact
                  path={prop.path}
                  key={key}
                  component={prop.component}
                />
              );
            })}
            {protectedRoutes.map((prop, key) => {
              return (
                <ProtectedRoute
                  exact
                  path={prop.path}
                  key={key}
                  component={prop.component}
                />
              );
            })}
          </Switch>
        </Router>
        {/* </StripeProvider> */}
      </MuiPickersUtilProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
);

document.body.style.margin = 0;
document.body.style.padding = 0;
document.body.style.backgroundColor = 'aliceblue';
