export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_ERROR = 'SIGNOUT_ERROR';

export const signOutAction = (payload) => {
    return (
        {
            type: SIGNOUT,
            payload,
        });
};

export const signOutErrorAction = (error) => (
    {
        type: SIGNOUT_ERROR,
        error,
    }
);

const initialState = {
    fetching: false,
    error: null,
};

export function signOutReducer(state = initialState, action) {
    switch (action.type) {
        case SIGNOUT:
            return {
                ...state,
                fetching: true,
                error: null,
            };

        case SIGNOUT_ERROR:
            return {
                ...state,
                fetching: false,
                error: action.error,
            };

        default:
            return state;
    }
}