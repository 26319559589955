import { AdminLayout } from '../../layout/admin-layout';
import React from 'react';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { TextField, MenuItem } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import * as moment from 'moment';
import { FormTypes } from '../../../enums/form-types.enum';

const AddContentPageComponent = ({
  classes,
  semesters,
  sections,
  semesterFormTypeAction,
  semesterFormType,
  semesterForm,
  editSemesterFormAction,
  createSemestersAction,
  editSemestersAction,
  semesterNames,
  editSectionFormAction,
  sectionFormTypeAction,
  sectionForm,
  sectionFormType,
  editSectionAction,
  createSectionAction,
  danceClasses,
  editDanceClassFormAction,
  createDanceClassAction,
  editDanceClassAction,
  danceClassFormTypeAction,
  danceClassForm,
  danceClassFormType,
  createDanceClassTimeAction,
  editDanceClassTimeAction,
  editDanceClassTimeFormAction,
  danceClassTimeFormTypeAction,
  danceClassTimeForm,
  danceClassTimeFormType,
  danceClassTimes,
  fetchAllTimesByClassAction
}) => {
  return (
    <AdminLayout>
      <Typography variant="h2">Semesters</Typography>
      <TextField
        id="semester"
        value={semesterForm.selected}
        onChange={e =>
          editSemesterFormAction({ ...e.target.value, selected: e.target.value }) &&
          semesterFormTypeAction(FormTypes.EDIT)
        }
        select
        label="Semester"
        margin="normal"
        className={classes.select}
      >
        {(semesters &&
          semesters.map(semester => (
            <MenuItem key={semester.id} value={semester}>
              {(semester.semesterName && semester.semesterName.name + ' ' + semester.year) || <p />}
            </MenuItem>
          ))) || <p />}
      </TextField>
      <Fab
        onClick={() =>
          editSemesterFormAction({
            id: undefined,
            year: '',
            semesterName: { name: '' },
            startDate: '',
            endDate: '',
            selected: undefined
          }) && semesterFormTypeAction(FormTypes.CREATE)
        }
        color="primary"
        aria-label="Add"
        size="small"
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
      <br />
      {semesterFormType === FormTypes.EDIT && (
        <React.Fragment>
          <Typography variant="h4" className={classes.inline}>
            Edit
          </Typography>
        </React.Fragment>
      )}

      {semesterFormType === FormTypes.CREATE && (
        <React.Fragment>
          <Typography variant="h4" className={classes.inline}>
            Create
          </Typography>
        </React.Fragment>
      )}
      {semesterFormType !== 'None' && (
        <React.Fragment>
          <TextField
            label="Year"
            value={semesterForm.year}
            onChange={e =>
              editSemesterFormAction({
                ...semesterForm,
                year: e.target.value
              })
            }
            className={classes.textField}
            margin="normal"
          />
          <TextField
            label="Semester Name"
            value={semesterNames.find(semesterName => semesterName.id === semesterForm.semesterName.id)}
            onChange={e =>
              editSemesterFormAction({
                ...semesterForm,
                semesterName: e.target.value
              })
            }
            select
            margin="normal"
            className={`${classes.select} ${classes.textField}`}
          >
            {(semesterNames &&
              semesterNames.map(semesterName => (
                <MenuItem key={semesterName.id} value={semesterName}>
                  {semesterName.name}
                </MenuItem>
              ))) || <p />}
          </TextField>
          <TextField
            id="start-date"
            value={moment(semesterForm.startDate).format('YYYY-MM-DD')}
            onChange={e =>
              editSemesterFormAction({
                ...semesterForm,
                startDate: e.target.value
              })
            }
            label="Start Date"
            type="date"
            className={classes.textField}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            id="end-date"
            value={moment(semesterForm.endDate).format('YYYY-MM-DD')}
            onChange={e =>
              editSemesterFormAction({
                ...semesterForm,
                endDate: e.target.value
              })
            }
            label="End Date"
            type="date"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />

          <Fab
            onClick={() => semesterFormTypeAction(FormTypes.NONE)}
            color="secondary"
            aria-label="Cancel"
            size="small"
            className={classes.fab}
          >
            <ClearIcon />
          </Fab>
          <Fab
            onClick={() =>
              ((semesterFormType === FormTypes.EDIT && editSemestersAction(semesterForm)) ||
                (semesterFormType === FormTypes.CREATE && createSemestersAction(semesterForm))) &&
              semesterFormTypeAction(FormTypes.NONE)
            }
            color="primary"
            aria-label="Save"
            size="small"
            className={classes.fab}
          >
            <SaveIcon />
          </Fab>
        </React.Fragment>
      )}
      <Typography variant="h2">Sections</Typography>
      <TextField
        id="sections"
        value={sectionForm.selected}
        onChange={e =>
          editSectionFormAction({ ...e.target.value, selected: e.target.value }) &&
          sectionFormTypeAction(FormTypes.EDIT)
        }
        select
        label="Sections"
        margin="normal"
        className={classes.select}
      >
        {(sections &&
          sections.map(section => (
            <MenuItem key={section.id} value={section}>
              {section.sectionName}
            </MenuItem>
          ))) || <p />}
      </TextField>
      <Fab
        onClick={() =>
          editSectionFormAction({
            id: undefined,
            sectionName: '',
            classesDuration: '',
            sectionAges: '',
            selected: undefined
          }) && sectionFormTypeAction(FormTypes.CREATE)
        }
        color="primary"
        aria-label="Add"
        size="small"
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
      <br />
      {sectionFormType === FormTypes.EDIT && (
        <React.Fragment>
          <Typography variant="h4" className={classes.inline}>
            Edit
          </Typography>
        </React.Fragment>
      )}

      {sectionFormType === FormTypes.CREATE && (
        <React.Fragment>
          <Typography variant="h4" className={classes.inline}>
            Create
          </Typography>
        </React.Fragment>
      )}
      {sectionFormType !== 'None' && (
        <React.Fragment>
          <TextField
            label="Section Name"
            value={sectionForm.sectionName}
            onChange={e =>
              editSectionFormAction({
                ...sectionForm,
                sectionName: e.target.value
              })
            }
            className={classes.textField}
            margin="normal"
          />
          <TextField
            label="Classes Duration"
            value={sectionForm.classesDuration}
            onChange={e =>
              editSectionFormAction({
                ...sectionForm,
                classesDuration: e.target.value
              })
            }
            margin="normal"
            className={`${classes.textField}`}
          />
          <TextField
            label="Section Ages"
            value={sectionForm.sectionAges}
            onChange={e =>
              editSectionFormAction({
                ...sectionForm,
                sectionAges: e.target.value
              })
            }
            margin="normal"
            className={`${classes.textField}`}
          />
          <Fab
            onClick={() => sectionFormTypeAction(FormTypes.NONE)}
            color="secondary"
            aria-label="Cancel"
            size="small"
            className={classes.fab}
          >
            <ClearIcon />
          </Fab>
          <Fab
            onClick={() =>
              ((sectionFormType === FormTypes.EDIT && editSectionAction(sectionForm)) ||
                (sectionFormType === FormTypes.CREATE && createSectionAction(sectionForm))) &&
              sectionFormTypeAction(FormTypes.NONE)
            }
            color="primary"
            aria-label="Save"
            size="small"
            className={classes.fab}
          >
            <SaveIcon />
          </Fab>
        </React.Fragment>
      )}
      <Typography variant="h2">Dance Classes</Typography>
      <TextField
        onChange={e =>
          editDanceClassFormAction({ ...e.target.value, selected: e.target.value }) &&
          danceClassFormTypeAction(FormTypes.EDIT)
        }
        value={danceClassForm.selected}
        id="dance-classes"
        select
        label="Dance Classes"
        margin="normal"
        className={classes.select}
      >
        {(danceClasses &&
          danceClasses.map(danceClass => (
            <MenuItem key={danceClass.id} value={danceClass}>
              {danceClass.className}
            </MenuItem>
          ))) || <p />}
      </TextField>
      <Fab
        onClick={() =>
          editDanceClassFormAction({
            ...danceClassForm,
            sectionId: 1,
            className: '',
            classDescription: '',
            imagePath: 'assets/img/Pre-Ballet.png',
            selected: undefined
          }) && danceClassFormTypeAction(FormTypes.CREATE)
        }
        color="primary"
        aria-label="Add"
        size="small"
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
      <br />
      {danceClassFormType === FormTypes.EDIT && (
        <React.Fragment>
          <Typography variant="h4" className={classes.inline}>
            Edit
          </Typography>
        </React.Fragment>
      )}

      {danceClassFormType === FormTypes.CREATE && (
        <React.Fragment>
          <Typography variant="h4" className={classes.inline}>
            Create
          </Typography>
        </React.Fragment>
      )}
      {danceClassFormType !== 'None' && (
        <React.Fragment>
          <TextField
            label="Class Name"
            value={danceClassForm.className}
            onChange={e =>
              editDanceClassFormAction({
                ...danceClassForm,
                className: e.target.value
              })
            }
            className={classes.textField}
            margin="normal"
          />
          <TextField
            label="Class Description"
            value={danceClassForm.classDescription}
            onChange={e =>
              editDanceClassFormAction({
                ...danceClassForm,
                classDescription: e.target.value
              })
            }
            multiline
            className={classes.textField}
            margin="normal"
          />
          <TextField
            label="Image Path"
            value={danceClassForm.imagePath}
            onChange={e =>
              editDanceClassFormAction({
                ...danceClassForm,
                imagePath: e.target.value
              })
            }
            className={classes.textField}
            margin="normal"
          />
          <TextField
            id="sections"
            value={danceClassForm.sectionId}
            onChange={e =>
              editDanceClassFormAction({
                ...danceClassForm,
                sectionId: e.target.value
              })
            }
            select
            label="Sections"
            margin="normal"
            className={`${classes.select} ${classes.textField}`}
          >
            {(sections &&
              sections.map(section => (
                <MenuItem key={section.id} value={section.id}>
                  {section.sectionName}
                </MenuItem>
              ))) || <p />}
          </TextField>
          <Fab
            onClick={() => danceClassFormTypeAction(FormTypes.NONE)}
            color="secondary"
            aria-label="Cancel"
            size="small"
            className={classes.fab}
          >
            <ClearIcon />
          </Fab>
          <Fab
            onClick={() =>
              ((danceClassFormType === FormTypes.EDIT && editDanceClassAction(danceClassForm)) ||
                (danceClassFormType === FormTypes.CREATE && createDanceClassAction(danceClassForm))) &&
              danceClassFormTypeAction(FormTypes.NONE)
            }
            color="primary"
            aria-label="Save"
            size="small"
            className={classes.fab}
          >
            <SaveIcon />
          </Fab>
        </React.Fragment>
      )}
      <Typography variant="h2">Dance Class Times</Typography>
      <TextField
        onChange={e =>
          editDanceClassTimeFormAction({
            ...danceClassTimeForm,
            classId: e.target.value,
            danceClassId: e.target.value
          }) && fetchAllTimesByClassAction(e.target.value)
        }
        value={danceClassTimeForm.classId}
        id="dance-classes"
        select
        label="Dance Classes"
        margin="normal"
        className={`${classes.select} ${classes.textField}`}
      >
        {(danceClasses &&
          danceClasses.map(danceClass => (
            <MenuItem key={danceClass.id} value={danceClass.id}>
              {danceClass.className}
            </MenuItem>
          ))) || <p />}
      </TextField>
      <TextField
        onChange={e =>
          editDanceClassTimeFormAction({ ...e.target.value, selected: e.target.value }) &&
          danceClassTimeFormTypeAction(FormTypes.EDIT)
        }
        value={danceClassTimeForm.selected}
        id="dance-class-time"
        select
        label="Dance Class Times"
        margin="normal"
        className={classes.select}
      >
        {(danceClassTimes &&
          danceClassTimes.map(danceClassTime => (
            <MenuItem key={danceClassTime.timeId} value={danceClassTime}>
              {danceClassTime.time}
            </MenuItem>
          ))) || <p />}
      </TextField>
      <Fab
        onClick={() =>
          editDanceClassTimeFormAction({
            ...danceClassTimeForm,
            sectionId: 1,
            className: '',
            classDescription: '',
            imagePath: 'assets/img/Pre-Ballet.png',
            selected: undefined
          }) && danceClassTimeFormTypeAction(FormTypes.CREATE)
        }
        color="primary"
        aria-label="Add"
        size="small"
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
      <br />
      {danceClassTimeFormType === FormTypes.EDIT && (
        <React.Fragment>
          <Typography variant="h4" className={classes.inline}>
            Edit
          </Typography>
        </React.Fragment>
      )}

      {danceClassTimeFormType === FormTypes.CREATE && (
        <React.Fragment>
          <Typography variant="h4" className={classes.inline}>
            Create
          </Typography>
        </React.Fragment>
      )}
      {danceClassTimeFormType !== 'None' && (
        <React.Fragment>
          <TextField
            label="Time"
            value={danceClassTimeForm.time}
            onChange={e =>
              editDanceClassTimeFormAction({
                ...danceClassTimeForm,
                time: e.target.value
              })
            }
            className={classes.textField}
            margin="normal"
          />
          <TextField
            label="Class Ages"
            value={danceClassTimeForm.classAges}
            onChange={e =>
              editDanceClassTimeFormAction({
                ...danceClassTimeForm,
                classAges: e.target.value
              })
            }
            className={classes.textField}
            margin="normal"
          />
          <TextField
            id="semester"
            value={semesters.find(
              semester => semester.id === (danceClassTimeForm.semester && danceClassTimeForm.semester.id)
            )}
            onChange={e => editDanceClassTimeFormAction({ ...danceClassTimeForm, semester: e.target.value })}
            select
            label="Semester"
            margin="normal"
            className={`${classes.select} ${classes.textField}`}
          >
            {(semesters &&
              semesters.map(semester => (
                <MenuItem key={semester.id} value={semester}>
                  {semester.semesterName && semester.semesterName.name + ' ' + semester.year}
                </MenuItem>
              ))) || <p />}
          </TextField>
          <TextField
            label="Class Max"
            value={danceClassTimeForm.classMax}
            onChange={e =>
              editDanceClassTimeFormAction({
                ...danceClassTimeForm,
                classMax: e.target.value
              })
            }
            className={classes.textField}
            margin="normal"
          />
          <Fab
            onClick={() => danceClassTimeFormTypeAction(FormTypes.NONE)}
            color="secondary"
            aria-label="Cancel"
            size="small"
            className={classes.fab}
          >
            <ClearIcon />
          </Fab>
          <Fab
            onClick={() =>
              ((danceClassTimeFormType === FormTypes.EDIT && editDanceClassTimeAction(danceClassTimeForm)) ||
                (danceClassTimeFormType === FormTypes.CREATE && createDanceClassTimeAction(danceClassTimeForm))) &&
              danceClassTimeFormTypeAction(FormTypes.NONE)
            }
            color="primary"
            aria-label="Save"
            size="small"
            className={classes.fab}
          >
            <SaveIcon />
          </Fab>
        </React.Fragment>
      )}
    </AdminLayout>
  );
};

const styles = theme => ({
  fab: {
    margin: theme.spacing.unit,
    marginTop: '25px'
  },
  select: {
    width: '150px'
  },
  textField: {
    marginLeft: 0, // theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 250,
    display: 'flex'
  }
});

export const AddContentPage = withStyles(styles)(AddContentPageComponent);
