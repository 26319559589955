export const FETCH_TIMES_BY_CLASS = 'FETCH_TIMES_BY_CLASS';
export const FETCH_ALL_TIMES_BY_CLASS = 'FETCH_ALL_TIMES_BY_CLASS';
export const CREATE_DANCE_CLASS_TIME = 'CREATE_DANCE_CLASS_TIME';
export const EDIT_DANCE_CLASS_TIME = 'EDIT_DANCE_CLASS_TIME';
export const FETCH_TIMES_BY_CLASS_SUCCESS = 'FETCH_TIMES_BY_CLASS_SUCCESS';
export const FETCH_ALL_TIMES_BY_CLASS_SUCCESS = 'FETCH_ALL_TIMES_BY_CLASS_SUCCESS';
export const FETCH_TIMES_BY_CLASS_ERROR = 'FETCH_TIMES_BY_CLASS_ERROR';

export const fetchTimesByClassAction = payload => {
  return {
    type: FETCH_TIMES_BY_CLASS,
    payload
  };
};

export const fetchAllTimesByClassAction = payload => {
  return {
    type: FETCH_ALL_TIMES_BY_CLASS,
    payload
  };
};

export const createDanceClassTimeAction = payload => {
  return {
    type: CREATE_DANCE_CLASS_TIME,
    payload
  };
};

export const editDanceClassTimeAction = payload => {
  return {
    type: EDIT_DANCE_CLASS_TIME,
    payload
  };
};

export const fetchTimesByClassSuccessAction = times => ({
  type: FETCH_TIMES_BY_CLASS_SUCCESS,
  times
});

export const fetchAllTimesByClassSuccessAction = times => ({
  type: FETCH_ALL_TIMES_BY_CLASS_SUCCESS,
  times
});

export const fetchTimesByClassErrorAction = error => ({
  type: FETCH_TIMES_BY_CLASS_ERROR,
  error
});

const initialState = {
  fetching: false,
  times: [],
  timesByClass: [],
  error: null
};

export function timesByClassReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TIMES_BY_CLASS:
      return {
        ...state,
        fetching: true,
        error: null
      };

    case FETCH_TIMES_BY_CLASS_SUCCESS:
      return {
        ...state,
        fetching: false,
        times: {
          ...state.times,
          [action.times.name]: action.times.times
        },
        error: null
      };

    case FETCH_ALL_TIMES_BY_CLASS_SUCCESS:
      return {
        ...state,
        fetching: false,
        timesByClass: action.times.times,
        error: null
      };

    case FETCH_TIMES_BY_CLASS_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.error
      };

    default:
      return state;
  }
}
