import { combineReducers } from 'redux';
import { fetchDancersReducer } from './fetch-dancers.reducer';

const initialState = {};

export function dancersReducer(state = initialState, action) {
  let dancersReducer = combineReducers({
    dancersSlice: fetchDancersReducer
  });
  return dancersReducer(state, action);
}
