export const SIGNIN = 'SIGNIN';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_ERROR = 'SIGNIN_ERROR';

export const signInAction = payload => {
  return {
    type: SIGNIN,
    payload
  };
};

export const signInSuccessAction = payload => {
  return {
    type: SIGNIN_SUCCESS,
    payload
  };
};

export const signInErrorAction = error => ({
  type: SIGNIN_ERROR,
  error
});

const initialState = {
  fetching: false,
  error: null,
  statusCode: null
};

export function signInReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNIN:
      return {
        ...state,
        fetching: true,
        error: null
      };

    case SIGNIN_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        statusCode: action.payload
      };

    case SIGNIN_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.error
      };

    default:
      return state;
  }
}
