import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../../util';
import {
  changePasswordSuccessAction,
  changePasswordErrorAction,
  changePasswordAction
} from './change-password.reducer';
import { signInSuccessAction } from '../sign-in/sign-in.reducer';

export function* changePasswordWorkerSaga(action) {
  try {
    const response = yield call(axios, {
      method: 'POST',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/authentication/changePassword`,
      data: action.payload,
      withCredentials: true
    });

    yield put(changePasswordSuccessAction(response.status));

    window.location.href = '/';
  } catch (error) {
    yield put(changePasswordErrorAction(error.response));
  }
}

export function* checkPasswordWorkerSaga(action) {
  try {
    const response = yield call(axios, {
      method: 'POST',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/authentication/signin`,
      data: action.payload,
      withCredentials: true
    });

    yield put(signInSuccessAction(response.status));
    if (response.status === 200 && action.payload.password === action.payload.checkPassword) {
      yield put(
        changePasswordAction({
          Username: action.payload.username,
          Password: action.payload.password
        })
      );
    }
  } catch (error) {
    yield put(changePasswordErrorAction(error.response));
  }
}
