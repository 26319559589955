import React from 'react';
import { Typography, withStyles } from '@material-ui/core';

const HeadingComponent = props => {
  const { component: Component = 'div', classes, ...other } = props;
  return (
    <Typography variant='h5' component='h2' className={classes.heading}>
      <Component {...other} />
    </Typography>
  );
};

const styles = theme => ({
  heading: {
    fontSize: '3rem',
    textAlign: 'center'
  }
});

export const Heading = withStyles(styles)(HeadingComponent);
