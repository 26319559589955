import get from 'lodash/get';
import validator from 'validator';
import { Types } from '../area/util/validations';

export const useValidation = {
  validatorjs(a, prop) {
    if (a.length === 0) return true;
    const [validatorType, ...args] = a;
    const propAsString = `${prop}`;
    if (!validator[validatorType]) {
      // eslint-disable-next-line no-console
      console.warn(
        `validator library does not support ${validatorType} validation function`
      );
      return false;
    }
    return validator[validatorType](propAsString, ...args);
  },
  useFieldValidation(conf, key, prop) {
    const validations = conf
      .map(v => {
        let isValid;
        switch (v.type) {
          case Types.pure:
            isValid = v.isValid(prop);
            break;
          case Types.validatorJS:
            isValid = this.validatorjs(v.validation, prop);
            break;
          default:
            return { isValid: false, msg: 'Validator Type is not supported' };
        }
        return { isValid, msg: isValid ? '' : v.msg(key) };
      })
      .filter(v => v);
    const isValid = validations.every(v => v.isValid);
    const msg = validations.reduce((a, m) => {
      if (m.msg !== '') {
        if (a !== '') {
          a += '\n';
        }
        a += m.msg;
      }
      return a;
    }, '');
    return {
      isValid,
      msg
    };
  },
  validateForm(conf, model) {
    const validations = Object.entries(conf).map(
      ([propName, fieldValidation]) => {
        const prop = get(model, propName, '');
        return this.useFieldValidation(fieldValidation, propName, prop);
      }
    );
    return validations;
  }
};
