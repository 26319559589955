import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Header } from '../header/header';
import { Footer } from '../footer/footer.jsx';

import Paper from '@material-ui/core/Paper';

const CalendarPageComponent = props => {
  const { classes } = props;
  return (
    <div>
      <Header />
      <div className={classes.spacer} />
      <div className={classes.center}>
        <div>
          <Paper className={classes.root + ' ' + classes.paper} elevation={1}>
            <iframe
              src='https://calendar.google.com/calendar/embed?src=9e7nr5edi9ucpdush8dht6eb6o%40group.calendar.google.com&ctz=America%2FDenver'
              title='Scholarship Application'
              width='700'
              height='1640'
              frameBorder='0'
              marginHeight='0'
              marginWidth='0'
            >
              Loading...
            </iframe>
          </Paper>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const styles = {
  root: {
    paddingTop: 20,
    paddingBottom: 20
  },
  spacer: {
    padding: '20px'
  },
  paper: {
    margin: 'auto',
    marginTop: '4em',
    marginBottom: '4em',
    maxWidth: '50em',
    layout: 'center'
  },
  heading: {
    fontSize: '4rem'
  },
  content: {
    fontSize: '1rem',
    textAlign: 'left',
    padding: '10px',
    whiteSpace: 'pre-wrap'
  },
  center: {
    textAlign: 'center'
  }
};

export const CalendarPage = withStyles(styles)(CalendarPageComponent);
