import React from 'react';
import PropTypes from 'prop-types';
import { useValidation } from '../../hooks/useValidation';
import momentPropTypes from 'react-moment-proptypes';

export const RulesPropTypes = PropTypes.arrayOf(PropTypes.shape({}));

export const withValidations = WrappedComponent => {
  const validationWrapper = props => {
    // Two separate deconstructions here to let ...rest contain the name/label/value
    const { rules, formSubmitted, ...rest } = props;
    const { validationName, name, label, value } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const validations = useValidation.useFieldValidation(
      rules,
      validationName || label || name,
      value
    );
    const validationParams = !formSubmitted
      ? {}
      : {
          hasError: !validations.isValid,
          isValid: validations.isValid,
          helperText: validations.msg
        };
    return <WrappedComponent {...validationParams} {...rest} />;
  };

  validationWrapper.defaultProps = {
    rules: [],
    formSubmitted: false,
    label: ''
  };

  validationWrapper.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      momentPropTypes.momentObj,
      PropTypes.instanceOf(Date)
    ]).isRequired,
    formSubmitted: PropTypes.bool,
    rules: PropTypes.arrayOf(PropTypes.shape({}))
  };
  return validationWrapper;
};
