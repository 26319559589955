import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';

const SemesterClassStudentListComponent = props => {
  const { rows, dancers, setRedirectEmail, classes } = props;
  return (
    <Fragment>
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          setRedirectEmail(true);
        }}
      >
        Email
      </Button>

      <br />

      {dancers.map(dancer => (
        <div key={dancer.id} style={{ padding: '20px' }}>
          <Typography>{dancer.className}</Typography>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align='right'>Guardian</TableCell>
                <TableCell align='right'>Gender</TableCell>
                <TableCell align='right'>DoB</TableCell>
                <TableCell align='right'>Phone</TableCell>
                <TableCell align='right'>Email</TableCell>
              </TableRow>
            </TableHead>
            {rows.map(row => {
              return (
                <TableBody key={row.id}>
                  {dancer.students &&
                    dancer.students.map(dancer => (
                      <TableRow key={dancer.id}>
                        <TableCell>{`${dancer.firstName} ${dancer.lastName}`}</TableCell>
                        <TableCell>{`${dancer.myIdentityUser.firstName} ${dancer.myIdentityUser.lastName}`}</TableCell>
                        <TableCell>
                          {dancer.gender === 'Female' ? 'F' : 'M'}
                        </TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat('en-US').format(
                            new Date(dancer.birthdate)
                          )}
                        </TableCell>
                        <TableCell>
                          {dancer.myIdentityUser.phoneNumber}
                        </TableCell>
                        <TableCell>{dancer.myIdentityUser.email}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              );
            })}
          </Table>
        </div>
      ))}
    </Fragment>
  );
};

const styles = theme => ({});

export const SemesterClassStudentList = withStyles(styles)(
  SemesterClassStudentListComponent
);
