import { DancerPage } from './dancer-page';
import React, { Fragment } from 'react';
import { getBaseUrl, getApiPort, getProtocol } from '../../../util';
import axios from 'axios';
import { useAsync } from '../../../hooks/useAsync';
import { LoadingPage } from '../../loading/loading-page.component';
import { DancerCard } from './dancer-card';

export const DancerCardContainer = props => {
  const { history, dancer, year } = props;
  const fetchSemesterRegistration = () => {
    return axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Registration/semester-registrations/current`,
      withCredentials: true
    });
  };
  const fetchRegistrationPayment = () => {
    return axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Payments/registration`,
      withCredentials: true
    });
  };
  const fetchEnrollments = () => {
    return axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Enrollment/Dancers/${
        dancer.id
      }`,
      withCredentials: true
    });
  };
  const {
    pending: isLoading,
    // rejected: hasError,
    resolved: [semesterRegistration, SemesterPayment, enrollments] = []
  } = useAsync({
    fn: () =>
      Promise.all([
        fetchSemesterRegistration(),
        fetchRegistrationPayment(),
        fetchEnrollments()
      ]).then(response => response.map(r => r.data))
  });
  return (
    <Fragment>
      {isLoading && <LoadingPage />}
      {dancer && semesterRegistration && (
        <DancerCard
          dancer={dancer}
          year={year}
          registered={semesterRegistration.id !== 0}
          semesterPaid={SemesterPayment.id !== 0}
          enrollments={enrollments}
          history={history}
        />
      )}
    </Fragment>
  );
};
