import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import { formatCurrency } from '../util/currency';

export const PaymentTable = props => {
  const { PaymentDictionary } = props;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Item</TableCell>
          <TableCell align='right'>Price</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(PaymentDictionary).map((key, index) => (
          <TableRow key={index}>
            <TableCell align={key === 'Total' ? 'right' : 'left'}>
              {key}
            </TableCell>
            <TableCell align='right'>
              ${formatCurrency(PaymentDictionary[key])}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
