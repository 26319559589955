import React, { Fragment, useState } from 'react';
import { getBaseUrl, getApiPort, getProtocol } from '../../../util';
import axios from 'axios';
import { useAsync } from '../../../hooks/useAsync';
import { LoadingPage } from '../../loading/loading-page.component';
import { TuitionPaymentPage } from './tuition-payment-page';
import { Elements } from 'react-stripe-elements';
import { fetchUserAction } from '../../../store/area/authentication/users/fetch-user.reducer';
import { userSelector } from '../../../store/area/authentication/users/fetch-user.selector';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withLifecycle from '@hocs/with-lifecycle';

export const TuitionPaymentPageContainerComponent = props => {
  const { user, history, match } = props;
  const { year, month } = match.params;
  const fetchTuitionCost = () => {
    return axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Payments/tuition/fees/year/${year}/month/${month}`,
      withCredentials: true
    });
  };
  const {
    pending: isLoading,
    // rejected: hasError,
    resolved: tuition = []
  } = useAsync({
    fn: () =>
      Promise.resolve(fetchTuitionCost()).then(responces => responces.data)
  });
  return (
    <Fragment>
      {isLoading && <LoadingPage />}
      {!isLoading && (
        <Elements>
          <TuitionPaymentPage
            history={history}
            tuition={tuition.items}
            user={user}
            year={tuition.paymentYear}
            month={tuition.paymentMonth}
          />
        </Elements>
      )}
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    user: userSelector(state)
  };
};

const mapDispatchToProps = {
  fetchUserAction
};

export const TuitionPaymentPageContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLifecycle({
    onDidMount({ fetchUserAction }) {
      fetchUserAction();
    }
  })
)(TuitionPaymentPageContainerComponent);
