export const IS_AUTHENTICATED = 'IS_AUTHENTICATED';
export const IS_AUTHENTICATED_SUCCESS = 'IS_AUTHENTICATED_SUCCESS';
export const IS_AUTHENTICATED_ERROR = 'IS_AUTHENTICATED_ERROR';

export const isAuthenticatedAction = payload => {
  return {
    type: IS_AUTHENTICATED,
    payload
  };
};

export const isAuthenticatedSuccessAction = isAuthenticated => ({
  type: IS_AUTHENTICATED_SUCCESS,
  isAuthenticated
});

export const isAuthenticatedErrorAction = error => ({
  type: IS_AUTHENTICATED_ERROR,
  error
});

const initialState = {
  fetching: false,
  isAuthenticated: false,
  authCalled: false,
  error: null
};

export function isAuthenticatedReducer(state = initialState, action) {
  switch (action.type) {
    case IS_AUTHENTICATED:
      return {
        ...state,
        fetching: true,
        authCalled: true,
        error: null
      };

    case IS_AUTHENTICATED_SUCCESS:
      return {
        ...state,
        fetching: false,
        isAuthenticated: action.isAuthenticated,
        error: null
      };

    case IS_AUTHENTICATED_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.error
      };

    default:
      return state;
  }
}
