import { compose } from 'recompose';
import { connect } from 'react-redux';
import withLifecycle from '@hocs/with-lifecycle';
import { AddContentPage } from './add-content-page.component';
import {
  semesterSelector,
  semesterFormTypeSelector,
  semesterFormSelector,
  semesterNamesSelector
} from '../../../store/area/dance-classes/semesters/semester.selector';
import {
  fetchSemestersAction,
  createSemestersAction,
  editSemestersAction,
  fetchSemesterNamesAction
} from '../../../store/area/dance-classes/semesters/fetch-semesters.reducer';
import {
  sectionsSelector,
  sectionFormSelector,
  sectionFormTypeSelector
} from '../../../store/area/dance-classes/sections/sections.selector';
import {
  danceClassesSelector,
  danceClassFormSelector,
  danceClassFormTypeSelector
} from '../../../store/area/dance-classes/dance-classes/dance-classes.selector';
import {
  danceClassFormTypeAction,
  editDanceClassFormAction
} from '../../../store/area/dance-classes/dance-classes/dance-classes-form.reducer';
import {
  createDanceClassAction,
  editDanceClassAction
} from '../../../store/area/dance-classes/dance-classes/fetch-classes-by-section.reducer';
import {
  fetchSectionsAction,
  createSectionAction,
  editSectionAction
} from '../../../store/area/dance-classes/sections/api-sections.reducer';
import {
  editSemesterFormAction,
  semesterFormTypeAction
} from '../../../store/area/dance-classes/semesters/semester-form.reducer';
import {
  editSectionFormAction,
  sectionFormTypeAction
} from '../../../store/area/dance-classes/sections/sections-form.reducer';
import { fetchDanceClassesAction } from '../../../store/area/dance-classes/dance-classes/fetch-classes-by-section.reducer';
import {
  createDanceClassTimeAction,
  editDanceClassTimeAction,
  fetchAllTimesByClassAction
} from '../../../store/area/dance-classes/dance-class-times/fetch-times-by-class.reducer';
import {
  danceClassTimeFormTypeAction,
  editDanceClassTimeFormAction
} from '../../../store/area/dance-classes/dance-class-times/dance-class-times-form.reducer';
import {
  danceClassTimeFormSelector,
  classTimesByClassSelector,
  danceClassTimeFormTypeSelector
} from '../../../store/area/dance-classes/dance-class-times/dance-class-times.selector';

const mapStateToProps = state => {
  return {
    semesters: semesterSelector(state),
    sections: sectionsSelector(state),
    danceClasses: danceClassesSelector(state),
    danceClassForm: danceClassFormSelector(state),
    danceClassFormType: danceClassFormTypeSelector(state),
    semesterFormType: semesterFormTypeSelector(state),
    semesterForm: semesterFormSelector(state),
    semesterNames: semesterNamesSelector(state),
    sectionForm: sectionFormSelector(state),
    sectionFormType: sectionFormTypeSelector(state),
    danceClassTimeForm: danceClassTimeFormSelector(state),
    danceClassTimeFormType: danceClassTimeFormTypeSelector(state),
    danceClassTimes: classTimesByClassSelector(state)
  };
};

const mapDispatchToProps = {
  fetchSemestersAction,
  fetchSectionsAction,
  semesterFormTypeAction,
  editSemesterFormAction,
  createSemestersAction,
  editSemestersAction,
  fetchSemesterNamesAction,
  fetchDanceClassesAction,
  editSectionFormAction,
  sectionFormTypeAction,
  editSectionAction,
  createSectionAction,
  createDanceClassAction,
  editDanceClassAction,
  danceClassFormTypeAction,
  editDanceClassFormAction,
  createDanceClassTimeAction,
  editDanceClassTimeAction,
  danceClassTimeFormTypeAction,
  editDanceClassTimeFormAction,
  fetchAllTimesByClassAction
};

export const AddContentPageContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withLifecycle({
    onDidMount({ fetchSemestersAction, fetchSectionsAction, fetchSemesterNamesAction, fetchDanceClassesAction }) {
      fetchSemestersAction();
      fetchSectionsAction({ sectionsOnly: true });
      fetchSemesterNamesAction();
      fetchDanceClassesAction();
    }
  })
)(AddContentPage);
