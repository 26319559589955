import React, { Fragment, useState } from 'react';
import { getBaseUrl, getApiPort, getProtocol } from '../../../util/http';
import axios from 'axios';
import { useAsync } from '../../../../hooks/useAsync';
import { LoadingPage } from '../../../loading/loading-page.component';
import { Elements } from 'react-stripe-elements';
import { fetchUserAction } from '../../../../store/area/authentication/users/fetch-user.reducer';
import { userSelector } from '../../../../store/area/authentication/users/fetch-user.selector';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withLifecycle from '@hocs/with-lifecycle';
import { AdminEditAccount } from './admin-edit-account';

const AdminEditAccountContainerComponent = props => {
  const { user, history, match } = props;
  const { accountId } = match.params;
  const fetchUserAccounts = () => {
    return axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Authentication/Admin/users/${accountId}`,
      withCredentials: true
    });
  };
  const fetchUserPayment = () => {
    return axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Payments/Admin/registration/${accountId}`,
      withCredentials: true
    });
  };
  const {
    pending: isLoading,
    // rejected: hasError,
    resolved: [account, payment] = []
  } = useAsync({
    fn: () =>
      Promise.all([fetchUserAccounts(), fetchUserPayment()]).then(responces =>
        responces.map(r => r.data)
      )
  });
  return (
    <Fragment>
      {isLoading && <LoadingPage />}
      {!isLoading && (
        <Elements>
          <AdminEditAccount
            history={history}
            account={account}
            payment={payment}
          />
        </Elements>
      )}
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    user: userSelector(state)
  };
};

const mapDispatchToProps = {
  fetchUserAction
};

export const AdminEditAccountContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLifecycle({
    onDidMount({ fetchUserAction }) {
      fetchUserAction();
    }
  })
)(AdminEditAccountContainerComponent);
