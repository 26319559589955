import { call, put } from 'redux-saga/effects';
import { fetchSectionsSuccessAction, fetchSectionsErrorAction } from './api-sections.reducer';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../../util';
import { fetchClassesBySectionAction } from '../dance-classes/fetch-classes-by-section.reducer';

export function* fetchSectionsWorkerSaga(action) {
  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Section`
    });

    yield put(fetchSectionsSuccessAction(response.data));

    if (!action.payload.sectionsOnly) {
      for (let i = 0; i < response.data.length; ++i) {
        yield put(fetchClassesBySectionAction({ sectionId: i + 1, semesterId: action.payload.semesterId }));
      }
    }
  } catch (error) {
    yield put(fetchSectionsErrorAction(error.data));
  }
}

export function* createSectionWorkerSaga(action) {
  try {
    yield call(axios, {
      method: 'POST',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Section`,
      data: action.payload,
      withCredentials: true
    });
  } catch (error) {
    yield put(fetchSectionsErrorAction(error.data));
  }
}

export function* editSectionWorkerSaga(action) {
  try {
    yield call(axios, {
      method: 'PUT',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Section`,
      data: action.payload,
      withCredentials: true
    });
  } catch (error) {
    yield put(fetchSectionsErrorAction(error.data));
  }
}
