import { compose } from 'recompose';
import withLifecycle from '@hocs/with-lifecycle';
import { connect } from 'react-redux';
import { DanceClassesPage } from './dance-classes-page';
import { fetchSectionsAction } from '../../store/area/dance-classes/sections/api-sections.reducer';
import { fetchSemestersAction } from '../../store/area/dance-classes/semesters/fetch-semesters.reducer';
import { fetchClassesBySectionAction } from '../../store/area/dance-classes/dance-classes/fetch-classes-by-section.reducer';
import { danceClassesBySectionSelector } from '../../store/area/dance-classes/dance-classes/dance-classes.selector';
import { classTimesSelector } from '../../store/area/dance-classes/dance-class-times/dance-class-times.selector';
import { sectionsSelector } from '../../store/area/dance-classes/sections/sections.selector';
import {
  semesterSelector,
  currentSemesterSelector,
} from '../../store/area/dance-classes/semesters/semester.selector.js';
import { rolesSelector } from '../../store/area/authentication/roles/fetch-role.selector';
import { fetchRoleAction } from '../../store/area/authentication/roles/fetch-role.reducer';

const mapStateToProps = (state) => {
  return {
    times: classTimesSelector(state),
    sections: sectionsSelector(state),
    danceClasses: danceClassesBySectionSelector(state),
    semesters: semesterSelector(state),
    currentSemester: currentSemesterSelector(state),
    roles: rolesSelector(state),
  };
};

const mapDispatchToProps = {
  fetchSectionsAction,
  fetchClassesBySectionAction,
  fetchSemestersAction,
  fetchRoleAction,
};

export const DanceClassesPageContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withLifecycle({
    onDidMount({ fetchSectionsAction, fetchSemestersAction, currentSemester }) {
      // fetchRoleAction();
      // fetchSemestersAction();
      // fetchSectionsAction({ semesterId: undefined });
    },
  })
)(DanceClassesPage);
