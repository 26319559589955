import React, { Fragment } from 'react';
import { getBaseUrl, getApiPort, getProtocol } from '../../../util';
import axios from 'axios';
import { useAsync } from '../../../hooks/useAsync';
import { LoadingPage } from '../../loading/loading-page.component';
import { DancersCard } from './dancers-card';

export const DancersCardContainer = props => {
  const fetchDancers = () => {
    return axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Dancers`,
      withCredentials: true
    });
  };
  const fetchSemesterRegistration = () => {
    return axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Registration/semester-registrations/current`,
      withCredentials: true
    });
  };
  const fetchRegistrationPayment = () => {
    return axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Payments/registration`,
      withCredentials: true
    });
  };
  const {
    pending: isLoading,
    // rejected: hasError,
    resolved: [dancers, semesterRegistration, SemesterPayment] = []
  } = useAsync({
    fn: () =>
      Promise.all([
        fetchDancers(),
        fetchSemesterRegistration(),
        fetchRegistrationPayment()
      ]).then(response => response.map(r => r.data))
  });
  return (
    <Fragment>
      {isLoading && <LoadingPage />}
      {dancers && (
        <DancersCard
          dancers={dancers}
          registered={semesterRegistration.id !== 0}
          semesterPaid={SemesterPayment.id !== 0}
        />
      )}
    </Fragment>
  );
};
