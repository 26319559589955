import { combineReducers } from 'redux';
import { signInReducer } from './sign-in/sign-in.reducer';
import { userReducer } from './users/fetch-user.reducer';
import { roleReducer } from './roles/fetch-role.reducer';
import { isAuthenticatedReducer } from './is-authenticated/is-authenticated.reducer';
import { changePasswordReducer } from './change-password/change-password.reducer';

const initialState = {};

export function authenticationReducer(state = initialState, action) {
  let authenticationReducer = combineReducers({
    signInSlice: signInReducer,
    userSlice: userReducer,
    rolesSlice: roleReducer,
    isAuthenticatedSlice: isAuthenticatedReducer,
    changePasswordSlice: changePasswordReducer
  });
  return authenticationReducer(state, action);
}
