import { CircularProgress } from '@material-ui/core';
import { getBaseUrl, getApiPort, getProtocol } from '../../../../util';
import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { RegistrationForm } from './registration-form';

export const RegistrationFormContainer = props => {
  const { history } = props;
  const [semesterAgreements, setAgreements] = useState('Loading...');

  useEffect(() => {
    fetchAgreements();
  }, []);

  const fetchAgreements = async () => {
    let response = await axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Registration/semester-agreements/current`,
      withCredentials: true
    });
    setAgreements(response.data);
  };
  return (
    <Fragment>
      {semesterAgreements !== 'Loading...' && (
        <RegistrationForm
          semesterAgreements={semesterAgreements}
          history={history}
        />
      )}
      {semesterAgreements === 'Loading' && <CircularProgress />}
    </Fragment>
  );
};
