import React, { Fragment, useState } from 'react';
import { getBaseUrl, getApiPort, getProtocol } from '../../../util';
import axios from 'axios';
import { useAsync } from '../../../hooks/useAsync';
import { LoadingPage } from '../../loading/loading-page.component';
import { OverviewCard } from './overview-card';
import moment from 'moment';

export const OverviewCardContainer = props => {
  const { admin, accountId } = props;
  const now = moment();
  const defaultDate = now.format('DD') > 15 ? now.add(1, 'month') : now;
  const [tuitionMonth, setTuitionMonth] = useState({
    year: defaultDate.format('YYYY'),
    month: defaultDate.format('MM')
  });
  const fetchTuitionCost = () => {
    const url = admin
      ? `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Payments/admin/account/${accountId}/tuition/fees/year/${
          tuitionMonth.year
        }/month/${tuitionMonth.month}`
      : `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Payments/tuition/fees/year/${
          tuitionMonth.year
        }/month/${tuitionMonth.month}`;
    return axios({
      method: 'GET',
      url,
      withCredentials: true
    });
  };
  const fetchTuitionPayment = () => {
    const url = admin
      ? `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Payments/admin/account/${accountId}/tuition/year/${
          tuitionMonth.year
        }/month/${tuitionMonth.month}`
      : `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Payments/tuition/year/${
          tuitionMonth.year
        }/month/${tuitionMonth.month}`;
    return axios({
      method: 'GET',
      url,
      withCredentials: true
    });
  };
  const {
    pending: isLoading,
    // rejected: hasError,
    resolved: [tuition, tuition_payment] = []
  } = useAsync(
    {
      fn: () =>
        Promise.all([
          fetchTuitionCost(),
          fetchTuitionPayment()
        ]).then(responces => responces.map(r => r.data))
    },
    [tuitionMonth]
  );
  return (
    <Fragment>
      {isLoading && <LoadingPage />}
      {!isLoading && (
        <OverviewCard
          admin={admin}
          accountId={accountId}
          tuition={tuition.items}
          tuition_payment={tuition_payment}
          tuitionMonth={tuitionMonth}
          setTuitionMonth={setTuitionMonth}
        />
      )}
    </Fragment>
  );
};
