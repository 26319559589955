import React, { useState, Fragment } from 'react';
import { Page } from '../../../page/page';
import Validations from '../../../util/validations';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useFormState, { Form } from '../../../../hooks/useFormState';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../../util';
import { Redirect } from 'react-router';
import { routes } from '../../../../routes';
import { RegistrationFormInputs } from './registration-form-inputs';
import moment from 'moment';
import { ConfirmationModal } from '../../../modals/confirmation-modal';
import { CircularProgress } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';

const RegistrationFormComponent = props => {
  const { classes, semesterAgreements, history } = props;
  const [redirect, setRedirect] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);

  let validation = {
    registrationDigitalSignature: [Validations.required]
  };
  let fieldValidations = () => validation;

  const handleSubmit = async ({ state, isValid }) => {
    if (isValid) {
      await axios({
        method: 'POST',
        url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Registration/semester-registrations`,
        data: {
          Year: state.year,
          RegistrationDigitalSignature: state.registrationDigitalSignature,
          RegistrationTodaysDate: state.registrationTodaysDate,
          SemesterAgreementId: state.semesterAgreementId
        },
        withCredentials: true
      });

      setModalOpen(true);
    }
    return Promise.reject();
  };

  let defaultState = {
    registrationDigitalSignature: '',
    registrationTodaysDate: moment(),
    year: semesterAgreements.year,
    semesterAgreementId: semesterAgreements.id,
    checkbox: true
  };

  semesterAgreements.agreements &&
    semesterAgreements.agreements.map(
      agreement => (validation[agreement.id] = [Validations.isChecked])
    );

  semesterAgreements.agreements &&
    semesterAgreements.agreements.map(
      agreement => (defaultState[agreement.id] = undefined)
    );

  const {
    formState,
    onFormChange,
    onSubmit,
    resetState,
    isUpdating,
    // response,
    // hasError,
    hasSubmitted
    // setFormState
  } = useFormState(defaultState, handleSubmit, fieldValidations);

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <Fragment>
      {semesterAgreements !== 'Loading...' && (
        <Page>
          <IconButton onClick={history.goBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant='h4' className={classes.textField} component='p'>
            Registration Form
          </Typography>

          <Form
            onSubmit={onSubmit}
            isUpdating={isUpdating}
            handleReset={resetState}
            hasSubmitted={hasSubmitted}
            noReset
          >
            <RegistrationFormInputs
              model={formState}
              semesterAgreements={semesterAgreements}
              handleChange={onFormChange}
              hasSubmitted={hasSubmitted}
              validations={fieldValidations(formState)}
              isEditing={false}
            />
          </Form>
          <ConfirmationModal
            name='Thanks for submitting!'
            handleClose={() => setRedirect(routes.accountDashboardPageRoute)}
            open={modalOpen}
          />
        </Page>
      )}
      {semesterAgreements === 'Loading' && <CircularProgress />}
    </Fragment>
  );
};

const styles = theme => ({
  root: {
    paddingTop: 20,
    paddingBottom: 20
  },
  spacer: {
    padding: '20px'
  },
  paper: {
    margin: 'auto',
    marginTop: '4em',
    marginBottom: '4em',
    maxWidth: '300px'
    // layout: 'center'
  },
  heading: {
    fontSize: '4rem'
  },
  content: {
    fontSize: '1rem',
    textAlign: 'left',
    padding: '10px',
    whiteSpace: 'pre-wrap'
  },
  center: {
    textAlign: 'center'
  },
  button: {
    display: 'inline-flex',
    padding: '0 50px',
    margin: '20px 0'
  },
  error: {
    color: 'red'
  },
  textField: {
    margin: theme.spacing.unit,
    display: 'flex'
  },
  recaptcha: {
    margin: '8px'
  }
});

export const RegistrationForm = withStyles(styles)(RegistrationFormComponent);
