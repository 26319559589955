import { FormTypes } from '../../../../enums/form-types.enum';

export const DANCE_CLASS_FORM_TYPE = 'DANCE_CLASS_FORM_TYPE';
export const EDIT_DANCE_CLASS_FORM = 'EDIT_DANCE_CLASS_FORM';

export const danceClassFormTypeAction = formType => {
  return {
    type: DANCE_CLASS_FORM_TYPE,
    formType
  };
};

export const editDanceClassFormAction = form => {
  return {
    type: EDIT_DANCE_CLASS_FORM,
    form
  };
};

const initialState = {
  formType: FormTypes.NONE,
  form: {
    sectionId: 1,
    className: '',
    classDescription: '',
    imagePath: 'assets/img/Pre-Ballet.png',
    selected: ''
  }
};

export function danceClassFormReducer(state = initialState, action) {
  switch (action.type) {
    case DANCE_CLASS_FORM_TYPE:
      return {
        ...state,
        formType: action.formType
      };

    case EDIT_DANCE_CLASS_FORM:
      return {
        ...state,
        form: action.form
      };

    default:
      return state;
  }
}
