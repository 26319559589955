import React, { Fragment, useState } from 'react';
import { getBaseUrl, getApiPort, getProtocol } from '../../../util';
import axios from 'axios';
import { useAsync } from '../../../hooks/useAsync';
import { LoadingPage } from '../../loading/loading-page.component';
import { Elements } from 'react-stripe-elements';
import { fetchUserAction } from '../../../store/area/authentication/users/fetch-user.reducer';
import { userSelector } from '../../../store/area/authentication/users/fetch-user.selector';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withLifecycle from '@hocs/with-lifecycle';
import { AdminAccountsPage } from './admin-accounts-page';

const AdminAccountsPageContainerComponent = props => {
  const { user, history } = props;
  const fetchUserAccounts = () => {
    return axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Authentication/Admin/users`,
      withCredentials: true
    });
  };
  const {
    pending: isLoading,
    // rejected: hasError,
    resolved: accounts = []
  } = useAsync({
    fn: () =>
      Promise.resolve(fetchUserAccounts()).then(responces => responces.data)
  });
  return (
    <Fragment>
      {isLoading && <LoadingPage />}
      {!isLoading && (
        <Elements>
          <AdminAccountsPage history={history} accounts={accounts} />
        </Elements>
      )}
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    user: userSelector(state)
  };
};

const mapDispatchToProps = {
  fetchUserAction
};

export const AdminAccountsPageContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLifecycle({
    onDidMount({ fetchUserAction }) {
      fetchUserAction();
    }
  })
)(AdminAccountsPageContainerComponent);
