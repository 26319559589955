import { ProtectedRouter } from './protected-route';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { isAuthenticatedAction } from '../store/area/authentication/is-authenticated/is-authenticated.reducer';
import {
  isAuthenticatedSelector,
  isAuthenticatedLoadingSelector,
  authCalledSelector
} from '../store/area/authentication/is-authenticated/is-authenticated.selector';
import withLifecycle from '@hocs/with-lifecycle';

const mapStateToProps = state => {
  return {
    isAuthenticated: isAuthenticatedSelector(state),
    isAuthenticatedLoading: isAuthenticatedLoadingSelector(state),
    authCalled: authCalledSelector(state)
  };
};

const mapDispatchToProps = {
  isAuthenticatedAction
};

export const ProtectedRoute = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withLifecycle({
    onDidMount({ isAuthenticatedAction }) {
      isAuthenticatedAction();
    }
  })
)(ProtectedRouter);
