export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';

export const fetchUserAction = (payload) => {
    return (
        {
            type: FETCH_USER,
            payload,
        });
};

export const fetchUserSuccessAction = (user) => (
    {
        type: FETCH_USER_SUCCESS,
        user,
    }
);

export const fetchUserErrorAction = (error) => (
    {
        type: FETCH_USER_ERROR,
        error,
    }
);

const initialState = {
    fetching: false,
    user: {},
    error: null,
};

export function userReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_USER:
            return {
                ...state,
                fetching: true,
                error: null,
            };

        case FETCH_USER_SUCCESS:
            return {
                ...state,
                fetching: false,
                user: action.user,
                error: null,
            };

        case FETCH_USER_ERROR:
            return {
                ...state,
                fetching: false,
                error: action.error,
            };

        default:
            return state;
    }
}