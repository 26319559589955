export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHECK_PASSWORD = 'CHECK_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const changePasswordAction = payload => {
  return {
    type: CHANGE_PASSWORD,
    payload
  };
};

export const checkPasswordAction = payload => {
  return {
    type: CHECK_PASSWORD,
    payload
  };
};

export const changePasswordSuccessAction = () => {
  return {
    type: CHANGE_PASSWORD_SUCCESS
  };
};

export const changePasswordErrorAction = error => ({
  type: CHANGE_PASSWORD_ERROR,
  error
});

const initialState = {
  fetching: false,
  error: null,
  passwordSignInSucess: false
};

export function changePasswordReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return {
        ...state,
        fetching: true,
        error: null
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        passwordSignInSucess: action.payload
      };

    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.error
      };

    default:
      return state;
  }
}
