import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useFormState, { Form } from '../../../hooks/useFormState';
import Validations from '../../util/validations';
import { CreateAccountInputs } from './create-account-inputs';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../util';
import { Redirect } from 'react-router';
import { routes } from '../../../routes';
import { Page } from '../../page/page';
import { ConfirmationModal } from '../../modals/confirmation-modal';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';

const CreateAccountComponent = ({ classes, history }) => {
  const [redirect, setRedirect] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const fieldValidations = model => ({
    firstName: [Validations.required],
    lastName: [Validations.required],
    phoneNumber: [Validations.required],
    alternatePhoneNumber: [],
    address: [Validations.required],
    userName: [Validations.required, Validations.isEmail],
    referredBy: [Validations.isEmail],
    password: [
      Validations.required,
      Validations.passwordNumberRequired,
      Validations.passwordLowerCharRequired,
      Validations.passwordUpperCharRequired,
      Validations.passwordLengthRequired,
      Validations.passwordNonAlphanumericRequired
    ]
  });

  const handleSubmit = async ({ state, isValid }) => {
    if (isValid) {
      await axios({
        method: 'POST',
        url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Authentication/create`,
        data: state,
        withCredentials: true
      });
      setModalOpen(true);
    }
    return Promise.reject();
  };

  const {
    formState,
    onFormChange,
    onSubmit,
    resetState,
    isUpdating,
    // response,
    // hasError,
    hasSubmitted
    // setFormState
  } = useFormState(
    {
      password: '',
      checkPassword: '',
      userName: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      alternatePhoneNumber: '',
      address: '',
      referredBy: ''
    },
    handleSubmit,
    fieldValidations
  );

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <Page>
      <IconButton onClick={history.goBack}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant='h4' className={classes.textField} component='p'>
        Create Account
      </Typography>

      <Form
        onSubmit={onSubmit}
        isUpdating={isUpdating}
        handleReset={resetState}
        hasSubmitted={hasSubmitted}
        // recaptcha
      >
        <CreateAccountInputs
          model={formState}
          handleChange={onFormChange}
          hasSubmitted={hasSubmitted}
          validations={fieldValidations(formState)}
          isEditing={false}
        />

        {/* <div
            className={`${classes.recaptcha} g-recaptcha`}
            data-sitekey='6Lena8IUAAAAAMvo0r7Eh2ywpCldvPJnLIOBd3dC'
          ></div> */}
      </Form>
      <ConfirmationModal
        name='Account created successfully!'
        handleClose={() => setRedirect(routes.landingPageRoute)}
        open={modalOpen}
      />
    </Page>
  );
};

const styles = theme => ({
  root: {
    paddingTop: 20,
    paddingBottom: 20
  },
  spacer: {
    padding: '20px'
  },
  paper: {
    margin: 'auto',
    marginTop: '4em',
    marginBottom: '4em',
    maxWidth: '300px'
    // layout: 'center'
  },
  heading: {
    fontSize: '4rem'
  },
  content: {
    fontSize: '1rem',
    textAlign: 'left',
    padding: '10px',
    whiteSpace: 'pre-wrap'
  },
  center: {
    textAlign: 'center'
  },
  button: {
    display: 'inline-flex',
    padding: '0 50px',
    margin: '20px 0'
  },
  error: {
    color: 'red'
  },
  textField: {
    margin: theme.spacing.unit,
    display: 'flex'
  },
  recaptcha: {
    margin: '8px'
  }
});

export const CreateAccount = withStyles(styles)(CreateAccountComponent);
