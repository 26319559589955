import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Header } from '../header/header';
import { Footer } from '../footer/footer.jsx';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { TextField, Button } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../util';
import { convertCSVToArray } from 'convert-csv-to-array';
import { Redirect } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';

const EmailComponent = ({ classes, location, history }) => {
  const [emailForm, setEmailForm] = useState({
    recipients: location.state.recipients,
    subject: undefined,
    body: undefined,
    isHtml: false,
    files: undefined
  });
  const [rediretStudents, setRedirectStudents] = useState(false);

  const onSubmit = async () => {
    await axios({
      method: 'POST',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Email`,
      encType: 'multipart/form-data',
      data: new FormData(document.getElementById('email-form')),
      withCredentials: true
    });
    setRedirectStudents(true);
  };

  if (rediretStudents) {
    return <Redirect push to='/students' />;
  }

  return (
    <React.Fragment>
      <Header />
      <div className={classes.spacer} />
      <Paper className={classes.root + ' ' + classes.paper} elevation={1}>
        <IconButton onClick={history.goBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant='h5'
          component='h2'
          className={`${classes.heading} ${classes.center}`}
        >
          Email
        </Typography>

        <br />

        <form
          id='email-form'
          method='post'
          encType='multipart/form-data'
          action={`${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Email`}
          onSubmit={() => {
            onSubmit();
            setRedirectStudents(true);
          }}
        >
          <TextField
            id='recipients'
            label='Recipients'
            name='recipients'
            className={classes.textField}
            margin='normal'
            multiline
            value={emailForm.recipients}
            onChange={e =>
              setEmailForm({
                ...emailForm,
                recipients: convertCSVToArray(e.target.value)[0]
              })
            }
          >
            Recipients
          </TextField>

          <TextField
            id='subject'
            label='Subject'
            name='subject'
            className={classes.textField}
            margin='normal'
            value={emailForm.subject}
            onChange={e =>
              setEmailForm({
                ...emailForm,
                subject: e.target.value
              })
            }
          >
            Subject
          </TextField>

          <TextField
            id='body'
            label='Body'
            name='body'
            className={classes.textField}
            margin='normal'
            multiline
            value={emailForm.body}
            onChange={e =>
              setEmailForm({
                ...emailForm,
                body: e.target.value
              })
            }
          >
            Body
          </TextField>

          <TextField
            id='attachments'
            label='Attachments'
            name='files'
            type='file'
            className={classes.textField}
            margin='normal'
            inputProps={{ multiple: true }}
            onChange={async e => {
              var test = e.target.files;
              await setEmailForm({
                ...emailForm,
                files: test
              });
            }}
          >
            Attachments
          </TextField>

          <FormControlLabel
            control={
              <Switch
                name='isHtml'
                checked={emailForm.isHtml}
                onChange={e =>
                  setEmailForm({
                    ...emailForm,
                    isHtml: e.target.checked
                  })
                }
                value='isHtml'
              />
            }
            label='is HTML'
            className={classes.textField}
          />

          <Button variant='contained' color='primary' type='submit'>
            Send
          </Button>
        </form>
      </Paper>
      <Footer />
    </React.Fragment>
  );
};

const styles = theme => ({
  root: {
    paddingTop: 20,
    paddingBottom: 20
  },
  spacer: {
    padding: '20px'
  },
  paper: {
    margin: 'auto',
    marginTop: '4em',
    marginBottom: '4em',
    padding: '20px',
    overflow: 'overlay'
  },
  heading: {
    fontSize: '4rem'
  },
  content: {
    fontSize: '1rem',
    textAlign: 'left',
    padding: '10px',
    whiteSpace: 'pre-wrap'
  },
  center: {
    textAlign: 'center'
  },
  underline: {
    display: 'inline',
    textDecoration: 'underline'
  },
  noMargin: {
    margin: 0
  },
  MuiTypography: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 400,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    lineHeight: '1.46429em'
  },
  signupCheck: {
    display: 'flex'
  },
  error: {
    border: '1px solid red'
  },
  danceCheckWidth: {
    width: '48px',
    fontWeight: 'bold'
  },
  red: {
    color: 'red'
  },
  leftAlign: {
    textAlign: 'left'
  },
  semesterSelect: {
    width: '150px'
  },
  textField: {
    marginLeft: 0, // theme.spacing.unit,
    marginRight: theme.spacing.unit,
    // width: 250,
    display: 'flex'
  }
});

export const Email = withStyles(styles)(EmailComponent);
