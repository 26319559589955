import { createSelector } from 'reselect';
import { rootSelector } from '../../../root-selector';

export const semesterSelector = createSelector(
  rootSelector,
  state =>
    state.danceClassesSlice.semestersSlice.fetchSemestersSlice.semesters &&
    state.danceClassesSlice.semestersSlice.fetchSemestersSlice.semesters.semesters
);

export const currentSemesterSelector = createSelector(
  rootSelector,
  state =>
    state.danceClassesSlice.semestersSlice.fetchSemestersSlice.semesters &&
    state.danceClassesSlice.semestersSlice.fetchSemestersSlice.semesters.currentSemester
);

export const semesterNamesSelector = createSelector(
  rootSelector,
  state =>
    state.danceClassesSlice.semestersSlice.fetchSemestersSlice &&
    state.danceClassesSlice.semestersSlice.fetchSemestersSlice.semesterNames
);

export const semesterFormTypeSelector = createSelector(
  rootSelector,
  state =>
    state.danceClassesSlice.semestersSlice.semestersFormSlice &&
    state.danceClassesSlice.semestersSlice.semestersFormSlice.formType
);

export const semesterFormSelector = createSelector(
  rootSelector,
  state =>
    state.danceClassesSlice.semestersSlice.semestersFormSlice &&
    state.danceClassesSlice.semestersSlice.semestersFormSlice.form
);
