import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Map from '../../assets/img/map.png';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../util';
import { EditableTypography } from '../editable-typography/editable-typography';
import { rolesSelector } from '../../store/area/authentication/roles/fetch-role.selector';

const mapStateToProps = state => {
  return {
    roles: rolesSelector(state)
  };
};

const mapDispatchToProps = {};

const StudioInfoComponent = ({ roles, classes }) => {
  const [content, setContent] = useState('Loading...');

  useEffect(() => {
    fetchContent();
  });

  const fetchContent = async () => {
    let response = await axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Pages/studio-info-content`
    });
    setContent(response.data.content);
  };

  const postContent = async content => {
    await axios({
      method: 'POST',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Pages/studio-info-content`,
      data: { content: content },
      withCredentials: true
    });
    fetchContent();
  };

  return (
    <React.Fragment>
      <Typography variant="h5" component="h2" className={`${classes.heading} ${classes.center}`}>
        Studio Info
      </Typography>
      <br />
      <EditableTypography
        editable={roles.includes('Administrator')}
        onBlur={e => postContent(e.target.value)}
        value={content}
        className={`${classes.preStyle} ${classes.content}`}
      />

      <img src={Map} alt="Drop Off Map" className={classes.responsive} />
      <Typography component="p" className={classes.content}>
        CONTACT US:
        <br />
        VIBE Dance LLC
        <br />
        <a href='mailto:callie.vibedance@gmail.com'>callie.vibedance@gmail.com</a>
        <br />
        <a href='tel:801-834-8256'>801-834-8256</a>
        <br />
        <a href='www.thevibedance.com'>www.thevibedance.com</a>
      </Typography>
    </React.Fragment>
  );
};

const styles = {
  preStyle: {
    whiteSpace: 'pre-line'
  },
  responsive: {
    width: '100%',
    height: 'auto'
  },
  content: {
    fontSize: '1rem',
    textAlign: 'left',
    padding: '10px',
    width: '-webkit-fill-available',
    whiteSpace: 'pre-wrap'
  },
  heading: {
    fontSize: '3rem'
  },
  center: {
    textAlign: 'center'
  }
};

export const StudioInfo = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(StudioInfoComponent);
