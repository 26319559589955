import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const ContactUsComponent = (props) => {
  const { classes } = props;
  return (
    <div className={`${classes.center}`}>
      <Paper className={`${classes.root} ${classes.paper}`} elevation={1}>
        <Typography variant='h5' component='h2' className={classes.heading}>
          Contact Us
        </Typography>

        <br />

        <Typography component='p' className={classes.content}>
          If you have questions, concerns, or feedback about studio policy,
          classes, or teachers, please text, call, or email us. We care that we
          are serving you the best we can!
        </Typography>

        <br />

        <Typography
          component='pre'
          className={`${classes.content} ${classes.center}`}
        >
          VIBE Dance
        </Typography>
        <Typography
          component='pre'
          className={`${classes.content} ${classes.center}`}
        >
          South Jordan, UT 84009
        </Typography>
        <Typography
          component='pre'
          className={`${classes.content} ${classes.center}`}
        >
          Text/Call: 801-555-5555
        </Typography>
      </Paper>
    </div>
  );
};

const styles = {
  paper: {
    margin: 'auto',
    marginTop: '4em',
    marginBottom: '4em',
    maxWidth: '50em',
    layout: 'center',
  },
  heading: {
    fontSize: '3rem',
  },
  content: {
    fontSize: '1rem',
    textAlign: 'left',
    padding: '10px',
    whiteSpace: 'pre-wrap',
  },
  center: {
    textAlign: 'center',
  },
};

export const ContactUs = withStyles(styles)(ContactUsComponent);
