import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Header } from '../header/header';
import { Footer } from '../footer/footer.jsx';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { fetchClassesForSignupAction } from '../../store/area/dance-classes/dance-classes/fetch-classes-for-signup.reducer';
import { postRegistrationFormAction } from '../../store/area/forms/registration.reducer';
import { signupClassesSelector } from '../../store/area/dance-classes/dance-classes/dance-classes.selector';
import { fetchSemestersAction } from '../../store/area/dance-classes/semesters/fetch-semesters.reducer';
import { fetchDancersAction } from '../../store/area/dancers/fetch-dancers.reducer';
import { dancersSelector } from '../../store/area/dancers/fetch-dancers.selector';
import { semesterSelector } from '../../store/area/dance-classes/semesters/semester.selector.js';
import { TextField, MenuItem } from '@material-ui/core';
import { Redirect } from 'react-router';
import { SemesterStudentList } from './semester-students-list';
import { SemesterClassStudentList } from './semester-class-students-list';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../util';

const mapStateToProps = state => {
  return {
    dancers: dancersSelector(state),
    signupClasses: signupClassesSelector(state),
    semesters: semesterSelector(state)
  };
};

const mapDispatchToProps = {
  fetchDancersAction,
  fetchClassesForSignupAction,
  postRegistrationFormAction,
  fetchSemestersAction
};

const rows = [
  { id: 'name', numeric: true, disablePadding: true, label: 'Name' }
];

const StudentsPageComponent = ({
  classes,
  semesters,
  currentSemester,
  fetchDancersAction
}) => {
  const [dancers, setDancers] = useState(undefined);
  const [displayType, setDisplayType] = useState('students');
  const [selectedSemester, setSelectedSemester] = useState(
    currentSemester || ''
  );
  const [redirectEmail, setRedirectEmail] = useState(false);
  useEffect(() => {
    setSelectedSemester(currentSemester);
    fetchDancers(currentSemester);
  }, [currentSemester]);
  useEffect(() => {
    setDancers(undefined);
    fetchDancers(selectedSemester);
  }, [selectedSemester, displayType]);
  let recipients;

  const fetchDancers = async semesterId => {
    let url =
      displayType === 'students'
        ? `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Students/Semester/${semesterId}`
        : `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Students/Semester/${semesterId}/classes`;
    let response = await axios({
      method: 'GET',
      url: url,
      withCredentials: true
    });
    setDancers(response.data);
  };

  if (redirectEmail) {
    recipients = dancers.map(dancer => dancer.email);
    return <Redirect push to={{ pathname: '/email', state: { recipients } }} />;
  }

  return (
    <React.Fragment>
      <Header />
      <div className={classes.spacer} />
      <div>
        <div>
          <Paper className={classes.root + ' ' + classes.paper} elevation={1}>
            <Typography
              variant='h5'
              component='h2'
              className={`${classes.heading} ${classes.center}`}
            >
              Students
            </Typography>
            <TextField
              id='displayType'
              select
              label='Display Type'
              value={displayType}
              onChange={e => {
                setDancers(undefined);
                setDisplayType(e.target.value);
              }}
              className={classes.semesterSelect}
              margin='normal'
            >
              <MenuItem value='students'>Students</MenuItem>
              <MenuItem value='classes'>Classes</MenuItem>
            </TextField>
            <br />
            <TextField
              id='semester'
              select
              label='Session'
              value={selectedSemester}
              onChange={e => {
                setSelectedSemester(e.target.value);
              }}
              className={classes.semesterSelect}
              margin='normal'
            >
              {(semesters &&
                semesters.map(semester => (
                  <MenuItem key={semester.id} value={semester.id}>
                    {semester.semesterName.name + ' ' + semester.year}
                  </MenuItem>
                ))) || <p />}
            </TextField>

            <br />

            {console.log(dancers)}
            {displayType === 'students' && dancers && (
              <SemesterStudentList rows={rows} dancers={dancers} />
            )}
            {displayType === 'classes' && dancers && (
              <SemesterClassStudentList rows={rows} dancers={dancers} />
            )}
          </Paper>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

const styles = theme => ({
  root: {
    paddingTop: 20,
    paddingBottom: 20
  },
  spacer: {
    padding: '20px'
  },
  paper: {
    margin: 'auto',
    marginTop: '4em',
    marginBottom: '4em',
    padding: '20px',
    overflow: 'overlay'
  },
  heading: {
    fontSize: '4rem'
  },
  content: {
    fontSize: '1rem',
    textAlign: 'left',
    padding: '10px',
    whiteSpace: 'pre-wrap'
  },
  center: {
    textAlign: 'center'
  },
  textField: {
    marginLeft: 0, // theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 250,
    display: 'flex'
  },
  underline: {
    display: 'inline',
    textDecoration: 'underline'
  },
  noMargin: {
    margin: 0
  },
  MuiTypography: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 400,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    lineHeight: '1.46429em'
  },
  signupCheck: {
    display: 'flex'
  },
  error: {
    border: '1px solid red'
  },
  danceCheckWidth: {
    width: '48px',
    fontWeight: 'bold'
  },
  red: {
    color: 'red'
  },
  leftAlign: {
    textAlign: 'left'
  },
  semesterSelect: {
    width: '150px'
  }
});

export const StudentsPage = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(StudentsPageComponent)
);
