import React from 'react';
import { withStyles } from '@material-ui/core';
import { Header } from '../header/header';

const AdminLayoutComponent = ({ classes, children }) => (
  <React.Fragment>
    <Header />
    <div className={classes.toolbar} />
    <div className={classes.root}>{children}</div>
  </React.Fragment>
);

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  root: {
    minHeight: 100 + '%',
    padding: '2em 8%',
    fontSize: 2 + 'rem'
  }
});

export const AdminLayout = withStyles(styles)(AdminLayoutComponent);
