import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  SwipeableDrawer,
  List,
  MenuItem,
  Divider,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { connect } from 'react-redux';
import { routes } from '../../routes';
import titleImg from '../../assets/img/WebsiteTitle.png';
import { userNameSelector } from '../../store/area/authentication/users/fetch-user.selector';
import { fetchUserAction } from '../../store/area/authentication/users/fetch-user.reducer';
import { signOutAction } from '../../store/area/authentication/sign-out/sign-out.reducer';
import { fetchRoleAction } from '../../store/area/authentication/roles/fetch-role.reducer';
import { rolesSelector } from '../../store/area/authentication/roles/fetch-role.selector';
import { Redirect } from 'react-router';
import { Roles } from '../../enums/roles.enum';

const mapStateToProps = (state) => {
  return {
    userName: userNameSelector(state),
    roles: rolesSelector(state),
  };
};

const mapDispatchToProps = {
  fetchUserAction,
  signOutAction,
  fetchRoleAction,
};

const ButtonAppBar = (props) => {
  const {
    fetchUserAction,
    fetchRoleAction,
    signOutAction,
    classes,
    userName,
    roles,
  } = props;

  useEffect(() => {
    // fetchUserAction();
    // fetchRoleAction();
  }, []);

  const [drawerState, setDrawerState] = useState({
    left: false,
    anchorEl: null,
  });

  const [redirect, setRedirect] = useState(undefined);

  const handleClick = (event) => {
    setDrawerState({ ...drawerState, anchorEl: event.currentTarget });
  };

  const handleClose = () => {
    setDrawerState({ ...drawerState, anchorEl: null });
  };

  const toggleDrawer = (side, open) => () => {
    setDrawerState({
      ...drawerState,
      [side]: open,
    });
  };

  const navigations = [
    {
      id: 1,
      route: routes.landingPageRoute,
      label: 'Home',
    },
    {
      id: 2,
      route: routes.accountDashboardPageRoute,
      label: 'Dashboard',
      role: Roles.USER,
    },
    {
      id: 3,
      route: routes.danceClassesPageRoute,
      label: 'Classes',
    },
    {
      id: 4,
      route: routes.pricingPageRoute,
      label: 'Tuition',
    },
    {
      id: 8,
      route: routes.aboutUsPageRoute,
      label: 'About Us',
    },
    {
      id: 9,
      route: routes.studentsPageRoute,
      label: 'Students',
      role: Roles.ADMINISTRATOR,
    },
    {
      id: 10,
      route: routes.adminAccountsPageRoute,
      label: 'Accounts',
      role: Roles.ADMINISTRATOR,
    },
  ];

  const sideList = (
    <List className={classes.list}>
      {navigations.map((navigation) => (
        <Fragment key={navigation.id}>
          {((navigation.role && roles.includes(navigation.role)) ||
            !navigation.role) && (
            <Fragment>
              <Button
                onClick={() => setRedirect(navigation.route)}
                className={classes.menuItem}
              >
                {navigation.label}
              </Button>
              <Divider />
            </Fragment>
          )}
        </Fragment>
      ))}
      <Fragment>
        <Button
          onClick={() => window.location.assign('https://cameronpickle.com')}
          className={classes.menuItem}
        >
          Cameron's Website
        </Button>
        <Divider />
      </Fragment>
    </List>
  );

  if (redirect) {
    if (window.location.pathname !== redirect) {
      return <Redirect push to={redirect} />;
    }
  }

  return (
    <div className={classes.root}>
      <AppBar className={classes.barStyle} position='fixed'>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            onClick={toggleDrawer('left', true)}
            color='inherit'
            aria-label='Menu'
          >
            <MenuIcon />
          </IconButton>
          <div>
            <SwipeableDrawer
              open={drawerState.left}
              onClose={toggleDrawer('left', false)}
              onOpen={toggleDrawer('left', true)}
            >
              <div
                tabIndex={0}
                role='button'
                onClick={toggleDrawer('left', false)}
                onKeyDown={toggleDrawer('left', false)}
              >
                {sideList}
              </div>
            </SwipeableDrawer>
          </div>
          <Typography variant='h6' color='inherit' className={classes.flex}>
            <img
              onClick={() => setRedirect(routes.landingPageRoute)}
              className={classes.titleImg}
              src={titleImg}
              alt='Vibe'
            />
          </Typography>

          <Typography variant='h6' color='inherit' className={classes.flex}>
            DEMONSTRATION SITE ONLY
          </Typography>

          {userName && (
            <Button
              variant='contained'
              color='primary'
              aria-owns={drawerState.anchorEl ? 'simple-menu' : undefined}
              aria-haspopup='true'
              onClick={handleClick}
            >
              {userName}
            </Button>
          )}
          <Menu
            id='simple-menu'
            anchorEl={drawerState.anchorEl}
            open={Boolean(drawerState.anchorEl)}
            onClose={handleClose}
          >
            <Link
              style={{ textDecoration: 'none', color: 'inherit' }}
              to={routes.changePassword}
            >
              <MenuItem onClick={handleClose}>Change Password</MenuItem>
            </Link>
            <MenuItem onClick={() => signOutAction()}>Sign Out</MenuItem>
          </Menu>

          {!userName && (
            <Fragment>
              <Button
                onClick={() => setRedirect(routes.signInPageRoute)}
                className={classes.buttons}
                color='secondary'
              >
                Sign In
              </Button>
              <Button
                onClick={() => setRedirect(routes.createAccountPageRoute)}
                variant='contained'
                className={classes.buttonsContained}
                color='secondary'
              >
                Sign Up
              </Button>
            </Fragment>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.spacer} />
    </div>
  );
};

ButtonAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};
const headerStyle = {
  root: {
    flexGrow: 1,
  },
  buttons: {
    paddingLeft: '6px',
    paddingRight: '6px',
  },
  buttonsContained: {
    padding: '8px',
  },
  toolbar: {
    paddingRight: '4px',
  },
  flex: {
    flex: 1,
  },
  menuButton: {
    marginLeft: -12,
  },
  barStyle: {
    background: '#000',
  },
  titleImg: {
    maxHeight: '50px',
  },
  list: {
    width: 250,
  },
  menuItem: {
    width: 250,
    paddingTop: '10px',
    paddingBottom: '10px',
    fontSize: '20px',
  },
  textField: {
    color: 'white',
    margin: '5px',
  },
  input: {
    color: 'white',
    borderBottom: '2px solid white',
    margin: '5px',
  },
  spacer: {
    padding: '31px',
  },
};

export const Header = withStyles(headerStyle)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ButtonAppBar)
);
