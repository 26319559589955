import { createSelector } from 'reselect';
import { rootSelector } from '../../../root-selector';

export const isAuthenticatedSelector = createSelector(
  rootSelector,
  state => state.authenticationSlice.isAuthenticatedSlice.isAuthenticated
);

export const isAuthenticatedLoadingSelector = createSelector(
  rootSelector,
  state => state.authenticationSlice.isAuthenticatedSlice.fetching
);

export const authCalledSelector = createSelector(
  rootSelector,
  state => state.authenticationSlice.isAuthenticatedSlice.authCalled
);
