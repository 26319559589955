import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../../util';
import {
  fetchSemesterSuccessAction,
  fetchSemesterErrorAction,
  fetchSemesterNamesSuccessAction
} from './fetch-semesters.reducer';

export function* fetchSemestersWorkerSaga(action) {
  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Semester${(action.payload && action.payload.query) || ''}`
    });

    yield put(
      fetchSemesterSuccessAction({ currentSemester: response.data.current, semesters: response.data.semesters })
    );
  } catch (error) {
    yield put(fetchSemesterErrorAction(error.data));
  }
}

export function* fetchSemesterNamesWorkerSaga(action) {
  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Semester/names`
    });

    yield put(fetchSemesterNamesSuccessAction(response.data));
  } catch (error) {
    yield put(fetchSemesterErrorAction(error.data));
  }
}

export function* createSemestersWorkerSaga(action) {
  try {
    yield call(axios, {
      method: 'POST',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Semester/create`,
      data: action.semester
    });
  } catch (error) {
    yield put(fetchSemesterErrorAction(error.data));
  }
}

export function* editSemestersWorkerSaga(action) {
  try {
    yield call(axios, {
      method: 'POST',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Semester`,
      data: action.semester
    });
  } catch (error) {
    yield put(fetchSemesterErrorAction(error.data));
  }
}
