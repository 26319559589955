import { createSelector } from 'reselect';
import { rootSelector } from '../../../root-selector';

export const signInStatusErrorCodeSelector = createSelector(
  rootSelector,
  state => state.authenticationSlice.signInSlice.error && state.authenticationSlice.signInSlice.error.status
);

export const signInStatusCodeSelector = createSelector(
  rootSelector,
  state => state.authenticationSlice.signInSlice.statusCode
);
