import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { routes } from '../../../routes';
import { Redirect } from 'react-router-dom';

const DanceClassCardComponent = (props) => {
  const { name, times, description, image, imageAlt, coming, classes } = props;
  const [redirect, setRedirect] = useState(undefined);

  const handleOpen = () => {
    setRedirect(routes.accountDashboardPageRoute);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <Card className={classes.card}>
      <CardMedia className={classes.media} image={image} title={imageAlt} />
      <CardContent>
        <Typography gutterBottom align='center' variant='h5' component='h2'>
          {name}
        </Typography>
        {times &&
          times.map((time) => (
            <em className={classes.time} key={time.timeId}>
              {time.time}
              {time.classAges ? ' Ages ' + time.classAges : ''}
            </em>
          ))}
        <Typography component='p'>{description}</Typography>
      </CardContent>
      <CardActions>
        {
          <Button
            onClick={handleOpen}
            variant='contained'
            size='small'
            color='primary'
          >
            Sign Up
          </Button>
        }
        {coming && <strong>Coming {coming}!</strong>}
      </CardActions>
    </Card>
  );
};

const styles = {
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain',
    marginTop: '20px',
  },
  time: {
    whiteSpace: 'pre-wrap',
    fontSize: '0.6 rem',
    display: 'block',
  },
  paper: {
    position: 'absolute',
    width: 345,
    backgroundColor: '#FFFFFF',
    boxShadow: '#000000',
    padding: 20,
    top: 50 + '%',
    left: 50 + '%',
    transform: 'translateX(-50%) translateY(-50%)',
  },
};

export const DanceClassCard = withStyles(styles)(DanceClassCardComponent);
