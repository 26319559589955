import React, { useState } from 'react';
import { Page } from '../../page/page';
import { Heading } from '../../material-ui-wrappers/heading';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { ActionButton } from '../../material-ui-wrappers/action-button';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  Paper,
  Typography,
  CircularProgress
} from '@material-ui/core';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../util';
import { routes } from '../../../routes/index';
import { Redirect } from 'react-router-dom';
import { ConfirmationModal } from '../../modals/confirmation-modal';
import { PaymentTable } from '../../payment-table/payment-table';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';

const TuitionPaymentPageComponent = props => {
  const { classes, user, stripe, tuition, history, year, month } = props;

  const [error, setError] = useState(undefined);
  const [redirect, setRedirect] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const submitHandler = async () => {
    setError(undefined);
    let tokenResponse;
    tokenResponse = await stripe.createToken({
      type: 'card',
      name: `${user.firstName} ${user.lastName}`
    });
    if (tokenResponse.error) {
      setSubmitting(false);
      setError(
        `There was a problem processing your payment. ${tokenResponse.error.message}`
      );
      return;
    }
    try {
      var paymentResponse = await axios({
        method: 'POST',
        url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Payments/tuition/fees`,
        data: {
          token: tokenResponse.token,
          paymentYear: year,
          paymentMonth: month
        },
        withCredentials: true
      });
    } catch (ex) {
      setSubmitting(false);
      setError(
        `There was a problem processing your payment. ${ex.response.data.Message}`
      );
      return;
    }
    if (paymentResponse.status < 200 || paymentResponse.status > 299) {
      setSubmitting(false);
      setError(
        `There was a problem processing your payment. ${paymentResponse.Message}`
      );
      return;
    }
    setModalOpen(true);
  };

  if (redirect) {
    return <Redirect to={routes.accountDashboardPageRoute} />;
  }

  return (
    <Page>
      <IconButton onClick={history.goBack}>
        <ArrowBackIcon />
      </IconButton>
      <Heading>Tuition Payment</Heading>
      <Paper className={classes.card}>
        <PaymentTable PaymentDictionary={tuition} />
        <br />
        <Typography variant='h6'>Card Details</Typography>
        <CardElement className={classes.base} />
        {error && <Typography className={classes.error}>{error}</Typography>}
        <br />
        <ActionButton
          disabled={submitting}
          onClick={() => {
            setSubmitting(true);
            submitHandler();
          }}
        >
          {submitting ? <CircularProgress color='secondary' /> : 'Pay'}
        </ActionButton>
      </Paper>
      <ConfirmationModal
        name='Payment submitted successfully!'
        handleClose={() => setRedirect(routes.accountDashboardPageRoute)}
        open={modalOpen}
      />
    </Page>
  );
};

const styles = {
  card: {
    width: '80%',
    margin: '20px auto',
    padding: '20px'
  },
  error: {
    color: 'red'
  }
};

export const TuitionPaymentPage = withStyles(styles)(
  withRouter(injectStripe(TuitionPaymentPageComponent))
);
