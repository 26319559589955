import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

class ConfirmationModalComponent extends React.Component {
  state = {
    open: false
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.handleClose();
  };

  render() {
    const { classes, content } = this.props;
    return (
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={this.props.open}
        onClose={this.props.handleClose}
      >
        <div className={classes.modalStyle}>
          <Typography variant='title' id='modal-title'>
            {this.props.name}
          </Typography>
          <Typography variant='subheading' id='sign-up-time-modal-description'>
            {content}
          </Typography>
          <br />
          <Button
            onClick={this.handleClose}
            variant='contained'
            size='small'
            color='primary'
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  }
}

const styles = {
  root: {
    display: 'flex'
  },
  formControl: {
    margin: 20
  },
  group: {
    margin: `20px 0`
  },
  modalStyle: {
    position: 'absolute',
    width: '70%',
    maxWidth: '50em',
    backgroundColor: '#FFFFFF',
    boxShadow: '#000000',
    padding: 20,
    top: 50 + '%',
    left: 50 + '%',
    transform: 'translateX(-50%) translateY(-50%)'
  },
  paper: {
    margin: 'auto',
    marginTop: '4em',
    marginBottom: '4em',
    maxWidth: '50em',
    padding: '20px'
  }
};

export const ConfirmationModal = withStyles(styles)(ConfirmationModalComponent);
