import React, { useState } from 'react';
import { Page } from '../../page/page';
import { Heading } from '../../material-ui-wrappers/heading';
import { withStyles } from '@material-ui/core';
import { Content } from '../../material-ui-wrappers/content';
import { ActionButton } from '../../material-ui-wrappers/action-button';
import { routes } from '../../../routes';
import { Redirect } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';

const DancerPageComponent = props => {
  const {
    classes,
    dancer,
    registered,
    semesterPaid,
    enrollments,
    history
  } = props;
  const [redirect, setRedirect] = useState(undefined);

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <Page>
      <IconButton onClick={history.goBack}>
        <ArrowBackIcon />
      </IconButton>
      <Heading>{`${dancer.firstName} ${dancer.lastName}`}</Heading>
      {!registered && (
        <Content className={classes.error}>
          You must fill out the registration form before signing up for classes.
        </Content>
      )}
      {!semesterPaid && (
        <Content className={classes.error}>
          You must pay the registration fee before signing up for classes.
        </Content>
      )}
      {enrollments &&
        enrollments.map(enrollment => (
          <Content>{`${enrollment.classList.danceClass.className} ${enrollment.classList.time}`}</Content>
        ))}
      {registered && semesterPaid && (
        <ActionButton
          onClick={() =>
            setRedirect(routes.dancerAddClassRoute.replace(':id', dancer.id))
          }
          variant='contained'
          color='primary'
        >
          Add Class
        </ActionButton>
      )}
    </Page>
  );
};

const styles = theme => ({
  error: {
    color: 'red'
  }
});

export const DancerPage = withStyles(styles)(DancerPageComponent);
