import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Header } from '../header/header';
import { ImageSection } from '../image-section/image-section';
// import { ContentSection } from '../content-section/content-section.jsx';
import { Footer } from '../footer/footer.jsx';
// import dancePattern from '../../assets/img/dance_pattern.jpg';
import logo from '../../assets/img/logo.png';

const familyDiscountContent =
  'Classes for the whole family! Recieve a 10% discount on tuition for each family member enrolled.';

export const LandingPageComponent = ({ classes }) => {
  const iframe =
    '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3031.474174413631!2d-111.98597018425079!3d40.55320487934955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87528545355358d7%3A0x9fbcf2e7b9aacf9e!2sLove%20Baked%20by%20Callie!5e0!3m2!1sen!2sus!4v1650372561193!5m2!1sen!2sus" style="left: 0; top: 0; position: absolute; border:0;" width="100%" height="100%" allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.center}>
        <ImageSection
          image={logo}
          imageAlt='VIBE'
          title='VIBE'
          content={'Affordable & Recreational dance for all ages!'}
          img='image1'
        />

        {/* <ContentImageSection 
        title="VIBE Dance Grand Opening Summer Party" 
        content={GrandOpeningContent}
        img={summerFlyer}
        imgAlt="Grand Opening Party"
        button='RSVP Here'
        buttonLink='/summerparty'>
        </ContentImageSection> */}

        {/* <ContentSection inverted="true" content={familyDiscountContent} backgroundImg={dancePattern} /> */}

        <ImageSection title='' content={''} img='image2' />

        {/* <ContentSection
        inverted="true"
        title="Ready for Fall?" 
        content={readyForFallContent}
        img={fallImage}
        imgAlt="Fall Dance">
        </ContentSection> */}

        <div
          className={classes.mapResponsive}
          dangerouslySetInnerHTML={{ __html: iframe }}
        />

        <Footer />
      </div>
    </div>
  );
};

const styles = {
  root: {
    fontFamily: 'sans-serif',
  },
  center: {
    textAlign: 'center',
  },
  mapResponsive: {
    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
    height: 0,
  },
};

export const LandingPage = withStyles(styles)(LandingPageComponent);
