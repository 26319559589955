import React, { Fragment } from 'react';
import { getBaseUrl, getApiPort, getProtocol } from '../../../../util';
import axios from 'axios';
import { useAsync } from '../../../../hooks/useAsync';
import { LoadingPage } from '../../../loading/loading-page.component';
import { DancerAddClass } from './dancer-add-class';

export const DancerAddClassContainer = props => {
  const { history } = props;
  const { id } = props.match.params;
  const fetchDancer = () => {
    return axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Dancers/${id}`,
      withCredentials: true
    });
  };
  const fetchClasses = () => {
    return axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/ClassList/semesters/default/dancers/${id}/classSignUp`,
      withCredentials: true
    });
  };
  const {
    pending: isLoading,
    // rejected: hasError,
    resolved: [dancer, danceClasses] = []
  } = useAsync({
    fn: () =>
      Promise.all([fetchDancer(), fetchClasses()]).then(response =>
        response.map(r => r.data)
      )
  });
  return (
    <Fragment>
      {isLoading && <LoadingPage />}
      {dancer && (
        <DancerAddClass
          dancer={dancer}
          danceClasses={danceClasses}
          history={history}
        />
      )}
    </Fragment>
  );
};
