import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../util';
import { fetchDancersSuccessAction, fetchDancersErrorAction } from './fetch-dancers.reducer';

export function* fetchDancersWorkerSaga(action) {
  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Students/Semester/${action.payload.semesterId}`,
      withCredentials: true
    });

    yield put(fetchDancersSuccessAction(response.data));
  } catch (error) {
    yield put(fetchDancersErrorAction(error));
  }
}
