import { createSelector } from 'reselect';
import { rootSelector } from '../../../root-selector';

export const danceClassesSelector = createSelector(
  rootSelector,
  state => state.danceClassesSlice.classesSlice.classesApiSlice.danceClasses
);

export const danceClassesBySectionSelector = createSelector(
  rootSelector,
  state => state.danceClassesSlice.classesSlice.classesApiSlice.danceClassesBySection
);

export const danceClassFormSelector = createSelector(
  rootSelector,
  state => state.danceClassesSlice.classesSlice.classesFormSlice.form
);

export const danceClassFormTypeSelector = createSelector(
  rootSelector,
  state => state.danceClassesSlice.classesSlice.classesFormSlice.formType
);

export const signupClassesSelector = createSelector(
  rootSelector,
  state => state.signupSlice && state.signupSlice.classes
);
