import { combineReducers } from 'redux';
import { semestersReducer } from './semesters/semesters.reducer';
import { sectionsReducer } from './sections/sections.reducer';
import { classesReducer } from './dance-classes/class.reducer';
import { classTimesReducer } from './dance-class-times/dance-class-times.reducer';

const initialState = {};

export function danceClassesReducer(state = initialState, action) {
  let authenticationReducer = combineReducers({
    semestersSlice: semestersReducer,
    sectionsSlice: sectionsReducer,
    classesSlice: classesReducer,
    classTimesSlice: classTimesReducer
  });
  return authenticationReducer(state, action);
}
