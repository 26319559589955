export const POST_REGISTRATION_FORM = 'POST_REGISTRATION_FORM';
export const POST_REGISTRATION_FORM_SUCCESS = 'POST_REGISTRATION_FORM_SUCCESS';
export const POST_REGISTRATION_FORM_ERROR = 'POST_REGISTRATION_FORM_ERROR';

export const postRegistrationFormAction = (payload) => {
    return (
        {
            type: POST_REGISTRATION_FORM,
            payload,
        });
};

export const postRegistrationFormSuccessAction = (danceClasses) => (
    {
        type: POST_REGISTRATION_FORM_SUCCESS,
        danceClasses,
    }
);

export const postRegistrationFormErrorAction = (error) => (
    {
        type: POST_REGISTRATION_FORM_ERROR,
        error,
    }
);

const initialState = {
    fetching: false,
    response: {},
    error: null,
};

export function registrationReducer(state = initialState, action) {
    switch (action.type) {
        case POST_REGISTRATION_FORM:
            return {
                ...state,
                fetching: true,
                error: null,
            };

        case POST_REGISTRATION_FORM_SUCCESS:
            return {
                ...state,
                fetching: false,
                response: action.payload,
                error: null,
            };

        case POST_REGISTRATION_FORM_ERROR:
            return {
                ...state,
                fetching: false,
                error: action.error,
            };

        default:
            return state;
    }
}