import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';

const SemesterStudentListComponent = props => {
  const { rows, dancers, setRedirectEmail, classes } = props;
  return (
    <Fragment>
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          setRedirectEmail(true);
        }}
      >
        Email
      </Button>

      <br />

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align='right'>Guardian</TableCell>
            <TableCell align='right'>Gender</TableCell>
            <TableCell align='right'>DoB</TableCell>
            <TableCell align='right'>Phone</TableCell>
            <TableCell align='right'>Email</TableCell>
          </TableRow>
        </TableHead>
        {rows.map(row => {
          return (
            <TableBody key={row.id}>
              {dancers &&
                dancers.map(dancer => (
                  <TableRow key={dancer.id}>
                    <TableCell>{dancer.name}</TableCell>
                    <TableCell>{dancer.guardian}</TableCell>
                    <TableCell>
                      {dancer.gender === 'Female' ? 'F' : 'M'}
                    </TableCell>
                    <TableCell>
                      {new Intl.DateTimeFormat('en-US').format(
                        new Date(dancer.birthday)
                      )}
                    </TableCell>
                    <TableCell>{dancer.phone}</TableCell>
                    <TableCell>{dancer.email}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          );
        })}
      </Table>
    </Fragment>
  );
};

const styles = theme => ({});

export const SemesterStudentList = withStyles(styles)(
  SemesterStudentListComponent
);
