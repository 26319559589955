import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useFormState, { Form } from '../../../hooks/useFormState';
import Validations from '../../util/validations';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../util';
import { Redirect } from 'react-router';
import { routes } from '../../../routes';
import { Page } from '../../page/page';
import { SignInInputs } from './sign-in-inputs';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';

const CreateAccountComponent = ({ classes, history }) => {
  const [redirect, setRedirect] = useState(undefined);
  const fieldValidations = model => ({
    userName: [Validations.required, Validations.isEmail],
    password: [Validations.required]
  });

  const handleSubmit = async ({ state, isValid }) => {
    if (isValid) {
      await axios({
        method: 'POST',
        url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Authentication/signin`,
        data: state,
        withCredentials: true
      });
      setRedirect(routes.accountDashboardPageRoute);
    }
    return Promise.reject();
  };

  const {
    formState,
    onFormChange,
    onSubmit,
    resetState,
    isUpdating,
    // response,
    // hasError,
    hasSubmitted
    // setFormState
  } = useFormState(
    {
      password: '',
      userName: ''
    },
    handleSubmit,
    fieldValidations
  );

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <Page>
      <IconButton onClick={history.goBack}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant='h4' className={classes.textField} component='p'>
        Sign in to VIBE
      </Typography>

      <Form
        onSubmit={onSubmit}
        isUpdating={isUpdating}
        handleReset={resetState}
        hasSubmitted={hasSubmitted}
        noReset
        submitText='Sign In'
      >
        <SignInInputs
          model={formState}
          handleChange={onFormChange}
          hasSubmitted={hasSubmitted}
          validations={fieldValidations(formState)}
          isEditing={false}
        />
      </Form>
      <Button
        onClick={() => setRedirect(routes.passwordResetRoute)}
        className={classes.help}
      >
        Forgot Password?
      </Button>
    </Page>
  );
};

const styles = theme => ({
  root: {
    paddingTop: 20,
    paddingBottom: 20
  },
  heading: {
    fontSize: '4rem'
  },
  content: {
    fontSize: '1rem',
    textAlign: 'left',
    padding: '10px',
    whiteSpace: 'pre-wrap'
  },
  center: {
    textAlign: 'center'
  },
  button: {
    display: 'inline-flex',
    padding: '0 50px',
    margin: '20px 0'
  },
  textField: {
    margin: theme.spacing.unit,
    display: 'flex'
  },
  help: {
    color: 'blue'
  }
});

export const SignIn = withStyles(styles)(CreateAccountComponent);
