import React, { useState, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Content } from '../../../material-ui-wrappers/content';
import { Heading } from '../../../material-ui-wrappers/heading';
import { ActionButton } from '../../../material-ui-wrappers/action-button';
import { ContentCard } from '../../../material-ui-wrappers/content-card';
import { Redirect } from 'react-router-dom';
import { routes } from '../../../../routes';

const RegistrationFormCardComponent = props => {
  const { year, defaultYear } = props;
  const [redirect, setRedirect] = useState(undefined);

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <ContentCard>
      <Heading>Registration Form</Heading>
      {defaultYear && (
        <Fragment>
          <Content>
            Submit a registration form in order to be able to sign up for {year}{' '}
            classes.
          </Content>
          <ActionButton
            onClick={() => setRedirect(routes.registrationFormPageRoute)}
          >
            Register
          </ActionButton>
        </Fragment>
      )}
      {!defaultYear && (
        <Content>
          There currently is not a semester open for registration.
        </Content>
      )}
    </ContentCard>
  );
};

const styles = theme => ({});

export const RegistrationFormCard = withStyles(styles)(
  RegistrationFormCardComponent
);
