import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const BiographyCardComponent = props => {
  const { classes, image, imageAlt, name, title, description } = props;
  return (
    <Card className={classes.card}>
      <CardMedia className={classes.media} image={image} title={imageAlt} />
      <CardContent>
        <Typography gutterBottom align="center" variant="h5" component="h2">
          {name}
        </Typography>
        <Typography gutterBottom align="center" component="em">
          {title}
        </Typography>
        <Typography align="left" component="p">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

const styles = {
  card: {
    maxWidth: 345,
    margin: "20px"
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    backgroundSize: "contain",
    clipPath: "circle(33% at center)"
  }
};

export const BiographyCard =  withStyles(styles)(BiographyCardComponent);
