import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { AppBar, Toolbar } from '@material-ui/core';

const LoadingPageCompoponent = ({ classes }) => (
  <React.Fragment>
    <AppBar position="fixed">
      <Toolbar />
    </AppBar>
    <div className={classes.toolbar} />
    <div className={classes.center}>
      <CircularProgress className={classes.progress} />
    </div>
  </React.Fragment>
);

const styles = theme => ({
  center: {
    left: '50%',
    top: '50%',
    position: 'absolute',
    transform: 'translateX(-50%) translateY(-50%)'
  },
  toolbar: theme.mixins.toolbar
});

export const LoadingPage = withStyles(styles)(LoadingPageCompoponent);
