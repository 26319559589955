import { FormTypes } from '../../../../enums/form-types.enum';

export const DANCE_CLASS_TIME_FORM_TYPE = 'DANCE_CLASS_TIME_FORM_TYPE';
export const EDIT_DANCE_CLASS_TIME_FORM = 'EDIT_DANCE_CLASS_TIME_FORM';

export const danceClassTimeFormTypeAction = payload => {
  return {
    type: DANCE_CLASS_TIME_FORM_TYPE,
    payload
  };
};

export const editDanceClassTimeFormAction = payload => {
  return {
    type: EDIT_DANCE_CLASS_TIME_FORM,
    payload
  };
};

const initialState = {
  formType: FormTypes.NONE,
  form: {
    timeId: 0,
    time: '',
    classId: '',
    danceClassId: '',
    semesterId: 0,
    classMax: 6,
    classAges: '',
    selected: ''
  }
};

export function danceClassTimeFormReducer(state = initialState, action) {
  switch (action.type) {
    case DANCE_CLASS_TIME_FORM_TYPE:
      return {
        ...state,
        formType: action.payload
      };

    case EDIT_DANCE_CLASS_TIME_FORM:
      return {
        ...state,
        form: action.payload
      };

    default:
      return state;
  }
}
