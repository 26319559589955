import { connect } from 'react-redux';
import { rolesSelector } from '../../store/area/authentication/roles/fetch-role.selector';
import { MissionStatement } from './mission-statement';

const mapStateToProps = state => {
  return {
    roles: rolesSelector(state)
  };
};

const mapDispatchToProps = {};

export const MissionStatementContainer = connect(mapStateToProps,mapDispatchToProps)(MissionStatement);
