import React, { useState } from 'react';
import { Page } from '../../../page/page';
import { Heading } from '../../../material-ui-wrappers/heading';
import {
  TextField,
  MenuItem,
  withStyles,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { ActionButton } from '../../../material-ui-wrappers/action-button';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../../util';
import { routes } from '../../../../routes/index';
import { Redirect } from 'react-router-dom';
import { ConfirmationModal } from '../../../modals/confirmation-modal';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';

const DancerAddClassComponent = props => {
  const { classes, dancer, danceClasses, history } = props;
  const [selectedClass, setSelectedClass] = useState(danceClasses[0].timeId);
  const [error, setError] = useState(undefined);
  const [redirect, setRedirect] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setError(undefined);
    try {
      var response = await axios({
        method: 'POST',
        url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Enrollment`,
        data: { timeId: +selectedClass, dancerId: dancer.id },
        withCredentials: true
      });
    } catch (ex) {
      setSubmitting(false);
      setError(
        `There was a problem adding your class. ${ex.response.data.Message}`
      );
      return;
    }
    if (response.status < 200 || response.status > 299) {
      setSubmitting(false);
      setError(`There was a problem adding your class. ${response.Message}`);
      return;
    }
    setModalOpen(true);
  };

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <Page>
      <IconButton onClick={history.goBack}>
        <ArrowBackIcon />
      </IconButton>
      <Heading>Add Class</Heading>

      <TextField
        id='selectedClasses'
        select
        label='selectedClasses'
        value={selectedClass}
        onChange={e => setSelectedClass(e.target.value)}
        className={classes.textField}
        SelectProps={{ className: classes.textField }}
        margin='normal'
      >
        {(danceClasses &&
          danceClasses.map(danceClass => (
            <MenuItem key={danceClass.timeId} value={danceClass.timeId}>
              {`${danceClass.className} ${danceClass.time}`}
            </MenuItem>
          ))) || <p />}
      </TextField>
      <ActionButton
        disabled={submitting}
        onClick={() => {
          setSubmitting(true);
          handleSubmit();
        }}
      >
        {submitting ? <CircularProgress color='secondary' /> : 'Add'}
      </ActionButton>
      {error && <Typography className={classes.error}>{error}</Typography>}
      <ConfirmationModal
        name='Class added successfully!'
        handleClose={() => setRedirect(routes.accountDashboardPageRoute)}
        open={modalOpen}
      />
    </Page>
  );
};

const styles = theme => ({
  textField: {
    fontSize: '11px',
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
    margin: '5px'
  },
  textFieldOption: {
    fontSize: '11px',
    padding: '15px',
    textAlign: 'left',
    whiteSpace: 'pre-wrap'
  },
  error: {
    color: 'red',
    whiteSpace: 'pre-wrap'
  }
});

export const DancerAddClass = withStyles(styles)(DancerAddClassComponent);
