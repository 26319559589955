import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../util';
import { EditableTypography } from '../editable-typography/editable-typography';

const contentResponse = {
  id: 2,
  page: 'ABOUT_US',
  title: 'ABOUT_US_CONTENT',
  content:
    '“Sending good vibes into the world, one dancer at a time.”\n\nVIBE Dance Studio is a family focused, faith based, residential & recreational dance studio located in South Jordan, Utah. We provide fun, challenging, and affordable dance lessons that improve artistry, dance technique, and awareness. We are 100% committed to using music, costumes, and artistry that is uplifting and wholesome. VIBE is a place where we learn from each other and become better people and dancers together!\n\nWhy choose VIBE?\n\n- Affordable!\n- Serving all ages from toddler to adult\n- Classes are challenging, educational, non-competitive AND fun!\n- Traditional classes AND unique combination classes available\n- Class sizes are small so you can have the individualized feedback and encouragement needed to improve and progress in dance\n- Safe and proper ballet and straight line tumbling technique is taught and implemented by all our trained instructors\n- Increase your flexibility and strength, improve your posture, balance, and coordination\n- Implement musicality and rhythm\n- Gain confidence in performance, improvisation, and personal artistry to help you in becoming a well rounded dancer\n- Practice and perfect basic dance techniques and challenging choreography combinations\n- Learn valuable life lessons such as empathy, perseverance, and team work to complement your physical learning in movement and creative ability\n\n\nIf you have questions or concerns please text, call, or email us. We care that we are serving you the best we can!\n\nVIBE Dance\n4037 W. Yorkshire Dr.\nSouth Jordan, UT 84009\ncallie.vibedance@gmail.com\nText/Call: 801-834-8255\n',
};

const MissionStatementComponent = (props) => {
  const [contentOld, setContent] = useState('Loading...');

  const { content } = contentResponse;

  useEffect(() => {
    // fetchContent();
  });

  const fetchContent = async () => {
    let response = await axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Pages/about-us-content`,
    });
    setContent(response.data.content);
  };

  const postContent = async (content) => {
    await axios({
      method: 'POST',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Pages/about-us-content`,
      data: { content: content },
      withCredentials: true,
    });
    fetchContent();
  };

  const { classes, roles } = props;
  return (
    <Paper
      className={`${classes.root} ${classes.paper} ${classes.center}`}
      elevation={1}
    >
      <Typography variant='h5' component='h2' className={classes.heading}>
        Mission Statement
      </Typography>

      <br />
      <EditableTypography
        editable={roles.includes('Administrator')}
        onBlur={(e) => postContent(e.target.value)}
        value={content}
        className={`${classes.preStyle} ${classes.content}`}
      />
    </Paper>
  );
};

const styles = {
  preStyle: {
    whiteSpace: 'pre-line',
  },
  content: {
    fontSize: '1rem',
    textAlign: 'left',
    padding: '10px',
    width: '-webkit-fill-available',
    whiteSpace: 'pre-wrap',
    margin: '20px',
  },
  root: {
    paddingTop: '20px',
    paddingBottom: 20,
  },
  paper: {
    margin: 'auto',
    marginTop: '4em',
    marginBottom: '4em',
    maxWidth: '50em',
    layout: 'center',
  },
  heading: {
    fontSize: '3rem',
  },
  center: {
    textAlign: 'center',
  },
};

export const MissionStatement = withStyles(styles)(MissionStatementComponent);
