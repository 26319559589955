export const FETCH_SECTIONS = 'FETCH_SECTIONS';
export const EDIT_SECTIONS = 'EDIT_SECTIONS';
export const CREATE_SECTIONS = 'CREATE_SECTIONS ';
export const FETCH_SECTIONS_SUCCESS = 'FETCH_SECTIONS_SUCCESS';
export const FETCH_SECTIONS_ERROR = 'FETCH_SECTIONS_ERROR';

export const fetchSectionsAction = payload => {
  return {
    type: FETCH_SECTIONS,
    payload
  };
};

export const editSectionAction = payload => {
  return {
    type: EDIT_SECTIONS,
    payload
  };
};

export const createSectionAction = payload => {
  return {
    type: CREATE_SECTIONS,
    payload
  };
};

export const fetchSectionsSuccessAction = sections => ({
  type: FETCH_SECTIONS_SUCCESS,
  sections
});

export const fetchSectionsErrorAction = error => ({
  type: FETCH_SECTIONS_ERROR,
  error
});

const initialState = {
  fetching: false,
  sections: null,
  error: null
};

export function sectionsApiReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SECTIONS:
      return {
        ...state,
        fetching: true,
        error: null
      };

    case FETCH_SECTIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        sections: action.sections,
        error: null
      };

    case FETCH_SECTIONS_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.error
      };

    default:
      return state;
  }
}
