export const FETCH_CLASSES = 'FETCH_CLASSES';
export const CREATE_DANCE_CLASS = 'CREATE_DANCE_CLASS';
export const EDIT_DANCE_CLASS = 'EDIT_DANCE_CLASS';
export const FETCH_CLASSES_BY_SECTION = 'FETCH_CLASSES_BY_SECTION';
export const FETCH_CLASSES_SUCCESS = 'FETCH_CLASSES_SUCCESS';
export const FETCH_CLASSES_BY_SECTION_SUCCESS = 'FETCH_CLASSES_BY_SECTION_SUCCESS';
export const FETCH_CLASSES_BY_SECTION_ERROR = 'FETCH_CLASSES_BY_SECTION_ERROR';

export const fetchDanceClassesAction = payload => {
  return {
    type: FETCH_CLASSES,
    payload
  };
};

export const fetchClassesBySectionAction = payload => {
  return {
    type: FETCH_CLASSES_BY_SECTION,
    payload
  };
};

export const fetchClassesSuccess = danceClasses => ({
  type: FETCH_CLASSES_SUCCESS,
  danceClasses
});

export const fetchClassesBySectionSuccess = danceClasses => ({
  type: FETCH_CLASSES_BY_SECTION_SUCCESS,
  danceClasses
});

export const fetchClassesBySectionError = error => ({
  type: FETCH_CLASSES_BY_SECTION_ERROR,
  error
});

export const createDanceClassAction = danceClass => {
  return {
    type: CREATE_DANCE_CLASS,
    danceClass
  };
};

export const editDanceClassAction = danceClass => {
  return {
    type: EDIT_DANCE_CLASS,
    danceClass
  };
};

const initialState = {
  fetching: false,
  danceClasses: [],
  danceClassesBySection: [],
  error: null
};

export function classesBySectionReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CLASSES_BY_SECTION:
      return {
        ...state,
        fetching: true,
        error: null
      };

    case FETCH_CLASSES_SUCCESS:
      return {
        ...state,
        fetching: false,
        danceClasses: action.danceClasses,
        error: null
      };

    case FETCH_CLASSES_BY_SECTION_SUCCESS:
      return {
        ...state,
        fetching: false,
        danceClassesBySection: {
          ...state.danceClassesBySection,
          [action.danceClasses.name]: action.danceClasses.classes
        },
        error: null
      };

    case FETCH_CLASSES_BY_SECTION_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.error
      };

    default:
      return state;
  }
}
