import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

export const CheckboxWithLabel = props => {
  const { label, value, ...other } = props;
  return (
    <FormControlLabel
      style={{ margin: '0', padding: '0 10px 0 0' }}
      control={
        <Checkbox color='inherit' checked={value} value={label} {...other} />
      }
      label={label}
    />
  );
};
