import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  TextFieldWithValidation,
  CheckBoxWithValidation
} from '../../../material-ui-wrappers/with-validations';
import { Content } from '../../../material-ui-wrappers/content';

const RegistrationFormInputsComponent = props => {
  const {
    classes,
    model,
    handleChange,
    hasSubmitted,
    validations,
    semesterAgreements
  } = props;

  return (
    <Fragment>
      <Content>
        You will be required to fill out a new registration form once per
        session for your dancer. A registration fee of $30 per dancer will be
        due when you fill out your form via cash or check OR $55 for families
        with more than one student enrolled. This payment holds the dancer's
        spot in the class. PLEASE READ ALL INFORMATION CAREFULLY. If you have
        questions, contact us!
      </Content>
      <Content>
        SESSION DATES
        <br />
        <br />
        Fall Session 2019: September 3 - December 20
        <br />
        Summer Session 2019: June 3 - August 23
        <br />
        Spring Session 2020: January 6 - April 24
        <br />
        <br />
        Full session commitment is required for Fall and Spring.
        <br />
        <br />
        MONTHLY TUITION & PAYMENT OPTIONS
        <br />
        <br />
        Tuition is due on the 5th of every month. For every day late after that
        date, a $5 late fee will be assessed. We accept Cash, Check, or Card.
        Make Checks out to VIBE Dance LLC.
      </Content>

      {semesterAgreements.agreements &&
        semesterAgreements.agreements.map(agreement => (
          <Fragment>
            <CheckBoxWithValidation
              name={'' + agreement.id}
              label={agreement.description}
              onChange={handleChange}
              value={model[agreement.id]}
              rules={validations[agreement.id]}
              formSubmitted={hasSubmitted}
            ></CheckBoxWithValidation>
          </Fragment>
        ))}

      <TextFieldWithValidation
        id='registrationDigitalSignature'
        label='Signature'
        name='registrationDigitalSignature'
        value={model.registrationDigitalSignature}
        onChange={handleChange}
        className={`${classes.textField}`}
        InputProps={{
          className: classes.input
        }}
        InputLabelProps={{
          className: classes.textField
        }}
        margin='normal'
        rules={validations['registrationDigitalSignature']}
        formSubmitted={hasSubmitted}
      />
    </Fragment>
  );
};

const styles = theme => ({
  textField: {
    margin: theme.spacing.unit,
    display: 'flex'
  }
});

export const RegistrationFormInputs = withStyles(styles)(
  RegistrationFormInputsComponent
);
