import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const HelperText = props => {
  const { children, hasError, className, ...rest } = props;
  const classes = classNames('HelperText', className, {
    error: hasError
  });

  return (
    <span {...rest} className={classes}>
      {children}
    </span>
  );
};

HelperText.propTypes = {
  children: PropTypes.node.isRequired,
  hasError: PropTypes.bool,
  className: PropTypes.string
};

HelperText.defaultProps = {
  hasError: false,
  className: ''
};
