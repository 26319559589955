import { FETCH_SECTIONS, CREATE_SECTIONS, EDIT_SECTIONS } from './area/dance-classes/sections/api-sections.reducer';
import {
  FETCH_CLASSES_BY_SECTION,
  FETCH_CLASSES,
  CREATE_DANCE_CLASS,
  EDIT_DANCE_CLASS
} from './area/dance-classes/dance-classes/fetch-classes-by-section.reducer';
import { FETCH_CLASSES_FOR_SIGNUP } from './area/dance-classes/dance-classes/fetch-classes-for-signup.reducer';
import {
  FETCH_TIMES_BY_CLASS,
  CREATE_DANCE_CLASS_TIME,
  EDIT_DANCE_CLASS_TIME,
  FETCH_ALL_TIMES_BY_CLASS
} from './area/dance-classes/dance-class-times/fetch-times-by-class.reducer';
import { POST_REGISTRATION_FORM } from './area/forms/registration.reducer';
import {
  FETCH_SEMESTERS,
  CREATE_SEMESTER,
  EDIT_SEMESTERS,
  FETCH_SEMESTER_NAMES
} from './area/dance-classes/semesters/fetch-semesters.reducer';
import { FETCH_USER } from './area/authentication/users/fetch-user.reducer';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  fetchSectionsWorkerSaga,
  createSectionWorkerSaga,
  editSectionWorkerSaga
} from './area/dance-classes/sections/fetch-sections.saga';
import {
  fetchClassesBySectionWorkerSaga,
  fetchDanceClassesWorkerSaga,
  createDanceClassesWorkerSaga,
  editDanceClassesWorkerSaga
} from './area/dance-classes/dance-classes/fetch-classes-by-section.saga';
import { fetchClassesForSignupWorkerSaga } from './area/dance-classes/dance-classes/fetch-classes-for-signup.saga';
import {
  fetchTimesByClassWorkerSaga,
  createDanceClassTimeWorkerSaga,
  editDanceClassTimeWorkerSaga,
  fetchAllTimesByClassWorkerSaga
} from './area/dance-classes/dance-class-times/fetch-times-by-class.saga';
import { postRegistrationFormWorkerSaga } from './area/forms/registration.saga';
import {
  fetchSemestersWorkerSaga,
  editSemestersWorkerSaga,
  createSemestersWorkerSaga,
  fetchSemesterNamesWorkerSaga
} from './area/dance-classes/semesters/fetch-semester.saga';
import { fetchUserWorkerSaga } from './area/authentication/users/fetch-user.saga';
import { SIGNIN } from './area/authentication/sign-in/sign-in.reducer';
import { signInWorkerSaga } from './area/authentication/sign-in/sign-in.saga';
import { SIGNOUT } from './area/authentication/sign-out/sign-out.reducer';
import { signOutWorkerSaga } from './area/authentication/sign-out/sign-out.saga';
import { FETCH_ROLE } from './area/authentication/roles/fetch-role.reducer';
import { fetchRoleWorkerSaga } from './area/authentication/roles/fetch-role.saga';
import { FETCH_DANCERS } from './area/dancers/fetch-dancers.reducer';
import { fetchDancersWorkerSaga } from './area/dancers/fetch-dancers.saga';
import { isAuthenticatedWorkerSaga } from './area/authentication/is-authenticated/is-authenticated.saga';
import { IS_AUTHENTICATED } from './area/authentication/is-authenticated/is-authenticated.reducer';
import { CHANGE_PASSWORD, CHECK_PASSWORD } from './area/authentication/change-password/change-password.reducer';
import {
  changePasswordWorkerSaga,
  checkPasswordWorkerSaga
} from './area/authentication/change-password/change-password.saga';

export function* watcherSaga() {
  yield takeLatest(FETCH_SECTIONS, fetchSectionsWorkerSaga);
  yield takeEvery(FETCH_CLASSES_BY_SECTION, fetchClassesBySectionWorkerSaga);
  yield takeEvery(FETCH_TIMES_BY_CLASS, fetchTimesByClassWorkerSaga);
  yield takeEvery(FETCH_ALL_TIMES_BY_CLASS, fetchAllTimesByClassWorkerSaga);
  yield takeEvery(FETCH_CLASSES_FOR_SIGNUP, fetchClassesForSignupWorkerSaga);
  yield takeEvery(POST_REGISTRATION_FORM, postRegistrationFormWorkerSaga);
  yield takeEvery(FETCH_SEMESTERS, fetchSemestersWorkerSaga);
  yield takeEvery(FETCH_USER, fetchUserWorkerSaga);
  yield takeEvery(SIGNIN, signInWorkerSaga);
  yield takeEvery(SIGNOUT, signOutWorkerSaga);
  yield takeEvery(FETCH_ROLE, fetchRoleWorkerSaga);
  yield takeEvery(FETCH_DANCERS, fetchDancersWorkerSaga);
  yield takeEvery(IS_AUTHENTICATED, isAuthenticatedWorkerSaga);
  yield takeEvery(CREATE_SEMESTER, createSemestersWorkerSaga);
  yield takeEvery(EDIT_SEMESTERS, editSemestersWorkerSaga);
  yield takeEvery(FETCH_SEMESTER_NAMES, fetchSemesterNamesWorkerSaga);
  yield takeEvery(FETCH_CLASSES, fetchDanceClassesWorkerSaga);
  yield takeEvery(CREATE_SECTIONS, createSectionWorkerSaga);
  yield takeEvery(EDIT_SECTIONS, editSectionWorkerSaga);
  yield takeEvery(CREATE_DANCE_CLASS, createDanceClassesWorkerSaga);
  yield takeEvery(EDIT_DANCE_CLASS, editDanceClassesWorkerSaga);
  yield takeEvery(CREATE_DANCE_CLASS_TIME, createDanceClassTimeWorkerSaga);
  yield takeEvery(EDIT_DANCE_CLASS_TIME, editDanceClassTimeWorkerSaga);
  yield takeEvery(CHANGE_PASSWORD, changePasswordWorkerSaga);
  yield takeEvery(CHECK_PASSWORD, checkPasswordWorkerSaga);
}
