import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../util';
import { postRegistrationFormSuccessAction, postRegistrationFormErrorAction } from './registration.reducer';

export function* postRegistrationFormWorkerSaga(action) {
  try {
    const response = yield call(axios, {
      method: 'POST',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/registration/submit`,
      data: action.payload
    });

    yield put(postRegistrationFormSuccessAction(response.data));
  } catch (error) {
    yield put(postRegistrationFormErrorAction(error.data));
  }
}
