import React, { useState } from 'react';
import { Paper, withStyles } from '@material-ui/core';
import { Heading } from '../../../material-ui-wrappers/heading';
import { Content } from '../../../material-ui-wrappers/content';
import { ActionButton } from '../../../material-ui-wrappers/action-button';
import { routes } from '../../../../routes';
import { Redirect } from 'react-router-dom';

const RegistrationFeeCardComponent = props => {
  const { classes, year } = props;
  const [redirect, setRedirect] = useState(undefined);

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <Paper className={`${classes.paper} ${classes.root}`}>
      <Heading>Registration Fee</Heading>
      <Content>You have not paid your {year} registration fee!</Content>
      <ActionButton
        onClick={() => setRedirect(routes.registrationFeePageRoute)}
      >
        Make Payment
      </ActionButton>
    </Paper>
  );
};

const styles = theme => ({
  root: {
    paddingTop: 20,
    paddingBottom: 20
  },
  paper: {
    margin: 'auto',
    marginTop: '4em',
    marginBottom: '4em',
    maxWidth: '50em',
    layout: 'center'
  }
});

export const RegistrationFeeCard = withStyles(styles)(
  RegistrationFeeCardComponent
);
