import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../../util';
import { fetchRoleSuccessAction, fetchRoleErrorAction } from './fetch-role.reducer';

export function* fetchRoleWorkerSaga(action) {
  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/authentication/roles`,
      withCredentials: true
    });

    yield put(fetchRoleSuccessAction(response.data));
  } catch (error) {
    yield put(fetchRoleErrorAction(error));
  }
}
