import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../../util';
import {
  fetchTimesByClassSuccessAction,
  fetchTimesByClassErrorAction,
  fetchAllTimesByClassSuccessAction
} from './fetch-times-by-class.reducer';

export function* fetchTimesByClassWorkerSaga(action) {
  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/ClassList/semesterId/${
        action.payload.semesterId
      }/classId/${action.payload.classId}`
    });

    yield put(fetchTimesByClassSuccessAction(response.data));
  } catch (error) {
    yield put(fetchTimesByClassErrorAction(error.data));
  }
}

export function* fetchAllTimesByClassWorkerSaga(action) {
  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/ClassList/classId/${action.payload}`
    });

    yield put(fetchAllTimesByClassSuccessAction(response.data));
  } catch (error) {
    yield put(fetchTimesByClassErrorAction(error.data));
  }
}

export function* createDanceClassTimeWorkerSaga(action) {
  try {
    yield call(axios, {
      method: 'POST',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/ClassList`,
      data: action.payload,
      withCredentials: true
    });
  } catch (error) {
    yield put(fetchTimesByClassErrorAction(error.data));
  }
}

export function* editDanceClassTimeWorkerSaga(action) {
  try {
    yield call(axios, {
      method: 'PUT',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/ClassList`,
      data: action.payload,
      withCredentials: true
    });
  } catch (error) {
    yield put(fetchTimesByClassErrorAction(error.data));
  }
}
