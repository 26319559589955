import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import parseInputFloat from "../area/util/parse-input-float";
// DOCS: https://github.com/mariocasciaro/object-path-immutable
import immutable from "object-path-immutable";
import { CircularProgress, withStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useAsync } from "./useAsync";
import { useValidation } from "./useValidation";

export default (initialState, handleSubmit, fieldValidations = () => ({})) => {
  const [formState, setState] = useState(initialState);
  const [formCachedState, setCachedState] = useState(formState);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const {
    handler,
    pending: isLoading,
    rejected: hasError,
    resolved: response,
    reset
  } = useAsync({
    fn: handleSubmit,
    resolve: false
  });

  const setFormState = useCallback(
    (...args) => {
      setState(oldState => immutable.set(oldState, ...args));
      if (!isDirty) setIsDirty(true);
    },
    [formState]
  );

  const onChange = useCallback(
    props => {
      const {
        target: { name, value, checked, type, dataset }
      } = props;
      let v = type === "checkbox" ? checked : value;
      v =
        type === "number" || (dataset && dataset.type) === "number"
          ? parseInputFloat(v)
          : v;
      setFormState(name, v);
    },
    [formState]
  );

  const onFormChange = useCallback(event => onChange(event), [formState]);
  useEffect(() => {
    setCachedState(formState);
    setIsDirty(false);
  }, [response && !hasError]);

  const resetState = () => {
    setState(formCachedState);
    setHasSubmitted(false);
    setIsDirty(false);
    reset();
  };

  return {
    isUpdating: isLoading,
    resetState,
    formState,
    onChange,
    onFormChange,
    setFormState,
    response,
    hasError,
    hasSubmitted,
    resetSave: reset,
    onSubmit: e => {
      setHasSubmitted(true);
      const fieldConfigs = fieldValidations(formState);
      const validations = useValidation.validateForm(fieldConfigs, formState);
      const isValid = validations.every(v => v.isValid);
      return handler({
        event: e,
        state: formState,
        isValid,
        validations
      });
    }
  };
};

const FormComponent = ({
  className,
  onSubmit,
  children,
  isUpdating,
  handleReset,
  disabled,
  defaultControls,
  recaptcha = false,
  hasSubmitted,
  classes,
  noReset = false,
  submitText = "Save",
  ...rest
}) => (
  <form
    {...rest}
    data-testid="use-form"
    noValidate
    className={`Form ${className} needs-validation ${
      hasSubmitted ? "was-validated" : "no-validated"
    }`}
    onSubmit={event => {
      event.preventDefault();
      onSubmit(event);
    }}
  >
    {isUpdating ? (
      <div className="text-center p-5" data-testid="use-form-loading-spinner">
        <CircularProgress />
      </div>
    ) : (
      <div data-testid="use-form-children">{children}</div>
    )}
    {defaultControls && (
      <div className={`${classes.buttonMargin} ${classes.flex}`}>
        <div className={classes.spacer} />
        {!noReset && (
          <Button
            title="Reset"
            data-testid="use-form-reset"
            type="button"
            onClick={handleReset}
            isDisabled={isUpdating}
          >
            Reset
          </Button>
        )}
        <Button
          title="Submit"
          data-testid="use-form-submit"
          type="submit"
          isLoading={isUpdating}
          isDisabled={disabled || isUpdating}
          variant="contained"
          color="primary"
        >
          {!isUpdating ? submitText : "Saving..."}
        </Button>
      </div>
    )}
  </form>
);

const styles = theme => ({
  buttonMargin: {
    margin: "1em .5em"
  },
  flex: {
    display: "flex"
  },
  spacer: {
    flex: 1
  }
});

export const Form = withStyles(styles)(FormComponent);

Form.defaultProps = {
  className: "",
  isUpdating: false,
  disabled: false,
  defaultControls: true,
  hasSubmitted: false,
  handleReset: () => {}
};

Form.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isUpdating: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultControls: PropTypes.bool,
  handleReset: PropTypes.func,
  hasSubmitted: PropTypes.bool
};
