import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../../util';
import {
  fetchUserSuccessAction,
  fetchUserErrorAction
} from './fetch-user.reducer';

export function* fetchUserWorkerSaga(action) {
  try {
    const response = yield call(axios, {
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/authentication/users/current`,
      withCredentials: true
    });

    yield put(fetchUserSuccessAction(response.data));
  } catch (error) {
    yield put(fetchUserErrorAction(error));
  }
}
