import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useFormState, { Form } from '../../../hooks/useFormState';
import Validations from '../../util/validations';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../../util';
import { Redirect } from 'react-router';
import { routes } from '../../../routes';
import { Page } from '../../page/page';
import { PasswordResetInputs } from './password-reset-inputs';

const PasswordResetComponent = ({ classes }) => {
  const [redirect, setRedirect] = useState(undefined);
  const fieldValidations = model => ({
    userName: [Validations.required, Validations.isEmail]
  });

  const handleSubmit = async ({ state, isValid }) => {
    if (isValid) {
      await axios({
        method: 'POST',
        url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Authentication/password-reset/token`,
        data: state,
        withCredentials: true
      });
      setRedirect(routes.accountDashboardPageRoute);
    }
    return Promise.reject();
  };

  const {
    formState,
    onFormChange,
    onSubmit,
    resetState,
    isUpdating,
    // response,
    // hasError,
    hasSubmitted
    // setFormState
  } = useFormState(
    {
      userName: ''
    },
    handleSubmit,
    fieldValidations
  );

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <Page>
      <Typography variant='h4' className={classes.textField} component='p'>
        Forgot Password
      </Typography>

      <Form
        onSubmit={onSubmit}
        isUpdating={isUpdating}
        handleReset={resetState}
        hasSubmitted={hasSubmitted}
        noReset
        submitText='Submit'
      >
        <PasswordResetInputs
          model={formState}
          handleChange={onFormChange}
          hasSubmitted={hasSubmitted}
          validations={fieldValidations(formState)}
          isEditing={false}
        />
      </Form>
    </Page>
  );
};

const styles = theme => ({
  root: {
    paddingTop: 20,
    paddingBottom: 20
  },
  heading: {
    fontSize: '4rem'
  },
  content: {
    fontSize: '1rem',
    textAlign: 'left',
    padding: '10px',
    whiteSpace: 'pre-wrap'
  },
  center: {
    textAlign: 'center'
  },
  button: {
    display: 'inline-flex',
    padding: '0 50px',
    margin: '20px 0'
  },
  textField: {
    margin: theme.spacing.unit,
    display: 'flex'
  }
});

export const PasswordReset = withStyles(styles)(PasswordResetComponent);
