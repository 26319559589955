import React, { useState } from 'react';
import { Paper, Typography, withStyles } from '@material-ui/core';
import moment from 'moment';
import { routes } from '../../../routes';
import { Redirect } from 'react-router-dom';
import { Content } from '../../material-ui-wrappers/content';
import { ActionButton } from '../../material-ui-wrappers/action-button';

const DancerCardComponent = props => {
  const {
    classes,
    dancer,
    year,
    enrollments,
    registered,
    semesterPaid
  } = props;
  const [redirect, setRedirect] = useState(undefined);

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <Paper className={classes.card}>
      <Typography>{`${dancer.firstName} ${dancer.lastName}`}</Typography>
      <Typography>{moment(dancer.birthdate).format('YYYY-MM-DD')}</Typography>
      {enrollments &&
        enrollments.map(enrollment => (
          <Content>{`${enrollment.classList.danceClass.className} ${enrollment.classList.time}`}</Content>
        ))}
      {registered && semesterPaid && year && (
        <ActionButton
          onClick={() =>
            setRedirect(routes.dancerAddClassRoute.replace(':id', dancer.id))
          }
          variant='contained'
          color='primary'
        >
          Add Class
        </ActionButton>
      )}
    </Paper>
  );
};

const styles = theme => ({
  card: {
    padding: '10px'
  }
});

export const DancerCard = withStyles(styles)(DancerCardComponent);
