import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import grid from 'jss-grid';
import { DanceClassCard } from './dance-class-card/dance-class-card.jsx';
import { TextField, MenuItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import { routes } from '../../routes/index';
import { Page } from '../page/page';

const DanceClassesPageComponent = (props) => {
  const {
    classes,
    // danceClasses,
    // times,
    // sections,
    // semesters,
    roles,
    // currentSemester,
    fetchSectionsAction,
  } = props;

  // console.log(currentSemester);
  const currentSemester = 5;

  const danceClasses = {
    ClassesSection1: [
      {
        id: 1,
        section: null,
        sectionId: 1,
        className: 'Tiny Ballet',
        classDescription:
          "Turn, Hop & Learn! Students will be introduced to basic class structure, rhythm and movement. We'll explore the basic elements of dance using fun props, music, and games to enhance learning.",
        imagePath: 'assets/img/Pre-Ballet.png',
        isActive: true,
      },
      {
        id: 2,
        section: null,
        sectionId: 1,
        className: 'Intro to Jazz & Tumbling',
        classDescription:
          'A specialty fusion class combining dance and tumbling! We will learn the basic jumps, technique and tricks of tumbling and then use those skills while dancing to fun jazz music. **Hands-on instruction (spotting) is provided to help students safely learn.',
        imagePath: 'assets/img/Intro to Jazz and Tumbling.png',
        isActive: true,
      },
      {
        id: 17,
        section: null,
        sectionId: 1,
        className: 'Intro to Tumbling',
        classDescription:
          'Students will be introduced to basic tumbling and acrobatic skills such as forward/backward rolls, handstands, back bends, splits, cartwheels, and round offs. Proper strength and flexibility will be learned, and safe spotting techniques will be used to ensure proper body safety and alignment for students. We use fun props, music, and games to enhance learning experience. Students will also work on classroom skills such as following directions, listening and working with others.',
        imagePath: 'assets/img/Intro to Jazz and Tumbling.png',
        isActive: true,
      },
      {
        id: 18,
        section: null,
        sectionId: 1,
        className: 'Intro to Dance',
        classDescription:
          'Students will be introduced to basic rhythm and structure of dance, specifically the ballet and jazz genres. We will practice flexibility, balance, and proper body alignment to lay a strong foundation for your dancer! Musicality, improvisation and choreographic abilities will also be emphasized in class. We use fun props, music, and games to enhance learning experience. Students will also work on classroom skills such as following directions, listening and working with others.',
        imagePath: 'assets/img/Intro to Jazz and Tumbling.png',
        isActive: true,
      },
      {
        id: 34,
        section: null,
        sectionId: 1,
        className: 'Tots Dance & Tumble',
        classDescription:
          'Does your little one dream of becoming a dancer one day? This is a fantastic class to start learning those basic dance and tumble skills. Your little dancer will develop rhythm and coordination and gain strength, poise and grace- just the grounding they need to be a well-rounded dancer!  Best of all, there’s always a creative slant that keeps it fun and fresh for your child!',
        imagePath: 'assets/img/Pre-Ballet.png',
        isActive: true,
      },
      {
        id: 35,
        section: null,
        sectionId: 1,
        className: 'Mommy & Me',
        classDescription:
          'This class provides an opportunity for a toddler and an adult to bond while dancing, moving, and learning together! It also provides caregivers with ideas and techniques to stimulate the learning and growth of their child through imaginative play and creative movement. Guardian must be 18 years or older.',
        imagePath: 'assets/img/Pre-Ballet.png',
        isActive: true,
      },
    ],
    ClassesSection2: [
      {
        id: 5,
        section: null,
        sectionId: 2,
        className: 'Val-You Dance',
        classDescription:
          'A free scholarship dance class of Lyrical and Contemporary dance based on values taught in The Church of Jesus Christ of Latter Day Saints. Primary class emphasis will be given to strength, flexibility, and the lyrical and modern genres of dancing. We will focus on developing healthy physical and spiritual habits and learn how to share our unique talents and gifts with others through dance. 6 spots available for scholarship, first come first served! Call or email us to reserve your spot.',
        imagePath: 'assets/img/Val-You Dance.png',
        isActive: true,
      },
      {
        id: 6,
        section: null,
        sectionId: 2,
        className: 'Jazz Tumbling Fusion',
        classDescription:
          'A specialty fusion class combining dance and tumbling! Will focus on strength, flexibility and basics of tumbling and also incorporate jazz dance technique, musicality, and choreography. **Hands-on instruction (spotting) is provided to help students safely learn new tricks.',
        imagePath: 'assets/img/Jazz and Tumbling Fusion.png',
        isActive: true,
      },
      {
        id: 7,
        section: null,
        sectionId: 2,
        className: 'Beginning Tap Dance & Musical Dance Theatre',
        classDescription:
          'The first half of class will focus on musical theatre style tap and basic classical tap technique. The second half of class will be spent doing acting exercises, singing, practicing for auditions, learning about different eras of musical dance theatre, and much more. Begin your journey as a triple threat!',
        imagePath: 'assets/img/Beginning Tap.png',
        isActive: true,
      },
      {
        id: 13,
        section: null,
        sectionId: 2,
        className: 'Hip Hop',
        classDescription:
          'Our Hip-Hop classes teach rhythm, coordination, musicality and choreography without suggestive music or movements. Students are challenged to think on their feet while enjoying an energetic and fast-paced dance class. Our Hip-Hop dancers develop focus, strength, and agility while having fun.',
        imagePath: 'assets/img/kidshiphop.png',
        isActive: true,
      },
      {
        id: 15,
        section: null,
        sectionId: 2,
        className: 'Beginning Tap',
        classDescription:
          'Tap Dance is a great workout and a wonderful way to develop a sense of rhythm in music and all other areas of dance. In this class we will focus on perfecting basic tap steps and learning new dance combinations weekly.',
        imagePath: 'assets/img/Beginning Tap.png',
        isActive: true,
      },
      {
        id: 19,
        section: null,
        sectionId: 2,
        className: 'Dance & Tumble',
        classDescription:
          'Do you have an energetic, bouncy young dancer? Dance Tumble is a great combo class for action-loving kids to learn exciting tricks and impressive basic dance skills. Our trained instructors will create a safe and non-competitive environment for your dancer to learn in. In this class we will teach proper straight-line tumbling technique and dance technique from styles such as ballet, jazz, and hip hop.*Hands-on instruction is provided to help students safely learn.',
        imagePath: 'assets/img/Jazz and Tumbling Fusion.png',
        isActive: true,
      },
      {
        id: 20,
        section: null,
        sectionId: 2,
        className: 'Ballet & Jazz',
        classDescription:
          'Students will learn the proper ballet French terminology, correct body alignment, technique improvement including turnouts, and build strong central body cores. They will develop their building blocks to assist in every other style of dance they pursue. Curriculum is based on ballet technique layered with traditional jazz movement and includes a proper warm-up, stretches, isolations, across-the-floor progressions and choreographic combinations. Even if you don’t want to be a ballet dancer, the skills you learn in this class will make you stronger and steadier and develop proper alignment and strength.',
        imagePath: 'assets/img/Jazz and Tumbling Fusion.png',
        isActive: true,
      },
      {
        id: 21,
        section: null,
        sectionId: 2,
        className: 'Tap & Theatrics',
        classDescription:
          'This upbeat class teaches Broadway-style jazz and tap choreography and show-stopping stage presence with a focus on learning song-and-dance numbers from a variety of musical productions. Begin your journey as a triple threat!',
        imagePath: 'assets/img/Beginning Tap.png',
        isActive: true,
      },
      {
        id: 22,
        section: null,
        sectionId: 2,
        className: 'Hip Hop',
        classDescription:
          'Our Hip-Hop classes teach rhythm, coordination, musicality and choreography without suggestive music or movements. Students are challenged to think on their feet while enjoying an energetic and fast-paced dance class. Our Hip-Hop dancers develop focus, strength, and agility while having fun. Hip Hop encompasses many different urban dance styles such as popping, locking, breaking and freestyle movement to give students the opportunity to develop their own sense of style. Your dancer will learn all the cool moves along with stalls and other tumbling tricks!',
        imagePath: 'assets/img/kidshiphop.png',
        isActive: true,
      },
      {
        id: 27,
        section: null,
        sectionId: 2,
        className: '6 week Beginning Tap Intensive',
        classDescription:
          'May 13 & 20, June 17 & 24, July 8 & 22\n\n\nLearn the basics of tap dancing all the way up to a time step! We\'ll teach you some great technique and the "how to\'s" of beginning tap in just 6 weeks. Come tap with us!\n\n$60 for the whole 6 week session.',
        imagePath: 'assets/img/Beginning Tap.png',
        isActive: true,
      },
      {
        id: 28,
        section: null,
        sectionId: 2,
        className: '6 week Intermediate Tap Intensive',
        classDescription: 'May 13 & 20, June 17 & 24, July 8 & 22',
        imagePath: 'assets/img/Beginning Tap.png',
        isActive: true,
      },
      {
        id: 29,
        section: null,
        sectionId: 2,
        className: 'Jazz & Tap',
        classDescription:
          'In this exciting new combination class we will learn more about the genres of tap, jazz, and ballet in one class! Ballet positions, jazz and ballet terminology, tap rhythms, basic floor and foot work is taught on a weekly basis; slowly working up to choreography in dances which is both educational and fun. We emphasize precision and performance quality of movements at this age.',
        imagePath: 'assets/img/Jazz and Tumbling Fusion.png',
        isActive: true,
      },
      {
        id: 31,
        section: null,
        sectionId: 2,
        className: 'Lyrical',
        classDescription:
          'Lyrical incorporates ballet, jazz, and contemporary dance styles while developing an emotional connection to the music. The dancing tells the story of the song with powerful movement and beautiful lines. We focus on creative expression as a performer, and improving balance, coordination and flexibility.',
        imagePath: 'assets/img/Val-You Dance.png',
        isActive: true,
      },
      {
        id: 32,
        section: null,
        sectionId: 2,
        className: 'Ballet, Jazz & Acrobatics Combo',
        classDescription:
          'The ultimate dance class to create the most well rounded dancer! Your dancer will learn fundamental acrobatic and ballet technique, with the fun choreography and creativity found in jazz dance. Students will learn acrobatics such as handstands, elbow stands, cartwheels, back bends, walkovers and balance. We will focus on quality of performance, precision of ballet technique, and enhance agility, flexibility, and strength.',
        imagePath: 'assets/img/Dance Yoga Fusion.png',
        isActive: true,
      },
      {
        id: 33,
        section: null,
        sectionId: 2,
        className: 'Ballet & Jazz',
        classDescription:
          'Students will learn the French terminology, correct body alignment, technique improvement including turnouts, and build strong central body cores. They will develop their building blocks to assist in every other style of dance they pursue. Curriculum is based on ballet technique layered with traditional jazz movement and includes a proper warm-up, stretches, isolations, across-the-floor progressions and choreographic combinations.',
        imagePath: 'assets/img/Pre-Ballet.png',
        isActive: true,
      },
      {
        id: 39,
        section: null,
        sectionId: 2,
        className: 'Ballet',
        classDescription:
          'An introduction class to the structure and fundamentals of classical ballet. We will explore basic barre, center, and across the floor combinations with a fun and creative twist that all beginners will love! ',
        imagePath: 'assets/img/Pre-Ballet.png',
        isActive: true,
      },
    ],
    ClassesSection3: [
      {
        id: 3,
        section: null,
        sectionId: 3,
        className: 'Ballet',
        classDescription:
          'You’re 8 years old or older and want to be a dancer? Ballet is the foundation for all dance styles. Even if you don’t want to be a ballet dancer, the skills you learn in this class will make you stronger, steadier and more elegant- and give you a wonderful basis for any other dance styles you want to learn! Ballet technique develops proper alignment and strength. Every class begins with barre work emphasizing posture, rotation, and beautiful footwork. In the center, dancers work on adagio, pirouettes and petite and grand allegro combinations to cultivate grace and fluidity. Students will learn the French terminology, correct body alignment, technique improvement including turnouts, and build strong central body cores. They will develop their building blocks to assist in every other style of dance they pursue.',
        imagePath: 'assets/img/Beginning Ballet.png',
        isActive: true,
      },
      {
        id: 8,
        section: null,
        sectionId: 3,
        className: 'Intermediate Tap',
        classDescription:
          'Tap Dance is a wonderful way to develop a sense of rhythm in music and all other areas of dance. If your child has even a little bit of tap experience this is the class for you! In this class we will focus on perfecting basic tap steps and learning new dance combinations weekly.',
        imagePath: 'assets/img/Beginning Tap.png',
        isActive: true,
      },
      {
        id: 16,
        section: null,
        sectionId: 3,
        className: 'Hip Hop',
        classDescription:
          'Hip Hop encompasses many different urban dance styles such as popping, locking, breaking and freestyle movement to give students the opportunity to develop their own sense of style. Your dancer will learn all the cool moves along with stalls and other tumbling tricks! Our high energy classes teach rhythm, coordination, and musicality without suggestive music or movements.',
        imagePath: 'assets/img/kidshiphop.png',
        isActive: true,
      },
      {
        id: 23,
        section: null,
        sectionId: 3,
        className: 'VIBE Teen Company',
        classDescription:
          'Ballet Tuesday 7:30-8:30 PM Jazz Funk Tuesday 6:30-7:30 PM Contemporary/Acrobatics Company is for dancers ages 14+ who have had 3+ years of experience in ballet or jazz.This company is for dedicated and passionate dancers who want to improve their technique, artistry, and performance. Dancers will occasionally attend conventions, master classes, performances, and participate in competitions. Call or email us to set up a private audition.',
        imagePath: 'assets/img/Dance Yoga Fusion.png',
        isActive: true,
      },
      {
        id: 24,
        section: null,
        sectionId: 3,
        className: 'VIBE Mini Company',
        classDescription:
          'Ballet, Jazz & Tap M/W night starting at 5 Mini Company is for dancers ages 9-13 who have had at least one year of dance experience. This company is for dedicated and passionate dancers who want to improve their technique and performance. Mini Company will meet 2 times each week.',
        imagePath: 'assets/img/Private Lessons.png',
        isActive: true,
      },
      {
        id: 25,
        section: null,
        sectionId: 3,
        className: 'Teen Tap',
        classDescription:
          'Our teen tap classes will help your dancer develop rhythm, style and sound. Students will learn a variety of tap styles from Broadway to Rhythm tap. Exercises focus on building flexibility of the knee and ankles, coordination, and speed of movement. Class emphasis is on developing proper tap technique and producing clear tap sounds.',
        imagePath: 'assets/img/Beginning Tap.png',
        isActive: true,
      },
      {
        id: 30,
        section: null,
        sectionId: 3,
        className: 'Jazz',
        classDescription:
          'Jazz dance really mixes it up! It combines many other kinds of dance, with the strength and poise of ballet, the explosive moves of Hip Hop and the fun dance style of other modern dances. We set our choreography to fun modern pop music, and teach you everything from impressive jumps to sharp, clean movement.\n\nIt takes strength to do that well, and the first half of the class consists of exercises to stretch and tone muscles and learn proper body alignment. Then in the second half it’ll be time to learn jazz choreography!',
        imagePath: 'assets/img/Jazz and Tumbling Fusion.png',
        isActive: true,
      },
      {
        id: 36,
        section: null,
        sectionId: 3,
        className: 'Beginning Tumbling & Acrobatics',
        classDescription:
          'Introduces basic tumbling and acro moves with a strong emphasis on stretching and strengthening. We will work on basic progressions that work towards tricks such as: Splits, Backbends, Kip Ups, Chin/ Forearm Stands, Side Ariel, and Back Handsprings. This class is designed to be catered to individual skills of each student, as much of class is dedicated to working with and receiving individual feedback and spotting from the instructor.',
        imagePath: 'assets/img/Jazz and Tumbling Fusion.png',
        isActive: true,
      },
    ],
    ClassesSection4: [
      {
        id: 11,
        section: null,
        sectionId: 4,
        className: 'Yoga',
        classDescription:
          'Experience an all-levels contemporary yoga experience in synchronizing your movement with unique breathing techniques. Become more flexible,coordinated, and balanced emotionally and physically. Learn helpful meditation tools that can help cope with stressful life situations. We will practice movements, alignments, breathing, and meditations from Hatha, Yin, Iyengar, Ashtanga, Vinyasa, and Restorative styles of yoga.',
        imagePath: 'assets/img/Vinyasa Flow Yoga.png',
        isActive: true,
      },
      {
        id: 26,
        section: null,
        sectionId: 4,
        className: 'Adult Intermediate Contemporary',
        classDescription:
          'Build your artistry with us as we learn and experiment with different styles of lyrical, modern, jazz, ballet, and contemporary. We will focus on flexibility, balance, coordination, and learning and teaching choreography.',
        imagePath: 'assets/img/Private Lessons.png',
        isActive: true,
      },
      {
        id: 37,
        section: null,
        sectionId: 4,
        className: 'Adult Intermediate Tap',
        classDescription:
          'Tap Dance is a great workout and a wonderful way to develop a sense of rhythm in music and all other areas of dance. In this class we will focus on perfecting basic tap steps and learning new dance combinations weekly.',
        imagePath: 'assets/img/Beginning Tap.png',
        isActive: true,
      },
      {
        id: 38,
        section: null,
        sectionId: 4,
        className: 'Adult Beginning Contemporary',
        classDescription:
          'Wanted to try dance but never got the chance as a kid? In our beginner level adult dance classes we will discover facets and techniques in ballet, jazz, hip hop and contemporary dance. Every students gets the opportunity to create, choreograph, teach, and learn!',
        imagePath: 'assets/img/Private Lessons.png',
        isActive: true,
      },
      {
        id: 40,
        section: null,
        sectionId: 4,
        className: 'Adult Acrobatics',
        classDescription:
          'This class is designed for adults interested in learning tumbling and acrobatic skills from basic to an intermediate level. We will also create a fun personalized workout routine to help build more strength, flexibility, and body awareness.',
        imagePath: 'assets/img/Private Lessons.png',
        isActive: true,
      },
      {
        id: 41,
        section: null,
        sectionId: 4,
        className: 'Adult Ballet',
        classDescription: '',
        imagePath: 'assets/img/Pre-Ballet.png',
        isActive: true,
      },
      {
        id: 42,
        section: null,
        sectionId: 4,
        className: 'Adult Beginning Ballet',
        classDescription:
          'This class is the perfect intro class for beginners who are new to the art of ballet! Each class begins with barre work emphasizing posture, rotation, and beautiful footwork. In the center, dancers work on adagio, pirouettes and petite and grand allegro combinations to cultivate grace and fluidity. Students will learn Cecchetti Classical Ballet method. We will focus on learning terminology, correct body alignment, technique improvement including turnouts, and build strong central body cores.',
        imagePath: 'assets/img/Pre-Ballet.png',
        isActive: true,
      },
    ],
    ClassesSection5: [
      {
        id: 14,
        section: null,
        sectionId: 5,
        className: 'Private Lessons',
        classDescription:
          'Private lessons in any style of dance: ballet, jazz, tap, hip hop, contemporary, tumbling, acrobatics, and yoga. We also offer flexibility and stretching group lessons, studio rental, and group dance lessons. We can choreograph for any of your special events such as weddings, family reunions, dance competitions, etc and even help you with audition preparation!',
        imagePath: 'assets/img/Private Lessons.png',
        isActive: true,
      },
    ],
  };

  const times = {
    Class19: [
      {
        timeId: 86,
        time: 'Wednesday 4:00-5:00 PM',
        classAges: '',
        semester: null,
        classId: 19,
        danceClass: null,
        classMax: 6,
        isActive: true,
        count: -1,
      },
    ],
    Class36: [
      {
        timeId: 84,
        time: 'Wednesday 4:00-5:00 PM',
        classAges: '9-12',
        semester: null,
        classId: 36,
        danceClass: null,
        classMax: 6,
        isActive: true,
        count: -1,
      },
      {
        timeId: 85,
        time: 'Thursday 5:00-6:00 PM',
        classAges: '',
        semester: null,
        classId: 36,
        danceClass: null,
        classMax: 6,
        isActive: true,
        count: -1,
      },
    ],
    Class42: [
      {
        timeId: 88,
        time: 'Wednesday 4:00-5:00 PM',
        classAges: '',
        semester: null,
        classId: 36,
        danceClass: null,
        classMax: 6,
        isActive: true,
        count: -1,
      },
      {
        timeId: 89,
        time: 'Thursday 5:00-6:00 PM',
        classAges: '',
        semester: null,
        classId: 36,
        danceClass: null,
        classMax: 6,
        isActive: true,
        count: -1,
      },
    ],
    Class37: [
      {
        timeId: 89,
        time: 'Wednesday 4:00-5:00 PM',
        classAges: '',
        semester: null,
        classId: 36,
        danceClass: null,
        classMax: 6,
        isActive: true,
        count: -1,
      },
    ],
    Class1: [
      {
        timeId: 90,
        time: 'Wednesday 4:00-5:00 PM',
        classAges: '',
        semester: null,
        classId: 36,
        danceClass: null,
        classMax: 6,
        isActive: true,
        count: -1,
      },
    ],
    Class2: [
      {
        timeId: 91,
        time: 'Wednesday 4:00-5:00 PM',
        classAges: '',
        semester: null,
        classId: 36,
        danceClass: null,
        classMax: 6,
        isActive: true,
        count: -1,
      },
    ],
    Class5: [
      {
        timeId: 92,
        time: 'Wednesday 4:00-5:00 PM',
        classAges: '',
        semester: null,
        classId: 36,
        danceClass: null,
        classMax: 6,
        isActive: true,
        count: -1,
      },
    ],
    Class14: [
      {
        timeId: 94,
        time: 'Wednesday 4:00-5:00 PM',
        classAges: '',
        semester: null,
        classId: 36,
        danceClass: null,
        classMax: 6,
        isActive: true,
        count: -1,
      },
    ],
    Class11: [
      {
        timeId: 95,
        time: 'Wednesday 4:00-5:00 PM',
        classAges: '',
        semester: null,
        classId: 36,
        danceClass: null,
        classMax: 6,
        isActive: true,
        count: -1,
      },
    ],
    Class16: [
      {
        timeId: 96,
        time: 'Wednesday 4:00-5:00 PM',
        classAges: '',
        semester: null,
        classId: 36,
        danceClass: null,
        classMax: 6,
        isActive: true,
        count: -1,
      },
    ],
    Class32: [
      {
        timeId: 97,
        time: 'Wednesday 4:00-5:00 PM',
        classAges: '',
        semester: null,
        classId: 36,
        danceClass: null,
        classMax: 6,
        isActive: true,
        count: -1,
      },
    ],
  };

  const sections = [
    {
      id: 1,
      sectionName: 'Toddler Classes',
      sectionAges: '',
      classesDuration: '45 minutes per week',
      sortOrder: 1,
      isActive: true,
    },
    {
      id: 2,
      sectionName: 'Child Classes',
      sectionAges: 'Ages 5-8',
      classesDuration: '1 hour per week',
      sortOrder: 2,
      isActive: true,
    },
    {
      id: 3,
      sectionName: 'Pre-Teen Classes',
      sectionAges: 'Ages 9-12',
      classesDuration: '1 hour per week',
      sortOrder: 3,
      isActive: true,
    },
    // {
    //   id: 6,
    //   sectionName: 'Teen Classes',
    //   sectionAges: 'Ages 13-17',
    //   classesDuration: '1 hour per week',
    //   sortOrder: 4,
    //   isActive: true,
    // },
    {
      id: 4,
      sectionName: 'Adult Classes',
      sectionAges: 'Ages 18+',
      classesDuration: '1 hour per week',
      sortOrder: 5,
      isActive: true,
    },
    {
      id: 5,
      sectionName: 'Private Lessons',
      sectionAges: null,
      classesDuration: 'TBD',
      sortOrder: 6,
      isActive: true,
    },
  ];
  const semestersResponse = {
    current: 5,
    semesters: [
      {
        id: 5,
        year: 2020,
        semesterName: { id: 1, name: 'Spring' },
        startDate: '2020-01-01T00:00:00',
        endDate: '2028-05-31T00:00:00',
        defaultSemesterStartDate: '2019-12-15T00:00:00',
        defaultSemesterEndDate: '2028-03-01T00:00:00',
        isActive: true,
      },
    ],
  };
  const { semesters } = semestersResponse;
  const [state, setState] = useState(currentSemester || '');

  useEffect(() => {
    setState({ semester: currentSemester });
  }, [currentSemester]);

  const handleChange = (name) => (event) => {
    fetchSectionsAction({ semesterId: event.target.value });
    setState({
      [name]: event.target.value,
    });
  };

  return (
    <Page>
      <Typography variant='h5' component='h2' className={classes.heading}>
        Classes
      </Typography>
      <br />
      <Typography variant='h4' className={classes.content} component='p'>
        Session
      </Typography>
      {state && (
        <Fragment>
          <TextField
            id='semester'
            select
            label=''
            value={state.semester}
            onChange={handleChange('semester')}
            className={`${classes.content} ${classes.semesterSelect}`}
            margin='normal'
          >
            {(semesters &&
              semesters.map((semester) => (
                <MenuItem key={semester.id} value={semester.id}>
                  {semester.semesterName.name + ' ' + semester.year}
                </MenuItem>
              ))) || <p />}
          </TextField>
          {sections &&
            sections.map((s) => (
              <div key={s.id}>
                <h3 className={classes.content}>
                  {s.sectionName}
                  <em className={classes.postHeading}> {s.sectionAges}</em>
                </h3>
                <div className={classes.classGrid}>
                  {danceClasses &&
                    danceClasses[`ClassesSection${s.id}`] &&
                    danceClasses[`ClassesSection${s.id}`].map(
                      (dc) =>
                        times &&
                        times[`Class${dc.id}`] &&
                        times[`Class${dc.id}`].length !== 0 && (
                          <DanceClassCard
                            key={dc.id}
                            image={dc.imagePath}
                            imageAlt={dc.className}
                            name={dc.className}
                            times={times && times[`Class${dc.id}`]}
                            description={dc.classDescription}
                          />
                        )
                    )}
                </div>
              </div>
            ))}
        </Fragment>
      )}
      {!state && <p>loading...</p>}
      {roles.includes('Administrator') && (
        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to={routes.addContentPageRoute}
        >
          <Fab color='secondary' aria-label='Edit' className={classes.fab}>
            <Icon>edit_icon</Icon>
          </Fab>
        </Link>
      )}
    </Page>
  );
};

const styles = (theme) => ({
  fab: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
  },
  classGrid: {
    ...grid(
      `
            {.5em, .5em} | repeat(auto-fill, minmax(260px, 1fr)) |
            --
            `
    ),
  },
  postHeading: {
    fontWeight: 'normal',
  },
  content: {
    textAlign: 'left',
    padding: '10px',
    whiteSpace: 'pre-wrap',
  },
  semesterSelect: {
    width: '150px',
  },
  heading: {
    fontSize: '3rem',
    textAlign: 'center',
  },
});

export const DanceClassesPage = withStyles(styles)(DanceClassesPageComponent);
