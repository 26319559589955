import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faFacebookSquare,
  faInstagram,
} from '@fortawesome/fontawesome-free-brands';
import { appbarBackground, appbarText } from '../../assets/jss/vibeStyle';

function FooterComponent(props) {
  const { classes } = props;
  const [redirectToFacebook, setRedirectToFacebook] = useState(false);
  const [redirectToInstagram, setRedirectToInstagram] = useState(false);
  library.add(faFacebook);
  if (redirectToFacebook) {
    window.location.href = 'https://www.facebook.com/thevibedance';
  }
  if (redirectToInstagram) {
    window.location.href = 'https://www.instagram.com/thevibedance_/';
  }
  return (
    <div className={`${classes.root} ${classes.displayFlex}`}>
      <div className={classes.flex}>
        <span className={classes.text}>Contact Us:</span>
        <br />
        <span className={classes.text}>
          <a style={{ color: 'white' }} href='mailto:vibedance@closed.com'>
            vibedance@closed.com
          </a>
        </span>
        <br />
        <span className={classes.text}>
          <a style={{ color: 'white' }} href='tel:801-555-5555'>
            801-555-5555
          </a>
        </span>
      </div>
      <div className={classes.center}>
        <Button color='inherit' onClick={() => setRedirectToFacebook(true)}>
          <FontAwesomeIcon
            className={`font-awesome ${classes.fontAwesome}`}
            icon={faFacebookSquare}
          />
        </Button>
        <Button color='inherit' onClick={() => setRedirectToInstagram(true)}>
          <FontAwesomeIcon
            className={`font-awesome ${classes.fontAwesome}`}
            icon={faInstagram}
          />
        </Button>
      </div>
      <div />
    </div>
  );
}

const styles = {
  root: {
    flexGrow: 1,
    background: appbarBackground,
    color: appbarText,
    textAlign: 'left',
    padding: '1em',
  },
  text: {
    fontFamily: 'sans-serif',
    whiteSpace: 'normal',
    display: 'inline-block',
    marginBlockStart: '.5em',
    marginBlockEnd: '.5em',
  },
  flex: {
    flex: 1,
  },
  displayFlex: {
    display: 'flex',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  center: {
    textAlign: 'center',
  },
  fontAwesome: {
    fontSize: '3rem',
    decoration: 'none',
    color: 'white',
  },
};

export const Footer = withStyles(styles)(FooterComponent);
