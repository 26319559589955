(function ieCheck() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf('MSIE ');

  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
    // If Internet Explorer suggest chrome download
    var para = document.createElement('p');
    var node = document.createTextNode('You are using an outdated browser. Please ');
    var linktext = document.createTextNode('upgrade your browser');
    var node2 = document.createTextNode(' to improve your experience.');
    var link = document.createElement('a');
    link.setAttribute('href', 'https://www.google.com/chrome/');
    link.appendChild(linktext);
    para.appendChild(node);
    para.appendChild(link);
    para.appendChild(node2);

    var element = document.getElementById('root');
    var child = document.getElementById('p1');
    element.insertBefore(para, child);
  } // If another browser continue
  else {
    require('./init.jsx');
  }
})();
