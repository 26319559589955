import { LandingPage } from '../area/landing/landing-page.jsx';
import { DanceClassesPageContainer } from '../area/dance-classes/dance-classes-page.container.jsx';
import { PricingPage } from '../area/tuition/tuition-page.jsx';
import StudioInfoPage from '../area/studio-info/studio-info-page.jsx';
import { AboutUsPage } from '../area/about-us/about-us-page.jsx';
import { StudentsPageContainer } from '../area/students/students-page-container.jsx';
import ScholarshipApplicationPage from '../area/scholarship-application/scholarship-application-page.jsx';
import { SignIn } from '../area/accounts/sign-in/sign-in.jsx';
import { AddContentPageContainer } from '../area/add-content/add-content-page/add-content-page.container.jsx';
import { ChangePasswordPageContainer } from '../area/accounts/password/change-password/change-password-page.container.jsx';
import { Email } from '../area/email/email-component.jsx';
import { CreateAccount } from '../area/accounts/create-account/create-account.jsx';
import { AccountDashboardPageContainer } from '../area/accounts/dashboard/account-dashboard-page-container.jsx';
import { RegistrationFormContainer } from '../area/accounts/registration/registration-form/registration-form-container.jsx';
import { DancerForm } from '../area/accounts/dancers/dancer-form/dancer-form.jsx';
// import { DancerCardContainer } from '../area/accounts/dancers/dancer-page-container.jsx';
import { RegistrationFeePageContainer } from '../area/accounts/registration/registration-fee/registration-fee-page-container.jsx';
import { DancerAddClassContainer } from '../area/accounts/dancers/dancer-add-class/dancer-add-class-container.jsx';
import { TuitionPaymentPageContainer } from '../area/accounts/tuition/tuition-payment-page-container.jsx';
import { PasswordReset } from '../area/accounts/password/password-reset.jsx';
import { PasswordChange } from '../area/accounts/password/password-change.jsx';
import { AdminAccountsPageContainer } from '../area/Admin/Accounts/admin-accounts-container.jsx';
import { AdminEditAccountContainer } from '../area/Admin/Accounts/admin-edit-account.jsx/admin-edit-account-container.jsx';
import { CalendarPage } from '../area/calendar/calendar-page.jsx';

export const routes = {
  landingPageRoute: '/',
  landingPage2Route: '/landing-page',
  signInPageRoute: '/signin',
  accountDashboardPageRoute: '/account/dashboard',
  createAccountPageRoute: '/create-account',
  danceClassesPageRoute: '/dance-classes',
  pricingPageRoute: '/pricing',
  studioInfoPageRoute: '/studioInfo',
  aboutUsPageRoute: '/aboutus',
  recitalsPageRoute: '/recitals',
  scholarshipApplicationPageRoute: '/ScholarshipApplication',
  studentsPageRoute: '/students',
  addContentPageRoute: '/add-content',
  changePassword: '/change-password',
  email: '/email',
  registrationFormPageRoute: '/registration/form',
  registrationFeePageRoute: '/registration/fee',
  addDancerPage: '/dancers/create',
  // dancerPage: '/dancers/:id',
  dancerAddClassRoute: '/dancers/:id/classes/add',
  tuitionPaymentPageRoute: '/tuition/fee/:year/:month',
  passwordResetRoute: '/password/reset',
  passwordChangeRoute: '/account/:id/password-change/:token',
  adminAccountsPageRoute: '/admin/accounts',
  adminEditAccountRoute: '/admin/accounts/:accountId',
  calendarPageRoute: '/calendar',
  cameronsWebsite: 'https://cameronpickle.com',
};

export const indexRoutes = [
  {
    path: routes.landingPageRoute,
    name: 'LandingPage',
    component: LandingPage,
  },
  {
    path: routes.landingPage2Route,
    name: 'LandingPage',
    component: LandingPage,
  },
  {
    path: routes.calendarPageRoute,
    name: 'CalendarPage',
    component: CalendarPage,
  },
  { path: routes.signInPageRoute, name: 'SignIn', component: SignIn },
  {
    path: routes.createAccountPageRoute,
    name: 'CreateAccount',
    component: CreateAccount,
  },
  {
    path: routes.danceClassesPageRoute,
    name: 'DanceClasses',
    component: DanceClassesPageContainer,
  },
  { path: routes.pricingPageRoute, name: 'Pricing', component: PricingPage },
  {
    path: routes.studioInfoPageRoute,
    name: 'StudioInfo',
    component: StudioInfoPage,
  },
  { path: routes.aboutUsPageRoute, name: 'AboutUs', component: AboutUsPage },
  {
    path: routes.scholarshipApplicationPageRoute,
    name: 'ScholarshipApplication',
    component: ScholarshipApplicationPage,
  },
  {
    path: routes.passwordResetRoute,
    name: 'PasswordReset',
    component: PasswordReset,
  },
  {
    path: routes.passwordChangeRoute,
    name: 'PasswordChange',
    component: PasswordChange,
  },
];

export const protectedRoutes = [
  {
    path: routes.studentsPageRoute,
    name: 'Students',
    component: StudentsPageContainer,
  },
  {
    path: routes.changePassword,
    name: 'ChangePassword',
    component: ChangePasswordPageContainer,
  },
  {
    path: routes.addContentPageRoute,
    name: 'AddContent',
    component: AddContentPageContainer,
  },
  {
    path: routes.accountDashboardPageRoute,
    name: 'AccountDashboard',
    component: AccountDashboardPageContainer,
  },
  {
    path: routes.registrationFormPageRoute,
    name: 'RegistrationForm',
    component: RegistrationFormContainer,
  },
  {
    path: routes.registrationFeePageRoute,
    name: 'RegistrationFee',
    component: RegistrationFeePageContainer,
  },
  {
    path: routes.addDancerPage,
    name: 'AddDancer',
    component: DancerForm,
  },
  {
    path: routes.dancerAddClassRoute,
    name: 'DancerAddClass',
    component: DancerAddClassContainer,
  },
  // {
  //   path: routes.dancerPage,
  //   name: 'dancer',
  //   component: DancerCardContainer
  // },
  {
    path: routes.tuitionPaymentPageRoute,
    name: 'TuitionPayment',
    component: TuitionPaymentPageContainer,
  },
  {
    path: routes.adminAccountsPageRoute,
    name: 'AdminAccounts',
    component: AdminAccountsPageContainer,
  },
  {
    path: routes.adminEditAccountRoute,
    name: 'AdminEditAccount',
    component: AdminEditAccountContainer,
  },
  { path: routes.email, name: 'Email', component: Email },
];
