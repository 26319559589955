const colorPrimary = '#96D1AC';
const siteBackground = '#FFFFFF';
const appbarBackground = '#000';
const appbarText = '#fff';
const imageHeaders = '#fff';
const imageMessages = '#fff';
const contentHeader = '#000';
const contentMessages = '#000';

export {    colorPrimary,
            siteBackground,
            appbarBackground,
            appbarText,
            imageHeaders,
            imageMessages,
            contentHeader,
            contentMessages };