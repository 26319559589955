import React from 'react';
import { Paper, withStyles } from '@material-ui/core';

const ContentCardComponent = props => {
  const { classes, component: Component = 'div', ...other } = props;
  return (
    <Paper className={`${classes.root} ${classes.paper}`}>
      <Component {...other} />
    </Paper>
  );
};

const styles = theme => ({
  root: {
    paddingTop: 20,
    paddingBottom: 20
  },
  paper: {
    margin: 'auto',
    marginTop: '4em',
    marginBottom: '4em',
    maxWidth: '50em',
    layout: 'center'
  }
});

export const ContentCard = withStyles(styles)(ContentCardComponent);
