import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { useEffect, useState, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import grid from 'jss-grid';
import Typography from '@material-ui/core/Typography';
import { Page } from '../page/page';
import axios from 'axios';
import { getBaseUrl, getApiPort, getProtocol } from '../../util';
import { rolesSelector } from '../../store/area/authentication/roles/fetch-role.selector';
import { EditableTypography } from '../editable-typography/editable-typography';
import { CircularProgress } from '@material-ui/core';

const pricings = [
  {
    id: 1,
    section: 'Toddler Classes',
    prices: [
      { id: 1, amount: 25.0, section: null, sectionId: 1 },
      { id: 2, amount: 22.0, section: null, sectionId: 1 },
    ],
    amounts: [25.0, 22.0],
    sortOrder: 1,
  },
  {
    id: 2,
    section: 'Child Classes',
    prices: [
      { id: 3, amount: 28.0, section: null, sectionId: 2 },
      { id: 4, amount: 25.0, section: null, sectionId: 2 },
    ],
    amounts: [28.0, 25.0],
    sortOrder: 2,
  },
  {
    id: 3,
    section: 'Pre-Teen Classes',
    prices: [
      { id: 5, amount: 30.0, section: null, sectionId: 3 },
      { id: 6, amount: 27.0, section: null, sectionId: 3 },
    ],
    amounts: [30.0, 27.0],
    sortOrder: 3,
  },
  { id: 6, section: 'Teen Classes', prices: [], amounts: [], sortOrder: 4 },
  {
    id: 4,
    section: 'Adult Classes',
    prices: [
      { id: 7, amount: 31.0, section: null, sectionId: 4 },
      { id: 8, amount: 28.0, section: null, sectionId: 4 },
    ],
    amounts: [31.0, 28.0],
    sortOrder: 5,
  },
  {
    id: 5,
    section: 'Private Lessons',
    prices: [
      { id: 9, amount: 25.0, section: null, sectionId: 5 },
      { id: 10, amount: 23.0, section: null, sectionId: 5 },
    ],
    amounts: [25.0, 23.0],
    sortOrder: 6,
  },
];

const contentResponse = {
  id: 3,
  page: 'PRICING',
  title: 'PRICING_CONTENT',
  content:
    'DISCOUNTS:\n*Leave a review on Google and Facebook for 20% off one month of tuition.\n*Apply for our tuition scholarship each session!\n\nRegistration Fee: Paid once yearly and is $15 for individuals or $10 per family.',
};

const mapStateToProps = (state) => {
  return {
    roles: rolesSelector(state),
  };
};

const mapDispatchToProps = {};

const PricingPageComponent = ({ classes, roles }) => {
  const [pricingsOld, setPricings] = useState('Loading...');
  const [contentOld, setContent] = useState('Loading...');
  const content = contentResponse.content;

  useEffect(() => {
    // fetchPricings();
    // fetchPricingContent();
  }, []);

  const fetchPricings = async () => {
    let response = await axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/price/pricing`,
    });
    setPricings(response.data);
  };

  const fetchPricingContent = async () => {
    let response = await axios({
      method: 'GET',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Pages/pricing-content`,
    });
    setContent(response.data.content);
  };

  const postPrice = async (price) => {
    await axios({
      method: 'PUT',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/price/price`,
      data: price,
      withCredentials: true,
    });
    fetchPricings();
  };

  const postSection = async (section) => {
    await axios({
      method: 'PUT',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/price/category`,
      data: section,
      withCredentials: true,
    });
    fetchPricings();
  };

  const postContent = async (content) => {
    await axios({
      method: 'POST',
      url: `${getProtocol()}${getBaseUrl()}${getApiPort()}/api/Pages/pricing-content`,
      data: { content: content },
      withCredentials: true,
    });
    fetchPricingContent();
  };

  const priceHeaders = [
    { id: 1, title: 'Section' },
    { id: 2, title: 'Number of Classes Enrolled' },
    { id: 3, title: 'Tuition per Class' },
  ];

  return (
    <Page>
      <Typography variant='h5' component='h2' className={classes.heading}>
        Tuition
      </Typography>

      <br />

      <div className={classes.grid}>
        {(pricings &&
          pricings !== 'Loading...' &&
          priceHeaders.map((c) => (
            <Typography
              key={c.id}
              component='p'
              className={`${classes.cells} ${classes.tableHeader}`}
            >
              {c.title}
            </Typography>
          ))) || <CircularProgress />}
        {(pricings &&
          pricings !== 'Loading...' &&
          pricings.map((v) => (
            <Fragment key={v.id}>
              {v.prices.map((price, i) => (
                <Fragment key={price.id}>
                  <EditableTypography
                    editable={i === 0 && roles.includes('Administrator')}
                    onBlur={(e) =>
                      postSection({
                        id: v.id,
                        section: e.target.value,
                        sortorder: v.sortorder,
                      })
                    }
                    value={(i === 0 && v.section) || ''}
                    className={`${classes.preStyle} ${classes.cells}`}
                  />
                  <Typography component='p' className={classes.cells}>
                    {`${i + 1}${i === v.prices.length - 1 ? '+' : ''}`}
                  </Typography>
                  <EditableTypography
                    editable={roles.includes('Administrator')}
                    formatter={(x) => `$${x}`}
                    onBlur={(e) =>
                      postPrice({
                        id: price.id,
                        amount: parseInt(e.target.value),
                      })
                    }
                    value={price.amount}
                    className={`${classes.preStyle} ${classes.cells}`}
                  />
                </Fragment>
              ))}
            </Fragment>
          ))) || <CircularProgress />}
      </div>

      <EditableTypography
        editable={roles.includes('Administrator')}
        onBlur={(e) => postContent(e.target.value)}
        value={content}
        className={`${classes.preStyle} ${classes.content}`}
      />
    </Page>
  );
};

const styles = {
  heading: {
    textAlign: 'center',
    fontSize: '3rem',
  },
  subHeading: {
    paddingLeft: '20px',
    fontSize: '2rem',
  },
  cells: {
    fontSize: '1rem',
    textAlign: 'left',
    padding: '10px',
    border: '1px solid black',
  },
  tableHeader: {
    fontWeight: 'bold',
  },
  text: {
    fontSize: '1rem',
    textAlign: 'left',
    padding: '10px',
  },
  content: {
    fontSize: '1rem',
    textAlign: 'left',
    padding: '10px',
    width: '-webkit-fill-available',
    whiteSpace: 'pre-wrap',
  },
  preStyle: {
    whiteSpace: 'pre-line',
  },
  grid: {
    padding: '20px',
    ...grid(
      `
      { 0em, 0em } | 1fr | 1fr | 1fr |
      --
      `
    ),
  },
};

export const PricingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(PricingPageComponent);
