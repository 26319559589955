import { combineReducers } from 'redux';
import { sectionsApiReducer } from './api-sections.reducer';
import { sectionFormReducer } from './sections-form.reducer';

const initialState = {};

export function sectionsReducer(state = initialState, action) {
  let authenticationReducer = combineReducers({
    sectionsApiSlice: sectionsApiReducer,
    sectionFormSlice: sectionFormReducer
  });
  return authenticationReducer(state, action);
}
