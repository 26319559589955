import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import img1 from '../../assets/img/paralax1.jpg';
import img2 from '../../assets/img/paralax3.jpg';

function ImageSectionComponent(props) {
  const { classes } = props;
  return (
    <div className={classes.imageSection + ' ' + classes[props.img]}>
      <div className={classes.heading}>
        <img className={classes.headingImg} src={props.image} alt={props.imageAlt} />
        <p>{props.content}</p>
      </div>
    </div>
  );
}

const imageSectionStyle = {
  imageSection: {
    minHeight: 100 + 'vh',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center'
  },

  heading: {
    fontSize: 4.8 + 'vw',
    fontWeight: 300,
    color: '#fff',
    textShadow: '0 1px 3px rgba(0,0,0,.7)',
    top: 50 + '%',
    position: 'absolute',
    left: 50 + '%',
    textAlign: 'center',
    right: 'auto',
    width: 90 + '%',
    transform: 'translateX(-50%) translateY(-50%)',
    maxWidth: 1170 + 'px'
  },
  headingImg: {
    maxWidth: '100%'
  },

  image1: {
    backgroundImage: 'url(' + img1 + ')'
  },

  image2: {
    backgroundImage: 'url(' + img2 + ')'
  }
};

export const ImageSection = withStyles(imageSectionStyle)(ImageSectionComponent);
