import { defaultsDeep } from 'lodash';
import moment from 'moment';

export const Types = {
  validatorJS: 'validatorjs',
  pure: 'pure'
};

const strHasLengthDefault = { max: undefined, min: 0, customMsg: undefined };
export const strHasLength = (extraConf = {}) => {
  const conf = defaultsDeep(extraConf, strHasLengthDefault);
  const { max, min, customMsg } = conf;
  const validationConf = {};
  validationConf.min = conf.min;
  if (max !== undefined) validationConf.max = max;
  let msgPart = '';
  if (min > 0 && max) {
    msgPart += `must be greater than ${min} and less than ${max}`;
  } else if (max) {
    msgPart += `must be less than ${max}`;
  } else {
    msgPart += `must be greater than ${min}`;
  }
  msgPart += ' characters';
  return {
    validation: ['isByteLength', validationConf],
    type: Types.validatorJS,
    msg: name => (customMsg && customMsg(name)) || `${name} ${msgPart}`
  };
};

const numInRangeDefault = {
  max: undefined,
  min: undefined,
  customMsg: undefined
};
export const numInRange = (extraConf = {}) => {
  const params = defaultsDeep(extraConf, numInRangeDefault);
  // gt/lt are exclusive to the given numbers
  // min/max are inclusive to the given numbers
  const { min, max, gt, lt, customMsg } = params;
  const conf = {};
  if (min !== undefined) conf.min = min;
  if (max !== undefined) conf.max = max;
  if (gt !== undefined) conf.gt = gt;
  if (lt !== undefined) conf.lt = lt;
  const msgParts = [];
  if (min !== undefined)
    msgParts.push(`must be greater than or equal to ${min}`);
  if (max !== undefined) msgParts.push(`must be less than or equal to ${max}`);
  if (gt !== undefined) msgParts.push(`must be greater than ${gt}`);
  if (lt !== undefined) msgParts.push(`must be less than ${lt}`);
  let msg = msgParts.length === 1 ? msgParts[0] : '';
  if (msgParts.length > 1) {
    const lastPart = msgParts.pop();
    msg = `${msgParts.join(', ')}, and ${lastPart}`;
  }
  return {
    validation: ['isFloat', conf],
    type: Types.validatorJS,
    msg: name => (customMsg && customMsg(name)) || `${name} ${msg}`
  };
};

const regexDefault = { expression: '', customMsg: undefined };
export const regex = (extraConf = {}) => {
  const params = defaultsDeep(extraConf, regexDefault);
  const { expression, customMsg } = params;
  return {
    validation: ['matches', expression],
    type: Types.validatorJS,
    msg: name =>
      (customMsg && customMsg(name)) ||
      `${name} must match the following expression: ${expression}`
  };
};

const urlDefault = { options: {}, customMsg: undefined };
export const url = (extraConf = {}) => {
  const params = defaultsDeep(extraConf, urlDefault);
  const { options, customMsg } = params;
  return {
    validation: ['isURL', options],
    type: Types.validatorJS,
    msg: name =>
      (customMsg && customMsg(name)) ||
      `${name} must be structured as a URL. eg: http://www.rakuten.com, https://www.rakuten.com`
  };
};

const emailDefault = { options: {}, customMsg: undefined };
export const email = (extraConf = {}) => {
  const params = defaultsDeep(extraConf, emailDefault);
  const { options, customMsg } = params;
  return {
    validation: ['isEmail', options],
    type: Types.validatorJS,
    msg: name =>
      (customMsg && customMsg(name)) || `${name} must be a valid email address.`
  };
};

const equalsDefault = { stringToCompare: '', customMsg: undefined };
export const equals = (extraConf = {}) => {
  const params = defaultsDeep(extraConf, equalsDefault);
  const { stringToCompare, customMsg } = params;
  return {
    validation: ['equals', stringToCompare],
    type: Types.validatorJS,
    msg: name =>
      (customMsg && customMsg(name)) || `${name} must match ${stringToCompare}.`
  };
};

export const required = {
  validation: strHasLength({ min: 1 }).validation,
  type: Types.validatorJS,
  msg: name => `${name} is required`
};

export const isDate = {
  isValid: value => moment(value).isValid(),
  type: Types.pure,
  msg: name => `${name} must be a valid date.`
};

export const isPresentOrFutureDate = date => ({
  isValid: value => moment(date || value).isSameOrAfter(moment()),
  type: Types.pure,
  msg: name => `${name} must be current date or future date.`
});

export const isDateOrUndefined = {
  isValid: value => moment(value).isValid() || value === undefined,
  type: Types.pure,
  msg: name => `${name} must be a valid date.`
};

export const isEmail = {
  isValid: value =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    ) || value === '',
  type: Types.pure,
  msg: name => `${name} must be a valid E-mail.`
};

export const passwordNumberRequired = {
  isValid: value => /\d/.test(value),
  type: Types.pure,
  msg: name => `${name} must contain a number.`
};

export const passwordLowerCharRequired = {
  isValid: value => /[a-z]/.test(value),
  type: Types.pure,
  msg: name => `${name} must contain a lowercase character.`
};

export const passwordUpperCharRequired = {
  isValid: value => /[A-Z]/.test(value),
  type: Types.pure,
  msg: name => `${name} must contain a uppercase character.`
};

export const passwordLengthRequired = {
  isValid: value => value.length >= 6,
  type: Types.pure,
  msg: name => `${name} must be 6 characters or more.`
};

export const passwordNonAlphanumericRequired = {
  isValid: value =>
    // eslint-disable-next-line no-useless-escape
    /[\.\/\\\(\)\"\'\:\,\;\<\>\!\@\#\$\%\^\&\*\|\+\=\[\]\{\}\`\~\?\-]/.test(
      value
    ),
  type: Types.pure,
  msg: name =>
    // eslint-disable-next-line no-useless-escape
    `${name} must include a symbol from the following \"./\\()\"':,;<>!@#$%^&*|+=[]{}\`~?-\".`
};

export const isChecked = {
  isValid: value => !!value,
  type: Types.pure,
  msg: name => `This is a required field.`
};

export const passwordsMatch = (extraConf = {}) => {
  return {
    validation: ['equals', extraConf],
    type: Types.validatorJS,
    msg: name => `Passwords must match`
  };
};

export default {
  strHasLength,
  numInRange,
  regex,
  required,
  isChecked,
  url,
  email,
  equals,
  isDate,
  isPresentOrFutureDate,
  isDateOrUndefined,
  isEmail,
  passwordNumberRequired,
  passwordLowerCharRequired,
  passwordUpperCharRequired,
  passwordLengthRequired,
  passwordNonAlphanumericRequired
};
