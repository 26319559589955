import React from 'react';
import { Typography, withStyles } from '@material-ui/core';

const ContentComponent = props => {
  const { classes, component: Component = 'span', ...other } = props;
  return (
    <Typography component='p' className={classes.content}>
      <Component {...other} />
    </Typography>
  );
};

const styles = theme => ({
  content: {
    fontSize: '1rem',
    textAlign: 'left',
    padding: '10px',
    whiteSpace: 'pre-wrap'
  }
});

export const Content = withStyles(styles)(ContentComponent);
